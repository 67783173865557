import React, { useEffect, useState } from "react";
// import { Rating } from "react-simple-star-rating";
import Rating from "@mui/material/Rating";
import ayekart from "../../../images/ayekart-default.png";
import star from "../../../images/Star 1.png";

const PreviousProduct = ({ data }) => {
  const [rating, setRating] = useState(5);

  return (
    <>
      {data && (
        <div className="partner-card">
          <img
            src={
              data.Product.product_images[0]
                ? data.Product.product_images[0]
                : ayekart
            }
            alt="icon"
          />
          <div style={{ padding: "0.1vw 0.5vw" }}>
            <p className="partner-card-txt1">{data.product_name}</p>
            <p className="partner-card-txt2">(Manufacturer)</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "0.2vw",
                marginBottom: "0.5vw",
              }}
            >
              {/* <Rating initialValue={rating} size={"1vw"} /> */}
              <Rating
                value={data.ProductRating}
                style={{ fontSize: "1vw" }}
                name="read-only"
              />
              {/* <img src={star} alt="" /> */}
              <p className="rating">({data.ProductRating.toFixed(1)})</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviousProduct;
