import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DataProvider } from "./contexts/DataContext";
import AddNewCustomer from "./components/home-components/Home/AddNewCustomer";
import HomeMiddle from "./components/home-components/Home/HomeMiddle";
import AddNewVendor from "./components/home-components/Home/AddNewVendor";
import CreateSale from "./components/home-components/Home/CreateSale";
import AddNewProduct from "./components/home-components/Home/AddNewProduct";
import Finance from "./components/home-components/finance/Finance";
import InvoiceHistory from "./components/home-components/Home/InvoiceHistory";
import CreateBusinessAcc from "./components/home-components/Home/CreateBusinessAcc";
import RequestMoney from "./components/home-components/finance/RequestMoney";
import RequestAmount from "./components/home-components/finance/RequestAmount";
import Remind from "./components/home-components/finance/Remind";
import CreateInvoice from "./components/home-components/finance/CreateInvoice";
import EditProduct from "./components/home-components/Home/EditProduct";
import Inventory from "./components/home-components/inventory/Inventory";
import AddStock from "./components/home-components/inventory/AddStock";
import AddProduct from "./components/home-components/inventory/AddProduct";
import EditInventoryProduct from "./components/home-components/inventory/EditInventoryProduct";
import Staff from "./components/home-components/staff/Staff";
import AddStaff from "./components/home-components/staff/AddStaff";
import StaffProfile from "./components/home-components/staff/StaffProfile";
import SalarySettings from "./components/home-components/staff/SalarySettings";
import AddEntryVendor from "./components/home-components/Home/addEntry/vendor/AddEntryVendor";
import AddEntryCustomer from "./components/home-components/Home/addEntry/customer/AddEntryCustomer";
import CreatePurchase from "./components/home-components/Home/createPurchase/CreatePurchase";
import ShopPage from "./components/home-components/shop/ShopPage";
import Scf from "./components/home-components/scf-form/Scf";
import Offers from "./components/home-components/offers/Offers";
import "./index.css";
import MyOrder from "./components/home-components/my-order/MyOrder";
import OrderDetails from "./components/home-components/my-order/OrderDetails";
import OrderDetailsCard from "./components/home-components/my-order/OrderDetailsCard";
import Profile from "./components/home-components/profile/Profile";
import PrivacyPolicy from "./components/home-components/profile/PrivacyPolicy";
import ScfRequest from "./components/home-components/scf-form/ScfRequest";
import ViewProducts from "./components/home-components/scf-form/ViewProducts";
import Reports from "./components/home-components/finance/Reports";
import StockDetails from "./components/home-components/inventory/StockDetails";
import AddStockEntry from "./components/home-components/inventory/AddStockEntry";
import CustomerProfile from "./components/home-components/Home/customer-profile/CustomerProfile";
import ViewReports from "./components/home-components/Home/customer-profile/ViewReports";
import VendorProfile from "./components/home-components/Home/vendor-profile/VendorProfile";
import ViewReportsVendor from "./components/home-components/Home/vendor-profile/ViewReportsVendor";
import RemindPage from "./components/home-components/Home/customer-profile/RemindPage";

function App() {
  return (
    <>
      <DataProvider>
        <Router>
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Home />}>
              <Route index element={<HomeMiddle />} />
              <Route path="/addNewCustomer" element={<AddNewCustomer />} />
              <Route path="/addNewVendor" element={<AddNewVendor />} />
              <Route path="/createSale/:id" element={<CreateSale />} />
              <Route path="/addNewProduct" element={<AddNewProduct />} />
              <Route path="/finance" element={<Finance />} />
              <Route path="/invoiceHistory" element={<InvoiceHistory />} />
              <Route
                path="/createBusinessAccount"
                element={<CreateBusinessAcc />}
              />
              <Route path="/requestMoney" element={<RequestMoney />} />
              <Route path="/requestAmount" element={<RequestAmount />} />
              <Route path="/remind" element={<Remind />} />
              <Route path="/createInvoice" element={<CreateInvoice />} />
              <Route path="/editProduct" element={<EditProduct />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/addStock" element={<AddStock />} />
              {/* <Route path="/addProduct" element={<AddProduct />} /> */}
              <Route path="/addProduct" element={<AddProduct />} />
              <Route
                path="/editInventoryProduct/:id"
                element={<EditInventoryProduct />}
              />
              <Route path="/staff" element={<Staff />} />
              <Route path="/addStaff" element={<AddStaff />} />
              <Route path="/staffProfile/:id" element={<StaffProfile />} />
              <Route path="/salarySettings/:id" element={<SalarySettings />} />
              <Route
                path="/addEntry-customer/:id"
                element={<AddEntryCustomer />}
              />
              <Route path="/addEntry-vendor/:id" element={<AddEntryVendor />} />
              <Route path="/createPurchase/:id" element={<CreatePurchase />} />
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/scf-form" element={<Scf />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/myOrder" element={<MyOrder />} />
              <Route
                path="/orderDetails/:id/:status"
                element={<OrderDetails />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/scfRequest" element={<ScfRequest />} />
              <Route
                path="/viewProducts/:id/:status"
                element={<ViewProducts />}
              />
              <Route path="/reports" element={<Reports />} />
              <Route path="/stockDetails/:id" element={<StockDetails />} />
              <Route path="/addStockEntry/:id" element={<AddStockEntry />} />
              <Route
                path="/customerProfile/:id"
                element={<CustomerProfile />}
              />
              <Route path="/viewReports/:id" element={<ViewReports />} />
              <Route
                path="/vendorProfile/:id"
                element={<VendorProfile />}
              />
              <Route path="/viewReportsVendor/:id" element={<ViewReportsVendor />} />
              <Route path="/remindPage" element={<RemindPage />} />

              {/* <Route path="/orderDetailsCard/:id" element={<OrderDetailsCard />} /> */}
            </Route>
            <Route path="/login" element={<SignIn />} />
          </Routes>
        </Router>
      </DataProvider>
    </>
  );
}

export default App;
