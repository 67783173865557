import React, { useState, useEffect, useRef } from "react";
import "../../styles/login.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const LoginBoxOtp = ({ oneTimePassword, loader, otpData }) => {
  const navigate = useNavigate();
  const [otpOne, setOtpOne] = useState("");
  const [otpTwo, setOtpTwo] = useState("");
  const [otpThree, setOtpThree] = useState("");
  const [otpFour, setOtpFour] = useState("");
  const [otpFive, setOtpFive] = useState("");
  const [otpSix, setOtpSix] = useState("");
  // const inputs = useRef([]);

  useEffect(() => {
    if (otpSix) {
      let combineOtp = otpOne + otpTwo + otpThree + otpFour + otpFive + otpSix;

      if (parseInt(combineOtp) === oneTimePassword) {
        // store user details in local storage
        localStorage.setItem("userOtp", JSON.stringify(otpData));

        // make null login with password
        localStorage.setItem("userFirebase", JSON.stringify(""));

        loader(true);

        toast.success("Login Successful!", {
          position: "top-center",
        });

        // Delay 1 sec because of showing loader - for better User Experience
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        toast.error("Wrong OTP!", {
          position: "top-center",
        });
      }
    }
  }, [otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix]);

  // const handleKeyDown = (index, event) => {
  //   const { key } = event;
  //   if (key === 'Backspace' && index > 0) {
  //     inputs.current[index - 1].focus();
  //   } else if (key !== 'Backspace' && index < inputs.current.length - 1) {
  //     inputs.current[index + 1].focus();
  //   } else if (key === 'Backspace' && index === 0) {
  //     inputs.current[index].focus();
  //   }
  // };

  // const handleInputFocus = (index) => {
  //   if (index === 0) {
  //     // Clear the first input when it receives focus
  //     inputs.current[index].focus();
  //   }
  // };

  return (
    <>
      <div className="flex justify-between mt-3">
        <input
          type="text"
          className="bg-[#3850BD] border border-[#fbfbfb] text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] font-semibold text-center rounded md:rounded-md outline-0 block w-[1.3rem] min-[400px]:w-[1.5rem] md:w-[2.5rem] lg:w-[3rem] h-[1.4rem] min-[400px]:h-[1.6rem] md:h-[2.5rem] lg:h-[3rem] p-1 md:p-2 lg:p-3"
          onChange={(e) => {
            const re = /^[0-9\b]*$/;
            const inputValue = e.target.value;
            if (inputValue.length <= 1 && re.test(e.target.value)) {
              setOtpOne(inputValue);
            }
          }}
          value={otpOne}
          maxLength={1}
        />
        <input
          type="text"
          className="bg-[#3850BD] border border-[#fbfbfb] text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] font-semibold text-center rounded md:rounded-md outline-0 block w-[1.3rem] min-[400px]:w-[1.5rem] md:w-[2.5rem] lg:w-[3rem] h-[1.4rem] min-[400px]:h-[1.6rem] md:h-[2.5rem] lg:h-[3rem] p-1 md:p-2 lg:p-3"
          onChange={(e) => {
            const re = /^[0-9\b]*$/;
            const inputValue = e.target.value;
            if (inputValue.length <= 1 && re.test(e.target.value)) {
              setOtpTwo(inputValue);
            }
          }}
          value={otpTwo}
          maxLength={1}
        />
        <input
          type="text"
          className="bg-[#3850BD] border border-[#fbfbfb] text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] font-semibold text-center rounded md:rounded-md outline-0 block w-[1.3rem] min-[400px]:w-[1.5rem] md:w-[2.5rem] lg:w-[3rem] h-[1.4rem] min-[400px]:h-[1.6rem] md:h-[2.5rem] lg:h-[3rem] p-1 md:p-2 lg:p-3"
          onChange={(e) => {
            const re = /^[0-9\b]*$/;
            const inputValue = e.target.value;
            if (inputValue.length <= 1 && re.test(e.target.value)) {
              setOtpThree(inputValue);
            }
          }}
          value={otpThree}
          maxLength={1}
        />
        <input
          type="text"
          className="bg-[#3850BD] border border-[#fbfbfb] text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] font-semibold text-center rounded md:rounded-md outline-0 block w-[1.3rem] min-[400px]:w-[1.5rem] md:w-[2.5rem] lg:w-[3rem] h-[1.4rem] min-[400px]:h-[1.6rem] md:h-[2.5rem] lg:h-[3rem] p-1 md:p-2 lg:p-3"
          onChange={(e) => {
            const re = /^[0-9\b]*$/;
            const inputValue = e.target.value;
            if (inputValue.length <= 1 && re.test(e.target.value)) {
              setOtpFour(inputValue);
            }
          }}
          value={otpFour}
          maxLength={1}
        />
        <input
          type="text"
          className="bg-[#3850BD] border border-[#fbfbfb] text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] font-semibold text-center rounded md:rounded-md outline-0 block w-[1.3rem] min-[400px]:w-[1.5rem] md:w-[2.5rem] lg:w-[3rem] h-[1.4rem] min-[400px]:h-[1.6rem] md:h-[2.5rem] lg:h-[3rem] p-1 md:p-2 lg:p-3"
          onChange={(e) => {
            const re = /^[0-9\b]*$/;
            const inputValue = e.target.value;
            if (inputValue.length <= 1 && re.test(e.target.value)) {
              setOtpFive(inputValue);
            }
          }}
          value={otpFive}
          maxLength={1}
        />
        <input
          type="text"
          className="bg-[#3850BD] border border-[#fbfbfb] text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] font-semibold text-center rounded md:rounded-md outline-0 block w-[1.3rem] min-[400px]:w-[1.5rem] md:w-[2.5rem] lg:w-[3rem] h-[1.4rem] min-[400px]:h-[1.6rem] md:h-[2.5rem] lg:h-[3rem] p-1 md:p-2 lg:p-3"
          onChange={(e) => {
            const re = /^[0-9\b]*$/;
            const inputValue = e.target.value;
            if (inputValue.length <= 1 && re.test(e.target.value)) {
              setOtpSix(inputValue);
            }
          }}
          value={otpSix}
          maxLength={1}
        />
      </div>
      {/* <ToastContainer /> */}
      {/* <form className="max-w-xs mx-1">
      <div className="flex mb-2 space-x-2 rtl:space-x-reverse">
        {[...Array(6)].map((_, index) => (
          <div key={index}>
            <label htmlFor={`code-${index + 1}`} className="sr-only">{`Code ${index + 1}`}</label>
            <input
              type="text"
              maxLength={1}
              data-focus-input-init
              id={`code-${index + 1}`}
              ref={(el) => (inputs.current[index] = el)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onFocus={() => handleInputFocus(index)}
              className="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>
        ))}
      </div>
    </form> */}
    </>
  );
};

export default LoginBoxOtp;
