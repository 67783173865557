import axios from "axios";
import React, { useEffect, useState } from "react";
import { editInventoryProduct } from "../../../services/Api";
import { NavLink, useNavigate } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext.js";
import { useParams } from "react-router-dom";

const EditInventoryProduct = () => {
  const [productName, setProductName] = useState("");
  const [UOM, setUOM] = useState("");
  const [packSize, setPackSize] = useState("");
  const [cessPercentage, setCessPercentage] = useState("");
  const [originated, setOriginated] = useState("");
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [cessAmount, setCessAmount] = useState(0.0);
  const [marginPercentage, setMarginPercentage] = useState("");
  const [marginAmount, setMarginAmount] = useState("");
  const [upcCode, setUpcCode] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [stockQty, setStockQty] = useState("");
  const [stockAmount, setStockAmount] = useState("");
  const [gst, setGST] = useState("");
  const navigate = useNavigate();
  const { selectedBusiness, inventoryProductDetails } = useDataContext();
  const [imageUrlRetailer, setImageUrlRetailer] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [selectedIdProduct, setSelectedIdProduct] = useState({});
  const { id } = useParams();
  const [minDate, setMinDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Append leading zero if month/day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    const currentDate = `${year}-${month}-${day}`;
    setMinDate(currentDate);
  }, []);

  useEffect(() => {
    if (buyingPrice && sellingPrice) {
      let percentage = ((sellingPrice - buyingPrice) / sellingPrice) * 100;
      setMarginPercentage(percentage);
      setMarginAmount(sellingPrice - buyingPrice);
    } else {
      setMarginPercentage("");
      setMarginAmount("");
    }

    if (buyingPrice && stockQty) {
      setStockAmount((stockQty * buyingPrice).toFixed(2));
    } else {
      setStockAmount("");
    }
  }, [buyingPrice, sellingPrice, stockQty]);

  useEffect(() => {
    const filter = inventoryProductDetails.filter(
      (item) => item.id === parseInt(id)
    );

    setSelectedIdProduct(filter[0]);

    console.log("filter[0]: ", filter[0])

    if (filter.length > 0) {
      setProductName(filter[0].product_name);
      setUOM(filter[0].measurement_unit.toLowerCase());
      setPackSize(filter[0].pack_size);
      setGST(filter[0].gst);
      setCessPercentage(filter[0].cess_percentage);
      setOriginated(filter[0].originated_from);
      setBuyingPrice(filter[0].buying_price);
      setSellingPrice(filter[0].selling_price);
      setCessAmount(filter[0].cess_amount);
      setMarginAmount(filter[0].margin_amount);
      setMarginPercentage(filter[0].margin_percentage);
      setIsChecked(filter[0].presence);
    }
  }, []);

  const handleFileChangeRetailer = (event) => {
    const selectedFile = event.target.files[0];
    getImageUrlRetailer(selectedFile);
    // setImageUrlRetailer(getImageUrl(selectedFile));
  };

  const getImageUrlRetailer = (e) => {
    const formValue = new FormData();
    formValue.append("file", e);
    const url = "/api/mitra-merchant/doc-upload";
    // const finalURL = url.replace("https://retailorder.ayekart.com", "");
    axios
      .post(
        // http://139.59.91.183:3003/api/mitra-merchant/doc-upload
        url,
        formValue
      )
      .then((response) => {
        setImageUrlRetailer(response.data.file_location);
        // return response.data.file_location;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const updateProduct = async () => {
    console.log("UOM: ", UOM)
    const stockEntryData = {
      batch_number: "",
      business_id: (selectedBusiness.id ? selectedBusiness.id : 2).toString(),
      buying_price: buyingPrice,
      cess_amount: parseFloat(cessAmount),
      cess_percentage: parseInt(cessPercentage),
      gst: gst,
      margin_amount: marginAmount,
      margin_percentage: marginPercentage,
      measurement_unit: UOM,
      originated_from: originated,
      pack_size: packSize,
      presence: isChecked.toString(),
      product_id: id,
      product_image_url: imageUrlRetailer,
      product_name: productName,
      selling_price: sellingPrice,
      sku: "",
    };

    try {
      const createdEntry = await editInventoryProduct(stockEntryData);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-medium text-lg md:text-2xl xl:text-3xl">
              Edit Product
            </h1>

            <p className="text-[#000000] text-[0.34rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/inventory" className="no-underline">
                Inventory
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/" className="no-underline">
                Product
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/editProduct" className="no-underline">
                Edit Product
              </NavLink>
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div>
            <label
              for="Product Image"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Product Image
            </label>
            <input
              type="file"
              id="Product Image"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={handleFileChangeRetailer}
            />
          </div>

          <div>
            <label
              for="Product Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Product Name*
            </label>
            <input
              type="text"
              id="Product Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>

          <div>
            <p className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white">
              UOM*
            </p>
            <div>
              <select
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={UOM}
                onChange={(e) => setUOM(e.target.value)}
              >
                <option value="" className="font-semibold">
                  Select Unit
                </option>
                <option value="gram">Gram</option>
                <option value="kilogram">Kilogram</option>
                <option value="milliliter">Milliliter</option>
                <option value="liter">Liter</option>
                <option value="dozen">Dozen</option>
                <option value="pieces">Pieces</option>
              </select>
            </div>
          </div>

          <div>
            <label
              for="Pack Size"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Pack Size*
            </label>
            <input
              type="number"
              id="Pack Size"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Pack Size"
              value={packSize}
              onChange={(e) => setPackSize(e.target.value)}
            />
          </div>

          <div>
            <label
              for="GST in %"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              GST*
            </label>
            <input
              type="text"
              id="GST in %"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="GST"
              value={gst}
              onChange={(e) => setGST(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Cess % (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Cess %
            </label>
            <input
              type="number"
              id="Cess % (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Cess %"
              value={cessPercentage}
              onChange={(e) => setCessPercentage(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Cess Amount (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Cess Amount
            </label>
            <input
              type="number"
              id="Cess Amount (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Amount"
              value={cessAmount}
              onChange={(e) => setCessAmount(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Buying Price"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Buying Price*
            </label>
            <input
              type="number"
              id="Buying Price"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Amount"
              value={buyingPrice}
              onChange={(e) => setBuyingPrice(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Selling Price"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Selling Price*
            </label>
            <input
              type="number"
              id="Selling Price"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Selling Price"
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Originated From"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Originated From
            </label>
            <input
              type="text"
              id="Originated From"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Originated From"
              value={originated}
              onChange={(e) => setOriginated(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Margin %"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Margin %
            </label>
            <input
              type="text"
              id="Margin %"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Percentage"
              value={marginPercentage}
              onChange={(e) => setMarginPercentage(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Margin Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Margin Amount
            </label>
            <input  
              type="number"
              id="Margin Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Amount"
              value={marginAmount}
              onChange={(e) => setMarginAmount(e.target.value)}
            />
          </div>

          <div>
            <label
              for="UPC Code (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              UPC Code
            </label>
            <input
              type="text"
              id="UPC Code (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter UPC Code"
              value={upcCode}
              onChange={(e) => setUpcCode(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Expiry Date (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Expiry Date
            </label>
            <input
              type="date"
              id="Expiry Date (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              min={minDate}
            />
          </div>

          <div>
            <label
              for="Batch Number (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Batch Number
            </label>
            <input
              type="text"
              id="Batch Number (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Batch Number"
              value={batchNo}
              onChange={(e) => setBatchNo(e.target.value)}
            />
          </div>

          <div>
            <p className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white">
              Presence
            </p>
            <div className=" bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-3 lg:p-[1.1rem] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <div className="flex justify-between items-center">
                <p className="text-xs font-medium text-[#384B9D] dark:text-white">
                  Online
                </p>
                <label className="amount-slider-container ">
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    onClick={handleToggle}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 py-2 lg:px-4 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={updateProduct}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default EditInventoryProduct;
