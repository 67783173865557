import React, { useEffect, useState } from "react";
import onion from "../../../images/Onion.svg";
import ayekart from "../../../images/ayekart-default.png";
import { getInventoryProductData } from "../../../services/Api.js";
import { useDataContext } from "../../../contexts/DataContext.js";
import { dateTimeConvert } from "../../../utilities/convertDate.js";
import { NavLink, useNavigate } from "react-router-dom";
import Pagination from "../pagination/Pagination.jsx";

const InventoryProductCard = ({ searchText }) => {
  const [inventoryProduct, setInventoryProduct] = useState([]);
  const [handleProductSearch, setHandleProductSearch] = useState([]);
  const {
    selectedBusiness,
    setInventoryProductDetails,
    inventoryProductDetails,
  } = useDataContext();
  const navigate = useNavigate();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    setRecords(inventoryProduct.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(inventoryProduct.length / recordsPerPage));
    maxmLimitPage();
  }, [inventoryProduct, firstIndex, lastIndex]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, inventoryProduct.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(inventoryProduct.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const getData = async () => {
    try {
      const productData = await getInventoryProductData(
        selectedBusiness.id ? selectedBusiness.id : 2
      );

      setHandleProductSearch(productData.Data);
      setInventoryProduct(productData.Data);
      setInventoryProductDetails(productData.Data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = () => {
    // Filter the data based on the user's search.
    const filteredData =
      handleProductSearch.length > 0
        ? handleProductSearch.filter((item) =>
            item.product_name.toLowerCase().includes(searchText.toLowerCase())
          )
        : [];
    setInventoryProduct(filteredData);
  };

  return (
    <>
      <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 min-[820px]:grid-cols-2 xl:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-6">
        {records.map((item, index) => (
          <div
            className="shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] w-full overflow-auto h-fit md:h-[9.5rem] lg:h-[10rem] p-1 md:p-2"
            onClick={() => navigate(`/editInventoryProduct/${item.id}`)}
            key={index}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img
                  src={
                    item.product_image_url && item.product_image_url[0] === "h"
                      ? item.product_image_url
                      : ayekart
                  }
                  className="w-[1.8rem] h-[1.8rem] md:w-[2.5rem] md:h-[2.5rem] rounded"
                  alt="icon"
                />
                <p
                  className="text-[0.9rem] lg:text-[1rem] text-[#384B9D] font-bold max-w-[5rem] overflow-hidden overflow-ellipsis whitespace-nowrap ml-2 cursor-pointer"
                  title={item.product_name}
                >
                  {item.product_name}
                </p>
              </div>
              <p className="text-[0.65rem] md:text-[0.7rem] lg:text-[0.8rem] text-[#384B9D] font-medium md:mx-3">
                {dateTimeConvert(item.updated_at)}
              </p>
            </div>

            <div className="mt-2 ml-2">
              <div className="flex mb-1 justify-between">
                <div className="flex w-[60%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    Buying Price:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-3 font-semibold">
                    {item.buying_price}
                  </span>
                </div>

                <div className="flex w-[50%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    Selling Price:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-3 font-semibold">
                    {item.selling_price}
                  </span>
                </div>
              </div>

              <div className="flex mb-1 justify-between">
                <div className="flex w-[60%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    GST:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-3 font-semibold">
                    {item.gst}
                  </span>
                </div>

                <div className="flex w-[50%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    Expiry Date:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-3 font-semibold">
                    NA
                  </span>
                </div>
              </div>

              <div className="flex mb-1 justify-between">
                <div className="flex w-[60%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    Batch Number:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-2 font-semibold">
                    NA
                  </span>
                </div>

                <div className="flex w-[50%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    Margin Percentage:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-2 font-semibold">
                    NA
                  </span>
                </div>
              </div>

              <div className="flex mb-1 justify-between">
                <div className="flex w-[60%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    Margin Amount:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-3 font-semibold">
                    NA
                  </span>
                </div>

                <div className="flex w-[50%]">
                  <span className="text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] font-semibold">
                    Presence:
                  </span>
                  <span className="text-[#384B9D] text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] ml-3 font-semibold">
                    {item.presence ? "Online" : "Offline"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* pagination */}
      <Pagination
        prevPage={prevPage}
        changePage={changePage}
        nextPage={nextPage}
        numbers={numbers}
        currentPage={currentPage}
      />
    </>
  );
};

export default InventoryProductCard;
