import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import InventoryStockCard from "./InventoryStockCard";
import InventoryProductCard from "./InventoryProductCard";

const Inventory = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  function customerButton() {
    setIsToggle(false);
  }

  function vendorButton() {
    setIsToggle(true);
  }

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-6 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-lg md:text-2xl xl:text-3xl">
              Inventory
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/inventory" className="no-underline">
                Inventory
              </NavLink>
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] min-[350px]:w-[6rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between items-center mt-5 w-[97%] md:w-[98%]">
          <div className="flex justify-center items-center">
            <button
              className={`${
                isToggle
                  ? "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                  : "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
              }
              w-[4rem] min-[350px]:w-[5rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] min-[350px]:text-xs xl:text-[1rem] rounded`}
              onClick={customerButton}
            >
              Products
            </button>

            <button
              className={`${
                isToggle
                  ? "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
                  : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
              }
              ml-1 w-[4rem] min-[350px]:w-[5rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem]  min-[350px]:text-xs xl:text-[1rem] rounded`}
              onClick={vendorButton}
            >
              Stocks
            </button>
          </div>

          <div className="flex justify-center items-center">
            {isToggle ? (
              <button
                className="bg-[#384B9D] border border-[#384B9D] text-[#FBFBFB] w-[4rem] min-[350px]:w-[5rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] min-[350px]:text-xs xl:text-[1rem] rounded"
                onClick={() => navigate("/addStock")}
              >
                Add Stock
              </button>
            ) : (
              <button
                className="bg-[#384B9D] border border-[#384B9D] text-[#FBFBFB] w-[4rem] min-[350px]:w-[5rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] min-[350px]:text-xs xl:text-[1rem] rounded"
                onClick={() => navigate("/addProduct")}
              >
                Add Product
              </button>
            )}
          </div>
        </div>

        {isToggle ? (
          <div className="">
            <InventoryStockCard searchText = {search}/>
          </div>
        ) : (
          <div className="">
            <InventoryProductCard searchText = {search}/>
          </div>
        )}
      </div>
    </>
  );
};

export default Inventory;
