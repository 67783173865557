import React, { useEffect, useState } from "react";
import terms from "../../../images/profile-1.svg";
import privacy from "../../../images/profile-2.svg";
import { NavLink, useNavigate } from "react-router-dom";
import {
  bankData,
  profileData,
  saveBankDetails,
} from "../../../services/ProfileApi";
import { useDataContext } from "../../../contexts/DataContext";
import { auth } from "../../../utilities/firebase";
import { signOut } from "firebase/auth";
import { dateConvert } from "../../../utilities/convertDate";
import ayekart from "../../../images/ayekart-default.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [number, setNumber] = useState("");
  const [onboardDate, setOnboardDate] = useState("");
  const [getProfileData, setGetProfileData] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const { userDetailsId, selectedBusiness } = useDataContext();

  const [isCheck, setIsCheck] = useState(false);
  const [acHolderName, setAcHolderName] = useState("");
  const [acNumber, setAcNumber] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [branch, setBranch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getProfileDetails();
    getBankData();
  }, []);

  const getProfileDetails = async () => {
    const data = await profileData(userDetailsId.data.ID);
    setGetProfileData(data.data);
    setName(`${data.data.first_name} ${data.data.last_name}`);
    setEmailId(data.data.email_address);
    setNumber(data.data.mobile);
    setOnboardDate(dateConvert(data.data.CreatedAt));
    setProfileImg(data.data.profile_image_url);
  };

  const getBankData = async () => {
    const data = await bankData(userDetailsId.data.business.ID);
    setIsCheck(data.bank_details.account_holder_name ? true : false);
    setAcHolderName(data.bank_details.account_holder_name);
    setAcNumber(data.bank_details.account_number);
    setIFSCCode(data.bank_details.ifsc_code);
    setBranch(data.bank_details.branch_name);
  };

  const saveBankData = async () => {
    if(acNumber && IFSCCode)
    {
      const reqData = {
        id_number: acNumber,
        ifsc: IFSCCode,
        ifsc_details: true,
      };
  
      const data = await saveBankDetails(reqData);
    }
    else
    {
      toast.error("Please complete all mandatory fields.", {
        position: "top-center",
      });
    }
  };

  // const handleLogout = () => {
  //   signOut(auth)
  //     .then(() => {
  //       // Sign-out successful.
  //       localStorage.clear();
  //       navigate("/login");
  //       console.log("Signed out successfully");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const downloadTC = () => {
    // Download the terms and condition pdf file
    window.open(
      "https://ayekart-mobile.sfo2.digitaloceanspaces.com/b2b_finance_bnpl/1711012613467-privacy_policy-%282%29.pdf",
      "_blank"
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-7 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
        <div>
          <h1 className="text-[#0e3293] font-medium text-xl md:text-2xl xl:text-3xl">
            Profile
          </h1>

          <p className="text-[#000000] text-[0.5rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
            <NavLink to="/" className="no-underline">
              Home
            </NavLink>
            &nbsp; &gt; &nbsp; Profile
          </p>
        </div>

        <div className="relative  mb-2">
          <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
            <svg
              className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[6rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
            placeholder="Search..."
          />
        </div>
      </div>

      <div className="mt-4 w-[97%] md:w-[98%] flex justify-between items-center">
        <p className="flex items-center cursor-pointer" onClick={downloadTC}>
          <span className="w-3 lg:w-4 h-fit">
            <img src={terms} alt="icon" />
          </span>
          <span className="text-[#384B9D] font-bold text-[0.6rem] md:text-[0.8rem] lg:text-[0.9rem] mx-1">
            Terms and Conditions
          </span>
        </p>

        <NavLink to="/privacyPolicy" className="no-underline">
          <p className="flex items-center cursor-pointer">
            <span>
              <img src={privacy} alt="icon" className="w-3 lg:w-4 h-fit" />
            </span>
            <span className="text-[#384B9D] font-bold text-[0.6rem] md:text-[0.8rem] lg:text-[0.9rem] mt-[0.1rem] lg:mt-[0.2rem]  mx-1">
              Privacy Policy
            </span>
          </p>
        </NavLink>
      </div>

      <div className="flex items-center mt-3 border border-gray-300 shadow-xl w-[97%] md:w-[98%] rounded-md p-4 md:p-6 xl:p-8 bg-[#EDEDED]">
        <img
          src={profileImg ? profileImg : ayekart}
          alt="profile-img"
          className="rounded-[90%] w-[2.5rem] md:w-[3.5rem] lg:w-[4rem] xl:w-[4.5rem] h-[2.5rem] md:h-[3.5rem] lg:h-[4rem] xl:h-[4.5rem] border-dashed border border-[#0E3293] p-[0.1rem]"
        />
        <div className="ml-3">
          <p className="font-bold text-[#384B9D] text-[0.8rem] md:text-[1rem] xl:text-[1.3rem]">
            {selectedBusiness.business_name
              ? selectedBusiness.business_name
              : "PGARTS"}
          </p>
          <p className="text-[#585858] text-[0.6rem] md:text-[0.8rem] xl:text-[1rem] font-bold">
            Admin
          </p>
        </div>
      </div>

      <div className="mt-5 lg:mt-7 border border-gray-300 shadow-xl w-[97%] md:w-[98%] rounded-md p-4">
        <p className="text-[#384B9D] font-bold mb-4 text-base md:text-lg xl:md:text-xl">
          Personal Details
        </p>
        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 ">
          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Staff Name
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={name}
              readOnly
            />
          </div>

          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Contact Number
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={number}
              readOnly
            />
          </div>

          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Email ID
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={emailId}
              readOnly
            />
          </div>

          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Onboard Date
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={onboardDate}
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="my-5 lg:mt-7 border border-gray-300 shadow-xl w-[97%] md:w-[98%] rounded-md p-4">
        <p className="text-[#384B9D] font-bold mb-4 text-base md:text-lg xl:md:text-xl">
          Bank Details
        </p>
        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 ">
          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              AC Holder Name
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={acHolderName}
              onChange={(e) => setAcHolderName(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Account Number*
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={acNumber}
              onChange={(e) => setAcNumber(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              IFSC Code*
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={IFSCCode}
              onChange={(e) => setIFSCCode(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Branch
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 font-bold outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
            />
          </div>
        </div>

        {isCheck ? (
          <div className="flex justify-end items-center w-[97%] md:w-[98%] mt-7">
            <button
              type="button"
              className="text-white bg-[#0D3293] hover:bg-blue-800  focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 md:px-5 lg:px-7 xl:px-9 py-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={saveBankData}
            >
              Update
            </button>
          </div>
        ) : (
          <div className="flex justify-end items-center w-[97%] md:w-[98%] mt-7">
            <button
              type="button"
              className="text-white bg-[#0D3293] hover:bg-blue-800  focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 md:px-5 lg:px-7 xl:px-9 py-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={saveBankData}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
