import React, { useEffect } from "react";
import { dateTimeConvert } from "../../../utilities/convertDate";
import { NavLink, useNavigate } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";

const ScfRequestCard = ({ cardData }) => {
  const navigate = useNavigate();
  const { setScfProductsData } = useDataContext();

  useEffect(() => {
    setScfProductsData(cardData);
  }, []);

  return (
    <div className="shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] w-full overflow-auto h-fit p-2 xl:p-3">
      <div className="flex justify-between items-center">
        <p className="text-[#3850BD] font-bold text-sm xl:text-[1.3rem]">Id: {cardData.ID}</p>
        <p className="text-[#384B9D] font-bold text-[0.6rem] xl:text-[0.9rem]">
          {dateTimeConvert(cardData.CreatedAt)}
        </p>
      </div>

      <div className="mt-2 xl:mt-3">
        <p className="text-[#384B9D] font-semibold text-[0.7rem] xl:text-[0.9rem]">
          <span className="text-[#3A3A3A]">Requested Amount :</span> &nbsp; Rs.{" "}
          {cardData.amount}
        </p>
        <p className="text-[#384B9D] font-semibold text-[0.7rem] xl:text-[0.9rem]">
          <span className="text-[#3A3A3A]">Tenure(no of days) :</span> &nbsp;{" "}
          {cardData.tenure} days
        </p>
      </div>

      <div className="flex justify-end items-center mt-3 xl:mt-4 mb-1">
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-[0.6rem] md:text-[0.7rem] xl:text-[0.8rem] px-1 md:px-2 lg:px-4 py-1 mr-2"
          onClick={() =>
            navigate(`/viewProducts/${cardData.ID}/${cardData.scf_status}`)
          }
        >
          View Products
        </button>

       { cardData.scf_status === "pending" &&  <button
          type="button"
          className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-[0.6rem] md:text-[0.7rem] xl:text-[0.8rem] px-1 md:px-2 lg:px-4 py-1"
        >
          Cancel
        </button>}
      </div>
    </div>
  );
};

export default ScfRequestCard;
