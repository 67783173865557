import React, { useState } from "react";
import menu from "../../../images/menu.png";
import HomeMiddleCardCustomer from "./HomeMiddleCardCustomer";
import HomeMiddleCardVendor from "./HomeMiddleCardVendor";
import { NavLink, useNavigate } from "react-router-dom";

const HomeMiddleCardHeading = () => {
  const [isToggle, setIsToggle] = useState(false);
  const navigate = useNavigate();

  function customerButton() {
    setIsToggle(false);
  }

  function vendorButton() {
    setIsToggle(true);
  }

  return (
    <>
      <div className="flex justify-between items-center mt-5 w-[97%] md:w-[98%] ">
        <div className="flex justify-center items-center">
          <button
            className={`${
              isToggle
                ? "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                : "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
            }
              w-[4rem] mr-1 md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] md:text-xs xl:text-[1rem] rounded`}
            onClick={customerButton}
          >
            Customers
          </button>

          <button
            className={`${
              isToggle
                ? "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
                : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
            }
              w-[4rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] md:text-xs xl:text-[1rem] rounded`}
            onClick={vendorButton}
          >
            Vendors
          </button>
        </div>

        <div className="flex justify-center items-center">
          {isToggle ? (
            <button
              className="bg-[#384B9D] border border-[#384B9D] text-[#FBFBFB] w-fit font-semibold py-1 xl:py-2 px-1 text-[0.5rem] md:text-xs xl:text-[1rem] rounded"
              onClick={() => navigate("/addNewVendor")}
            >
              Add New Vendor
            </button>
          ) : (
            <button
              className="bg-[#384B9D] border border-[#384B9D] text-[#FBFBFB] w-fit font-semibold py-1 lg:py-2 px-1 lg:px-2 text-[0.5rem] md:text-xs xl:text-[1rem] rounded"
              onClick={() => navigate("/addNewCustomer")}
            >
              Add New Customer
            </button>
          )}
        </div>
      </div>

      {isToggle ? <HomeMiddleCardVendor /> : <HomeMiddleCardCustomer />}
    </>
  );
};

export default HomeMiddleCardHeading;
