import React from 'react'
import ayekart from "../../../images/ayekart-default.png";
import { Rating } from 'react-simple-star-rating'

const CategoryCard = ({data}) => {
  return (
    <>
      <div className="category-card">
        <img src={data.image_URL ? data.image_URL : ayekart} alt="icon" />
        <div style={{ padding: "0.1vw 0.5vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p className="category-card-txt1">{data.name}</p>
        </div>
      </div>
    </>
  )
}

export default CategoryCard