import React, { useEffect, useState } from "react";
import { stockReportsApi } from "../../../../services/ChartApi";
import { useDataContext } from "../../../../contexts/DataContext";
import { dateTimeConvert } from "../../../../utilities/convertDate";

const StockReports = ({
  defaultFirstDate,
  defaultLastDate,
  from,
  to,
  search,
}) => {
  const { selectedBusiness } = useDataContext();
  const [stockData, setStockData] = useState([]);
  const [filterData, setFilterData] = useState("");

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    getStockReportsData(defaultFirstDate, defaultLastDate);
  }, [defaultFirstDate, defaultLastDate]);

  useEffect(() => {
    getStockReportsData(from, to);
  }, [from, to]);

  const getStockReportsData = async (firstDate, lastDate) => {
    if (firstDate && lastDate) {
      const data = await stockReportsApi(
        selectedBusiness.id ? selectedBusiness.id : 2,
        firstDate,
        lastDate
      );
      setFilterData(data["Data"] ? data.Data : []);
      setStockData(data["Data"] ? data.Data : []);
    }
  };

  const handleSearch = () => {
    //filter stock data
    const stockData =
      filterData.length > 0
        ? filterData.filter((item) =>
            item.item_name.toLowerCase().includes(search.toLowerCase())
          )
        : [];
    setStockData(stockData);
  };

  return (
    <div className="relative overflow-auto">
      <table className="w-full text-[0.6rem] md:text-[0.8rem] lg:text-[1rem]">
        <thead className="bg-[#384B9D] text-[#FFFFFF] font-semibold">
          <tr>
            <th scope="col" className="p-1 md:p-2">
              ITEMS
            </th>
            <th scope="col" className="p-1 md:p-2">
              ENTRY TYPE
            </th>
            <th scope="col" className="p-1 md:p-2">
              AMOUNT
            </th>
          </tr>
        </thead>
        {stockData.map((item, index) => (
          <tbody key={index}>
            <tr className="bg-[#F3F3F3] border-b text-center">
              <th
                scope="row"
                className="p-1 md:p-2 font-medium text-gray-900 whitespace-nowrap "
              >
                {item.item_name}
                <p className="text-[0.5rem] md:text-[0.6rem] font-normal">
                  {dateTimeConvert(item.created_at)}
                </p>
              </th>
              <td className="p-1 md:p-2">{item.entry_type}</td>
              <td className="p-1 md:p-2">
                <p>{item.item_purchased} Units</p>
                &#x20B9; {item.purchased_value}
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default StockReports;
