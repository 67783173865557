import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import ViewProductsCard from "./ViewProductsCard";
import {
  scfApprovedData,
  scfCancelledData,
  scfPendingData,
  scfRejectedData,
} from "../../../services/ScfFormApi";

const ViewProducts = () => {
  const { scfProductsData, selectedBusiness, userDetailsId } = useDataContext();
  const [productData, setProductData] = useState([]);
  const { id, status } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (status === "pending") {
      getScfPendingData();
    } else if (status === "approved") {
      getScfApprovedData();
    } else if (status === "rejected") {
      getScfRejectedData();
    } else if (status === "cancelled") {
      getScfCancelledData();
    }
  };

  const getScfPendingData = async () => {
    const data = await scfPendingData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );

    const filteredData = data.data.filter((item) => item.ID === parseInt(id));
    setProductData(filteredData[0]);
  };

  const getScfApprovedData = async () => {
    const data = await scfApprovedData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    const filteredData = data.data.filter((item) => item.ID === parseInt(id));
    setProductData(filteredData[0]);
  };

  const getScfRejectedData = async () => {
    const data = await scfRejectedData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    const filteredData = data.data.filter((item) => item.ID === parseInt(id));
    setProductData(filteredData[0]);
  };

  const getScfCancelledData = async () => {
    const data = await scfCancelledData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    const filteredData = data.data.filter((item) => item.ID === parseInt(id));
    setProductData(filteredData[0]);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
              Products
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/scfRequest" className="no-underline">
                SCF Requests
              </NavLink>
              &nbsp; &gt; &nbsp; Products
            </p>
          </div>

          <div className="relative mx-2 xl:mx-5 sm:mx-3 mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          {productData.SCFProducts &&
            productData.SCFProducts.map((item, index) => {
              return <ViewProductsCard cardData={item} key={index} />;
            })}
        </div>
      </div>
    </>
  );
};

export default ViewProducts;
