import React, { useEffect, useState } from "react";
import {
  dateTimeConvert,
  dateConvert,
} from "../../../utilities/convertDate.js";
import { getInventoryStockData } from "../../../services/Api.js";
import { useDataContext } from "../../../contexts/DataContext.js";
import { NavLink, useNavigate } from "react-router-dom";
import Pagination from "../pagination/Pagination.jsx";

const InventoryStockCard = ({ searchText }) => {
  const [inventoryStock, setInventoryStock] = useState([]);
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [handleStockSearch, setHandleStockSearch] = useState([]);
  const navigate = useNavigate();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    setRecords(inventoryStock.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(inventoryStock.length / recordsPerPage));
    maxmLimitPage();
  }, [inventoryStock, firstIndex, lastIndex]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, inventoryStock.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(inventoryStock.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const getData = async () => {
    try {
      const productData = await getInventoryStockData(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2
      );
      setHandleStockSearch(productData.data);
      setInventoryStock(productData.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = () => {
    // Filter the data based on the user's search.
    const filteredData =
      handleStockSearch.length > 0
        ? handleStockSearch.filter((item) =>
            item.item_name.toLowerCase().includes(searchText.toLowerCase())
          )
        : [];
    setInventoryStock(filteredData);
  };

  return (
    <>
      <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 min-[820px]:grid-cols-2 xl:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-6">
        {records.map((item, index) => (
          <div
            className="shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] w-full overflow-auto h-fit md:h-[9rem] cursor-pointer"
            key={index}
            onClick={() => navigate(`/stockDetails/${item.product_id}`)}
          >
            <div className="flex justify-between items-center p-2">
              <div>
                <p className="font-bold text-[#384B9D] text-[0.9rem] md:text-[1rem] mr-3">
                  {item.item_name}
                </p>
              </div>

              {item.item_purchased -
                (item.item_sold + item.item_damaged + item.item_returned) >
              0 ? (
                <p className="text-[#384B9D] font-semibold text-[0.6rem] md:text-[0.7rem]">
                  Available Stock :{" "}
                  {item.item_purchased -
                    (item.item_sold +
                      item.item_damaged +
                      item.item_returned)}{" "}
                  Units
                </p>
              ) : (
                <p className="text-[#9F1438] font-semibold text-[0.55rem] md:text-[0.7rem]">
                  Out of Stock
                </p>
              )}
            </div>

            <div className="mt-1 border-b-[0.01rem] border-[#999999] px-2 pb-1">
              <div className="flex mb-1">
                <div className="flex w-[50%]">
                  <span className="font-semibold text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] w-[60%]">
                    Purchased:
                  </span>
                  <span className="text-[#384B9D] font-semibold text-[0.6rem] md:text-[0.7rem]">
                    {item.item_purchased === 0 ? "NA" : item.item_purchased}
                  </span>
                </div>

                <div className="flex w-[50%]">
                  <span className="font-semibold text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] w-[60%]">
                    Sold:
                  </span>
                  <span className="text-[#384B9D] font-semibold text-[0.6rem] md:text-[0.7rem]">
                    {item.item_sold === 0 ? "NA" : item.item_sold}
                  </span>
                </div>
              </div>

              <div className="flex mb-1">
                <div className="flex w-[50%]">
                  <span className="font-semibold text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] w-[60%]">
                    Damaged:{" "}
                  </span>
                  <span className="text-[#384B9D] font-semibold text-[0.6rem] md:text-[0.7rem]">
                    {item.item_damaged === 0 ? "NA" : item.item_damaged}
                  </span>
                </div>

                <div className="flex w-[50%]">
                  <span className="font-semibold text-[#3A3A3A] text-[0.6rem] md:text-[0.7rem] w-[60%]">
                    Returned:
                  </span>
                  <span className="text-[#384B9D] font-semibold text-[0.6rem] md:text-[0.7rem]">
                    {item.item_returned === 0 ? "NA" : item.item_returned}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center p-2">
              <div>
                <div className="flex items-center">
                  <p className="text-[#384B9D] text-[0.5rem] sm:text-[0.7rem] font-semibold">
                    Purchased Date:
                  </p>
                  <p className="text-[#384B9D] text-[0.5rem] sm:text-[0.7rem] ml-1 font-semibold">
                    {dateConvert(item.purchased_date)}
                  </p>
                </div>

                <div className="flex items-center">
                  <p className="text-[#384B9D] text-[0.5rem] sm:text-[0.7rem] font-semibold">
                    Last Stock Updated Date:
                  </p>
                  <p className="text-[#384B9D] text-[0.5rem] ml-1 sm:text-[0.7rem] font-semibold">
                    {dateConvert(item.last_stock_update)}
                  </p>
                </div>
              </div>
              <p className="text-[#384B9D] text-[0.5rem] sm:text-[0.7rem] font-semibold">
                Total Enteries {item.total_entries}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* pagination */}
      <Pagination
        prevPage={prevPage}
        changePage={changePage}
        nextPage={nextPage}
        numbers={numbers}
        currentPage={currentPage}
      />
    </>
  );
};

export default InventoryStockCard;
