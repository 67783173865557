import React from "react";
import "../styles/login.css";
import LoginBox from "../components/login-components/LoginBox";
import AyekartLogo from "../components/login-components/AyekartLogo";

const SignIn = () => {
  return (
    <>
      <div className="flex h-[100vh]">
        <div className="w-[43%] bg-[#ffffff]">
          <AyekartLogo />
        </div>
        <div className="z-10 bg-[#3850bd] w-[60%]">
          <LoginBox />
        </div>
      </div>
    </>
  );
};

export default SignIn;
