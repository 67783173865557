import React, { useEffect, useState } from "react";
import money from "../../../images/money.png";
import invoice2 from "../../../images/invoice-2.png";
import reports from "../../../images/reports.png";
import finance1 from "../../../images/finance-1.svg";
import finance4 from "../../../images/finance-4.svg";
import finance3 from "../../../images/finance-3.svg";
import { NavLink } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { getCustomerDetails, getVendorData } from "../../../services/Api";

const Finance = () => {
  const [collected, setCollected] = useState(0);
  const [paid, setPaid] = useState(0);
  const { selectedBusiness, userDetailsId } = useDataContext();

  useEffect(() => {
    getCustomerData();
    getVendorDetails();
  }, []);

  const getCustomerData = async () => {
    const result = await getCustomerDetails(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setCollected(result.data.collected);
  };

  const getVendorDetails = async () => {
    const result = await getVendorData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setPaid(result.data.paid);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-lg md:text-2xl xl:text-3xl">
              Finance
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/finance" className="no-underline">
                Finance
              </NavLink>
            </p>
          </div>

          <div className="relative mx-2 xl:mx-5 sm:mx-3 mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 sm:mr-3 mt-4">
          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem] mb-2 md:mb-0">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              Collected
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              &#x20B9; {collected}
            </p>
          </div>

          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem]">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              Paid
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              &#x20B9; {paid.toFixed(2)}
            </p>
          </div>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div className="flex items-center shadow-xl h-[5rem] md:h-[6rem] lg:h-[7rem] border-[#d9d8d8] border-[1px] w-full rounded-sm p-3 lg:px-4">
            <div className="bg-[#ededed] p-[0.4rem] rounded-md lg:rounded-lg">
              <img
                src={finance3}
                alt="icon"
                className="w-[1.4rem] h-[1.5rem]"
              />
            </div>
            <div>
              <NavLink to="/requestMoney" className="no-underline">
                <p className="text-[#000000] mb-1 mx-3 font-semibold text-base">
                  Request Money
                </p>
              </NavLink>
              <p className="text-[#6D6D6D] mb-1 mx-3 text-[0.6rem]">
                0.0 Pending
              </p>
            </div>
          </div>

          <div className="flex items-center  h-[5rem]  md:h-[6rem] lg:h-[7rem] shadow-xl border-[#d9d8d8] border-[1px] w-full rounded-sm p-3 lg:px-4">
            <div className="bg-[#ededed] p-[0.4rem] rounded-md lg:rounded-lg">
              <img
                src={finance4}
                alt="icon"
                className="w-[1.4rem] h-[1.5rem]"
              />
            </div>
            <div>
              <NavLink to="/createInvoice" className="no-underline">
                <p className="text-[#000000] mb-1 mx-3 font-semibold text-base">
                  Create Invoice
                </p>
              </NavLink>
              <p className="text-[#6D6D6D] mb-1 mx-3 text-[0.6rem]">
                Create Invoice for your products
              </p>
            </div>
          </div>

          <div className="flex items-center  h-[5rem]  md:h-[6rem] lg:h-[7rem] shadow-xl border-[#d9d8d8] border-[1px] w-full rounded-sm p-3 lg:px-4">
            <div className="bg-[#ededed] p-[0.4rem] rounded-md lg:rounded-lg">
              <img
                src={finance1}
                alt="icon"
                className="w-[1.4rem] h-[1.5rem]"
              />
            </div>
            <div>
              <NavLink to="/reports" className="no-underline">
                <p className="text-[#000000] mb-1 mx-3 font-semibold text-base">
                  Reports
                </p>
              </NavLink>
              <p className="text-[#6D6D6D] mb-1 mx-3 text-[0.6rem]">
                View all Reports
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Finance;
