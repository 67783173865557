import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../contexts/DataContext";
import { dateConvert } from "../../../utilities/convertDate";
import { NavLink } from "react-router-dom";
import { fetchInvoice } from "../../../services/Api";

const InvoiceHistory = () => {
  const [getInvoiceData, setGetInvoiceData] = useState([]);
  const [handleInvoiceSearchData, setHandleInvoiceSearchData] = useState([]);
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const getInvoice = async () => {
    const data = await fetchInvoice(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setHandleInvoiceSearchData(data && data.data);
    setGetInvoiceData(data && data.data);
  };

  const handleSearch = () => {
    // Filter the data based on the user's search.
    const filteredData =
      handleInvoiceSearchData.length > 0
        ? handleInvoiceSearchData.filter((item) => item.ID === parseInt(search))
        : [];

    if (filteredData.length > 0) setGetInvoiceData(filteredData);
    else setGetInvoiceData(handleInvoiceSearchData);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
              My Invoices
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/finance" className="no-underline">
                Finance
              </NavLink>
              &nbsp; &gt; &nbsp; My Invoices
            </p>
          </div>

          <div className="relative mx-2 xl:mx-5 sm:mx-3 mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search by ID..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          {getInvoiceData
            .slice()
            .reverse()
            .map((item, index) => {
              // Format the date
              let formattedDate = dateConvert(item.CreatedAt);

              return (
                <div
                  className="flex justify-between shadow-lg border border-gray-300 w-full h-[4rem] md:h-[5rem] lg:h-[7rem]"
                  key={index}
                >
                  <div className="flex flex-col justify-center mx-2 lg:mx-4">
                    <p className="text-[#3850bd] text-[0.8rem] lg:text-[1.3rem] font-semibold">
                      Invoice Id:{item.ID}
                    </p>
                    <p className="text-[#000000] text-[0.8rem] font-semibold">
                      {formattedDate}
                    </p>
                  </div>
                  <a
                    href={item.image_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer bg-[#384b9d] text-[#fbfbfb] h-fit text-[0.6rem] lg:text-[0.8rem] p-1 lg:p-2 "
                  >
                    View Invoice
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default InvoiceHistory;
