import React, { useState } from "react";
import ToCollect from "./ToCollect";
import Collected from "./Collected";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

const AddEntryCustomer = () => {
  const [isToggle, setIsToggle] = useState(false);
  let { id } = useParams();

  function customerButton() {
    setIsToggle(false);
  }

  function vendorButton() {
    setIsToggle(true);
  }

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
              Add Entry
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/addEntry-customer" className="no-underline">
                Add Entry
              </NavLink>
            </p>
          </div>

          <div className="relative mx-2 xl:mx-5 sm:mx-3 mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="flex justify-start items-center mt-3">
          <button
            className={`${
              isToggle
                ? "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                : "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
            }
              w-[4rem] min-[350px]:w-[5rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] min-[350px]:text-xs xl:text-[1rem] rounded`}
            onClick={customerButton}
          >
            To Collect
          </button>

          <button
            className={`${
              isToggle
                ? "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
                : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
            }
              w-[4rem] ml-2 min-[350px]:w-[5rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem]  min-[350px]:text-xs xl:text-[1rem] rounded`}
            onClick={vendorButton}
          >
            Collected
          </button>
        </div>

        {isToggle ? (
          <Collected customerId={id} />
        ) : (
          <ToCollect customerId={id} />
        )}
      </div>
    </>
  );
};

export default AddEntryCustomer;
