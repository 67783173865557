import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useDataContext } from "../../../contexts/DataContext";
import axios from "axios";
import {
  createBusiness,
  fetchBusinessCategoryData,
} from "../../../services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateBusinessAcc = () => {
  const [getCategory, setGetCategory] = useState({});
  const navigate = useNavigate();
  const [businessCategory, setBusinessCategory] = useState("");
  const [businessCategoryId, setBusinessCategoryId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [allState, setAllState] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const { userDetailsId } = useDataContext();

  useEffect(() => {
    getBusinessCategory();
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.name,
      displayValue: state.name,
      isoCode: state.isoCode,
    }));

    setAllState(states);
  }, []);

  useEffect(() => {
    const isoCode = allState.filter((item) => {
      return item.displayValue === state && item.isoCode;
    });

    if (isoCode.length > 0) {
      const cityData = City.getCitiesOfState("IN", isoCode[0].isoCode).map(
        (city) => ({
          value: city.name,
          displayValue: city.name,
        })
      );

      setAllCity(cityData);
    }
  }, [state]);

  useEffect(() => {
    if (businessCategory) {
      const businessCategoryId = getCategory.data.filter((item) => {
        return item.category_name === businessCategory && item.ID;
      });
      setBusinessCategoryId(businessCategoryId[0].ID);
    }
  }, [businessCategory]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    getImageUrl(selectedFile);
  };

  const getBusinessCategory = async () => {
    const data = await fetchBusinessCategoryData();

    setGetCategory(data);
    //   setBusinesses(data.data.businesses);
  };

  const addBusiness = async () => {
    if (
      imageUrl &&
      businessCategory &&
      businessName &&
      state &&
      city &&
      address
    ) {
      const reqData = {
        businesses: [
          {
            business_name: businessName,
            business_category_id: parseInt(businessCategoryId),
            address: address,
            business_image_url: imageUrl,
            city: city,
            state: state,
            user_id: userDetailsId.data.ID,
          },
        ],
      };

      const data = await createBusiness(reqData);

      navigate("/");  

      window.location.reload(); 
    } else {
      toast.error("Please complete all mandatory fields.", {
        position: "top-center",
      });
    }
  };

  const getImageUrl = (e) => {
    const formValue = new FormData();
    formValue.append("file", e);
    const url = "/api/mitra-merchant/doc-upload";
    // const finalURL = url.replace("https://retailorder.ayekart.com", "");
    axios
      .post(
        // http://139.59.91.183:3003/api/mitra-merchant/doc-upload
        url,
        formValue
      )
      .then((response) => {
        setImageUrl(response.data.file_location);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <>
      <div>
        <div className="flex justify-center items-center mt-7">
          <h1 className="text-[#0e3293] font-bold text-[1rem] md:text-2xl xl:text-3xl">
            Tell Us About Your Business
          </h1>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4 bg-[#EDEDED] border border-gray-100 shadow-md w-[97%] md:w-[98%] rounded-md p-4">
          <div>
            <label
              for="Purchased Quantity"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Business Category*
            </label>
            <select
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={businessCategory}
              onChange={(e) => setBusinessCategory(e.target.value)}
            >
              {getCategory.data &&
                getCategory.data.map((item, index) => (
                  <option key={index}>{item.category_name}</option>
                ))}
            </select>
          </div>

          <div>
            <label
              for="Purchased Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Business Name*
            </label>
            <input
              type="text"
              id="Purchased Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Business Name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Product Image"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Business Image*
            </label>
            <input
              type="file"
              id="Product Image"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Business Name"
              onChange={handleFileChange}
            />
          </div>

          <div>
            <label
              for="Purchased Quantity"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              State*
            </label>
            <select
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              {allState.map((item, index) => (
                <option key={index}>{item.displayValue}</option>
              ))}
            </select>
          </div>

          <div>
            <label
              for="Purchased Quantity"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              City*
            </label>
            <select
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              {allCity.map((item, index) => (
                <option key={index}>{item.displayValue}</option>
              ))}
            </select>
          </div>

          <div>
            <label
              for="Purchased Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Address*
            </label>
            <input
              type="text"
              id="Purchased Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 py-2 lg:px-4 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={addBusiness}
          >
            Add Business
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateBusinessAcc;
