import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import OrderDetailsCard from "./OrderDetailsCard";
import { useDataContext } from "../../../contexts/DataContext";

const OrderDetails = () => {
  const { id, status } = useParams();
  const { myOrdersData } = useDataContext();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    filteredDetails();
  }, []);

  const filteredDetails = ()=>{
     const updatedData = myOrdersData.filter((item) => item.ID === parseInt(id));
     setFilteredData(updatedData);
  }

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-bold text-[0.9rem] md:text-3xl xl:text-3xl">
              Order Id: {id}
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.8rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/myOrder" className="no-underline">
                My Orders
              </NavLink>
              &nbsp; &gt; &nbsp; Order Detail
            </p>
          </div>
        </div>

        <div>
          <OrderDetailsCard filteredData={filteredData}/>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
