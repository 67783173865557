import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function profileData(userId) {
  try {
    const response = await fetch(`/v1/user/${userId}/me`);

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function bankData(businessId) {
  try {
    const response = await fetch(
      `/ayekart/api/hisab/bank_details/?business_id=${businessId}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function saveBankDetails(reqData) {
  try {
    const url = `/verify/v1/bank-verification/`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzODg3OTY5MCwianRpIjoiZjQ5ZWFiNGUtYzdlMS00Njc2LTgyYTEtZmQzZjM0NzQwMjRkIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LmF5ZWthcnRAYWFkaGFhcmFwaS5pbyIsIm5iZiI6MTYzODg3OTY5MCwiZXhwIjoxOTU0MjM5NjkwLCJ1c2VyX2NsYWltcyI6eyJzY29wZXMiOlsicmVhZCJdfX0.-nN8JRZ0ClRLojFWxveXVrq7WFiNQszNnzK5FC7Pz_c`,
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Updated successfully.", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Verification failed.", {
      position: "top-center",
    });
    console.log(error);
  }
}
