import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ayekart from "../../../../images/ayekart-default.png";
import view from "../../../../images/finance-1.svg";
import collect from "../../../../images/collectNow1.svg";
import remind from "../../../../images/remind1.svg";
import {
  customerDetailsApi,
  vendorDetailsApi,
} from "../../../../services/Api";
import { useParams } from "react-router-dom";
import { useDataContext } from "../../../../contexts/DataContext";
import { dateTimeConvert } from "../../../../utilities/convertDate";
import Pagination from "../../pagination/Pagination";

const VendorProfile = () => {
  const [isToggle, setIsToggle] = useState(false);
  const { id } = useParams();
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [customerData, setCustomerData] = useState("");
  const [customerHisab, setCustomerHisab] = useState([]);
  const [search, setSearch] = useState("");

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    getVendorData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    setRecords(customerHisab.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(customerHisab.length / recordsPerPage));
    maxmLimitPage();
  }, [customerHisab, firstIndex, lastIndex, isToggle]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, customerHisab.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(customerHisab.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const getVendorData = async () => {
    const data = await vendorDetailsApi(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      id
    );
    setCustomerData(data.data);
    setCustomerHisab(data.data.vendor_hisab.reverse());
  };

  function mostRecentButton() {
    setIsToggle(false);
    setCustomerHisab(customerHisab.reverse());
  }

  function oldestButton() {
    setIsToggle(true);
    setCustomerHisab(customerHisab.reverse());
  }

  const handleSearch = () => {
    //for customers
    const customerDetails = customerData.vendor_hisab
      ? customerData.vendor_hisab.filter((item) =>
          item.item_name.toLowerCase().includes(search.toLowerCase())
        )
      : [];
    setCustomerHisab(customerDetails);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-6 sticky top-0 z-10 bg-white shadow-sm w-[97%] md:w-[98%] rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-lg md:text-2xl xl:text-3xl">
              Vendor Profile
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp; Vendor Profile
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="flex items-center mt-3 border border-gray-300 shadow-xl w-[97%] md:w-[98%] rounded-md p-4 md:p-6 xl:p-8 bg-[#EDEDED]">
          <img
            src={
              customerData.profile_image_url
                ? customerData.profile_image_url
                : ayekart
            }
            alt="icon"
            className="rounded-[90%] w-[2.5rem] md:w-[3.5rem] lg:w-[4rem] xl:w-[4.5rem] h-[2.5rem] md:h-[3.5rem] lg:h-[4rem] xl:h-[4.5rem] border-dashed border border-[#0E3293] p-[0.1rem]"
          />
          <div className="ml-3">
            <p className="font-bold text-[#384B9D] text-[0.8rem] md:text-[1rem] xl:text-[1.3rem]">
              {customerData.first_name} {customerData.last_name}
            </p>
            <p className="text-[#585858] text-[0.6rem] md:text-[0.8rem] xl:text-[1rem] font-bold">
              {customerData.mobile}
            </p>
          </div>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 sm:mr-3 mt-4">
          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem] mb-2 md:mb-0">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              To Pay
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              &#x20B9;{" "}
              {customerData.vendor_hisab &&
                customerData.vendor_hisab.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + currentValue.to_pay;
                  },
                  0
                )}
            </p>
          </div>

          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem]">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              Paid
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              &#x20B9;{" "}
              {customerData.vendor_hisab &&
                customerData.vendor_hisab.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + currentValue.paid;
                  },
                  0
                )}
            </p>
          </div>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div className="flex items-center shadow-xl h-[5rem] md:h-[6rem] lg:h-[7rem] border-[#d9d8d8] border-[1px] w-full rounded-sm p-3 lg:px-4">
            <div className="bg-[#ededed] p-[0.4rem] rounded-md lg:rounded-lg">
              <img src={collect} alt="icon" className="w-[1.4rem] h-[1.5rem]" />
            </div>
            <div>
              <NavLink to="/" className="no-underline">
                <p className="text-[#000000] mb-1 mx-3 font-semibold text-base">
                  Pay Now
                </p>
              </NavLink>
            </div>
          </div>

          <div className="flex items-center  h-[5rem]  md:h-[6rem] lg:h-[7rem] shadow-xl border-[#d9d8d8] border-[1px] w-full rounded-sm p-3 lg:px-4">
            <div className="bg-[#ededed] p-[0.4rem] rounded-md lg:rounded-lg">
              <img src={view} alt="icon" className="w-[1.4rem] h-[1.5rem]" />
            </div>
            <div>
              <NavLink to={`/viewReportsVendor/${id}`} className="no-underline">
                <p className="text-[#000000] mb-1 mx-3 font-semibold text-base">
                  View Reports
                </p>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center w-[97%] md:w-[98%]">
          <div className="flex items-center">
            <button
              className={`${
                isToggle
                  ? "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                  : "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
              }
                w-[4rem] mr-1 md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] md:text-xs xl:text-[1rem] rounded`}
              onClick={mostRecentButton}
            >
              Most Recent
            </button>

            <button
              className={`${
                isToggle
                  ? "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
                  : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
              }
                w-[4rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] md:text-xs xl:text-[1rem] rounded`}
              onClick={oldestButton}
            >
              Oldest
            </button>
          </div>
          <div>
            <NavLink to={`/addEntry-vendor/${id}`} className="no-underline">
              <button className="text-white border bg-[#384B9D] w-[1.8rem] lg:w-[2rem] text-[0.9rem] md:text-[1.2rem] rounded-md ">
                +
              </button>
            </NavLink>
          </div>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 lg:grid-cols-3 sm:mr-3 mt-4 lg:mt-10">
          {records &&
            records.map((item, index) => {
              let formattedDate = dateTimeConvert(item.CreatedAt);

              return (
                <div
                  key={index}
                  className="overflow-auto w-full h-[5rem] md:h-[5.5rem] lg:h-[6rem] shadow-xl border-[#d9d8d8] border-[1px] rounded-sm md:rounded-md p-2 lg:px-3"
                >
                  <div className="flex justify-center  flex-col">
                    <div className="flex flex-col">
                      <p className="text-[#3850bd] font-semibold text-sm md:text-[1rem]">
                        {item.item_name}
                      </p>
                      <p className="text-[#000000] text-[0.6rem] md:text-[0.8rem] leading-6 ">
                        {formattedDate}
                      </p>
                    </div>

                    <p className="text-[#3850bd] font-semibold text-[0.6rem] md:text-[0.7rem] lg:text-[0.9rem] md:mt-[0.3rem] lg:mt-[0.5rem]">
                      Paid: &#x20B9; {item.paid.toFixed(1)}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>

        {/* pagination */}
        <Pagination
          prevPage={prevPage}
          changePage={changePage}
          nextPage={nextPage}
          numbers={numbers}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default VendorProfile;
