import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import FinanceGraph from "./reports-component/FinanceGraph";
import ProductGraph from "./reports-component/ProductGraph";
import { useDataContext } from "../../../contexts/DataContext";
import {
  monthlyChartApi,
  weeklyChartApi,
  yearlyChartApi,
} from "../../../services/ChartApi";
import HomeMiddleChart from "../Home/HomeMiddleChart";
import StockGraph from "./reports-component/StockGraph";
import StockReports from "./reports-component/StockReports";
import CustomerReport from "./reports-component/CustomerReport";
import StaffReport from "./reports-component/StaffReport";

const Reports = () => {
  const [getDate, setGetDate] = useState("");
  const [getLastDate, setGetLastDate] = useState("");
  const [graph, setGraph] = useState("Finance Graph");
  const [defaultValueDate, setDefaultValueDate] = useState("");
  const { selectedBusiness } = useDataContext();
  const [chartSelectedFormat, setChartSelectedFormat] = useState("Weekly");
  const [chartStockFormat, setChartStockFormat] = useState("Monthly");
  const [selectedDate, setSelectedDate] = useState(defaultValueDate);
  const [selectedDateStock, setSelectedDateStock] = useState(defaultValueDate);
  const [chartApiData, setChartApiData] = useState([]);
  const [defaultFirstDate, setDefaultFirstDate] = useState("");
  const [defaultLastDate, setDefaultLastDate] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    // getting current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Months are zero-based

    setDefaultValueDate(`${currentYear}-${currentMonth}`);

    // First date of current month
    firstDay();

    // Last date of current month
    lastDay();
  }, []);

  useEffect(() => {
    // getting current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Months are zero-based

    setDefaultValueDate(`${currentYear}-${currentMonth}`);
  }, []);

  useEffect(() => {
    setSelectedDate(defaultValueDate);
    //call chart API
    getWeeklyChartData(defaultValueDate);
  }, [defaultValueDate]);

  useEffect(() => {
    //call chart API
    if (chartSelectedFormat === "Weekly") getWeeklyChartData(selectedDate);
    else if (chartSelectedFormat === "Monthly")
      getMonthlyChartData(selectedDate);
    else if (chartSelectedFormat === "Yearly") getYearlyChartData();
  }, [chartSelectedFormat, selectedDate]);

  const getWeeklyChartData = async (date) => {
    if (date) {
      const dateString = date;
      const [year, month] = dateString.split("-");

      const chartData = await weeklyChartApi(
        selectedBusiness.id ? selectedBusiness.id : 2,
        month,
        year
      );
      setChartApiData(chartData ? chartData.Data : []);
    }
  };

  const getMonthlyChartData = async (date) => {
    if (date) {
      const dateString = date;
      const [year, month] = dateString.split("-");

      const chartData = await monthlyChartApi(
        selectedBusiness.id ? selectedBusiness.id : 2,
        year
      );

      setChartApiData(chartData ? chartData.Data : []);
    }
  };

  const getYearlyChartData = async () => {
    const chartData = await yearlyChartApi(
      selectedBusiness.id ? selectedBusiness.id : 2
    );

    setChartApiData(chartData ? chartData.Data : []);
  };

  const firstDay = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const year = firstDay.getFullYear();
    const month = String(firstDay.getMonth() + 1).padStart(2, "0");
    const day = String(firstDay.getDate()).padStart(2, "0");
    setDefaultFirstDate(`${year}-${month}-${day}`);
  };

  const lastDay = () => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const year = lastDay.getFullYear();
    const month = String(lastDay.getMonth() + 1).padStart(2, "0");
    const day = String(lastDay.getDate()).padStart(2, "0");
    setDefaultLastDate(`${year}-${month}-${day}`);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-medium text-lg md:text-2xl xl:text-3xl">
              Reports
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/finance" className="no-underline">
                Finance
              </NavLink>
              &nbsp; &gt; &nbsp; Reports
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between w-[97%] md:w-[98%] mt-5 items-center">
          <div>
            <select
              className="appearance-auto bg-[#384B9D] cursor-pointer text-[#FBFBFB] outline-0 text-center w-[5rem] min-[400px]:w-fit font-semibold py-[0.25rem] lg:py-[0.3rem] px-1 text-[0.6rem] lg:text-[0.8rem] md:text-xs xl:text-[1rem] hover:border-transparent rounded"
              value={graph}
              onChange={(e) => setGraph(e.target.value)}
            >
              <option value="Finance Graph">Finance Graph</option>
              <option value="Product Graph">Product Graph</option>
              <option value="Stock Graph">Stock Graph</option>
              <option value="Stock Reports">Stock Reports</option>
              <option value="Customer Reports">Customer Reports</option>
              <option value="Staff Reports">Staff Reports</option>
            </select>
          </div>

          {/* Finance button */}
          {graph === "Finance Graph" && (
            <div>
              <input
                type="month"
                className="text-center ml-1 sm:ml-3 w-[6rem] md:w-[8rem] lg:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.38rem]"
                defaultValue={defaultValueDate}
                onChange={(e) => setGetDate(e.target.value)}
              />
            </div>
          )}

          {/* Product Graph button */}
          {graph === "Product Graph" && (
            <div className="flex">
              <div>
                <select
                  className="appearance-auto bg-[#384B9D] cursor-pointer text-[#FBFBFB] outline-0 text-center w-[4rem] min-[375px]:w-fit font-semibold py-[0.24rem] lg:py-[0.3rem] px-1 text-[0.6rem] lg:text-[0.8rem] md:text-xs xl:text-[1rem] hover:border-transparent rounded"
                  value={chartSelectedFormat}
                  onChange={(e) => setChartSelectedFormat(e.target.value)}
                >
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>

              {chartSelectedFormat !== "Yearly" && (
                <div>
                  <input
                    type="month"
                    className="text-center ml-1 sm:ml-3 w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.18rem] md:py-[0.23rem] lg:py-[0.3rem] xl:py-[0.4rem]"
                    defaultValue={defaultValueDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
              )}
            </div>
          )}

          {/* Stock Graph button */}
          {graph === "Stock Graph" && (
            <div className="flex">
              <div>
                <select
                  className="appearance-auto bg-[#384B9D] cursor-pointer text-[#FBFBFB] outline-0 text-center w-[4rem] min-[375px]:w-fit font-semibold py-[0.24rem] lg:py-[0.3rem] px-1 text-[0.6rem] lg:text-[0.8rem] md:text-xs xl:text-[1rem] hover:border-transparent rounded"
                  value={chartStockFormat}
                  onChange={(e) => setChartStockFormat(e.target.value)}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>

              <div>
                <input
                  type="month"
                  className="text-center ml-1 sm:ml-3 w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.18rem] md:py-[0.23rem] lg:py-[0.3rem] xl:py-[0.4rem]"
                  defaultValue={defaultValueDate}
                  onChange={(e) => setSelectedDateStock(e.target.value)}
                />
              </div>
            </div>
          )}

          {/* Stock Reports button */}
          {graph === "Stock Reports" && (
            <div>
              <input
                type="date"
                className="text-center ml-1 sm:ml-3 w-[4rem] min-[375px]:w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.37rem]"
                defaultValue={defaultFirstDate}
                onChange={(e) => setGetDate(e.target.value)}
              />

              <input
                type="date"
                className="text-center ml-1 sm:ml-3 w-[4rem] min-[375px]:w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.37rem]"
                defaultValue={defaultLastDate}
                onChange={(e) => setGetLastDate(e.target.value)}
              />
            </div>
          )}

          {/* Customer Reports button */}
          {graph === "Customer Reports" && (
            <div>
              <input
                type="date"
                className="text-center ml-1 sm:ml-3 w-[4rem] min-[375px]:w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.37rem]"
                defaultValue={defaultFirstDate}
                onChange={(e) => setGetDate(e.target.value)}
              />

              <input
                type="date"
                className="text-center ml-1 sm:ml-3 w-[4rem] min-[375px]:w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.37rem]"
                defaultValue={defaultLastDate}
                onChange={(e) => setGetLastDate(e.target.value)}
              />
            </div>
          )}

          {graph === "Staff Reports" && (
            <div>
              <input
                type="month"
                className="text-center ml-1 sm:ml-3 w-[6rem] md:w-[8rem] lg:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.37rem]"
                defaultValue={defaultValueDate}
                onChange={(e) => setGetDate(e.target.value)}
              />
            </div>
          )}
        </div>

        <div>
          {graph === "Finance Graph" && (
            <FinanceGraph
              defaultValueDate={defaultValueDate}
              getDate={getDate}
            />
          )}

          {graph === "Product Graph" && (
            <div className="w-[97%] md:w-[98%] mt-[2rem]">
              <HomeMiddleChart
                chartData={chartApiData}
                format={chartSelectedFormat}
              />
            </div>
          )}

          {graph === "Stock Graph" && (
            <div className="w-[97%] md:w-[98%] mt-[2rem]">
              <StockGraph
                defaultValueDate={defaultValueDate}
                getDate={selectedDateStock}
                format={chartStockFormat}
              />
            </div>
          )}

          {graph === "Stock Reports" && (
            <div className="w-[97%] md:w-[98%] mt-[2rem]">
              <StockReports
                defaultFirstDate={defaultFirstDate}
                defaultLastDate={defaultLastDate}
                from={getDate}
                to={getLastDate}
                search={search}
              />
            </div>
          )}

          {graph === "Customer Reports" && (
            <div className="w-[97%] md:w-[98%] mt-[2rem]">
              <CustomerReport
                defaultFirstDate={defaultFirstDate}
                defaultLastDate={defaultLastDate}
                from={getDate}
                to={getLastDate}
                search={search}
              />
            </div>
          )}

          {graph === "Staff Reports" && (
            <StaffReport
              defaultValueDate={defaultValueDate}
              getDate={getDate}
              search={search}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Reports;
