import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { stockDetailsApi, updateStockDetails } from "../../../services/Api";
import { useDataContext } from "../../../contexts/DataContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddStockEntry = () => {
  const [stockStatus, setStockStatus] = useState("Purchased");
  const [availableUnits, setAvailableUnits] = useState("");
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [stockData, setStockData] = useState({});
  const [qty, setQty] = useState("");
  const [amount, setAmount] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getStockData();
  }, []);

  const getStockData = async () => {
    const data = await stockDetailsApi(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      id
    );

    // setStockEntries(data.data.stock_entries);
    setStockData(data.data);
    setAvailableUnits(
      data.data.item_purchased -
        (data.data.item_sold + data.data.item_damaged + data.data.item_returned)
    );
  };

  const saveData = async () => {
    if (parseInt(availableUnits) >= parseInt(qty) || stockStatus === "Purchased") {
      const reqData = {
        business_id: (selectedBusiness.id ? selectedBusiness.id : 2).toString(),
        damaged_value: "0.0",
        entry_type: stockStatus,
        image_url: stockData.stock_entries[0].image_URL,
        item_available_units: stockData.stock_entries[0].item_available_units,
        item_damaged: stockStatus === "Damaged" ?  qty : 0,
        item_name: stockData.item_name,
        item_purchased: stockStatus === "Purchased" ?  qty : 0,
        item_returned: stockStatus === "Returned" ?  qty : 0,
        item_sold: stockStatus === "Sold" ?  qty : 0,
        product_id: stockData.product_id,
        purchased_value: "0.0",
        returned_value: "0",
        sold_value: "0.0",
        stock_value: amount,
      };

      const data = await updateStockDetails(reqData);

      if (data) navigate(-1);
    }
    else
    {
      toast.error("Out of Stock - Your available stock is less, please update purchased entry", {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-6 sticky top-0 z-10 bg-white shadow-sm w-[97%] md:w-[98%] rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-lg md:text-2xl xl:text-3xl">
              Add Stock Entry
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/inventory" className="no-underline">
                Inventory
              </NavLink>
              &nbsp; &gt; &nbsp; Add Stock Entry
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="mt-3">
          <select
            className="appearance-auto bg-[#384B9D] text-[#FBFBFB] outline-0 text-center w-[5rem] md:w-[6rem] lg:w-[7rem] font-semibold py-1 lg:py-2 px-1 text-[0.6rem] md:text-xs lg:text-[1rem] hover:border-transparent rounded"
            value={stockStatus}
            onChange={(e) => setStockStatus(e.target.value)}
          >
            <option value="Purchased">Purchased</option>
            <option value="Sold">Sold</option>
            <option value="Damaged">Damaged</option>
            <option value="Returned">Returned</option>
          </select>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4 bg-[#ededed] p-[1rem] lg:p-[2rem] rounded-md">
          <div>
            <input
              type="text"
              id="Monthly Salary"
              className="bg-gray-50 outline-none border border-gray-300 text-[#384B9D] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Stock Name"
              value={stockData.item_name}
              readOnly
            />
          </div>

          <div>
            <input
              type="number"
              id="Monthly Salary"
              className="bg-gray-50 outline-none border border-gray-300 text-[#384B9D] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Quantity* "
              value={qty}
              onChange={(e) => {
                setQty(e.target.value);
                setAmount(stockData.product.buying_price * e.target.value);
              }}
            />
          </div>

          <div>
            <input
              type="number"
              id="Monthly Salary"
              className="bg-gray-50 outline-none border border-gray-300 text-[#384B9D] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Amount* "
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#384B9D] cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-5 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={saveData}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default AddStockEntry;
