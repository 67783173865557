import React, { useEffect, useState } from "react";
import { stockChartApi } from "../../../../services/ChartApi";
import { useDataContext } from "../../../../contexts/DataContext";
import StockChart from "./charts/StockChart";

const StockGraph = ({ defaultValueDate, getDate, format }) => {
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [chartData, setChartData] = useState([]);
  const [soldAmount, setSoldAmount] = useState("");
  const [purchasedAmount, setPurchasedAmount] = useState("");

  useEffect(() => {
    getChartData(defaultValueDate);
  }, [defaultValueDate]);

  useEffect(() => {
    getChartData(getDate);
  }, [getDate, format]);

  useEffect(() => {
    const soldSum = chartData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.sold_value,
      0
    );
    setSoldAmount((soldSum / 1000).toFixed(2));

    // sum of paid amount
    const purchasedSum = chartData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.purchased_value,
      0
    );
    setPurchasedAmount((purchasedSum / 1000).toFixed(2));
  }, [chartData]);

  const getChartData = async (date) => {
    if (date) {
      const data = await stockChartApi(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        format === "Monthly" ? date + "-01" : date.substring(0, 4) + "-01-01",
        format === "Monthly" ? date + "-28" : date.substring(0, 4) + "-12-31"
      );

      setChartData(data.data.Stocks);
    }
  };

  return (
    <>
      <div>
        <div className="grid gap-4 mb-6 md:grid-cols-2 mt-4">
          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem] mb-2 md:mb-0">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              Stock Sold
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              &#x20B9; {soldAmount}k
            </p>
          </div>

          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem]">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              Stock Purchased
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              &#x20B9; {purchasedAmount}k
            </p>
          </div>
        </div>

        <div>
          <StockChart chartValue={chartData} />
        </div>
      </div>
    </>
  );
};

export default StockGraph;
