import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function getInventoryProductData(businessId) {
  const url = `/ayekart/api/hisab/product/get_product_byBusinessId?business_id=${businessId}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    // throw error;
  }
}

export async function getInventoryStockData(userId, businessId) {
  const url = `/v1/user/${userId}/business/${businessId}/stock/stock-entries`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
  }
}

export async function getUserDetails(userPhnNo) {
  const url = `/v1/user/me?uid=${userPhnNo}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    // throw error;
  }
}

export async function getUserDetailsWithId(userId) {
  const url = `/v1/user/${userId}/me`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    // throw error;
  }
}

export async function getProduct(businessId) {
  const url = `/ayekart/api/hisab/product/get_product_byBusinessId?business_id=${businessId}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    // throw error;
  }
}

export async function getStock(businessId) {
  const url = `/ayekart/api/hisab/stock_entries/get_stock_entry_by_business_Id?business_id=${businessId}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    // throw error;
  }
}

export async function createStockEntry(stockEntryData) {
  const url = "/ayekart/api/hisab/stock_entries/create_stock_entry";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(stockEntryData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Stock created successfully", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });

    // throw error;
  }
}

export async function saveCustomer(userId, businessId, reqData) {
  const url = `/v1/user/${userId}/business/${businessId}/customer`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("New customer added successfully.", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });

    // throw error;
  }
}

export async function saveVendorData(userId, businessId, reqData) {
  const url = `/v1/user/${userId}/business/${businessId}/vendor`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("New vendor added successfully.", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });
  }
}

export async function saveNewProduct(reqData) {
  const url = `/ayekart/api/hisab/product/create_product`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Product added successfully", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Product Already Exists", {
      position: "top-center",
    });
    // throw error;
  }
}

export async function createProduct(stockEntryData) {
  const url = "/ayekart/api/hisab/product/create_product";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(stockEntryData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Product created successfully", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });

    // throw error;
  }
}

export async function createBusiness(reqData) {
  const url = "/v1/user/business/create";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Business created successfully", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });

    // throw error;
  }
}

export async function editInventoryProduct(data) {
  try {
    const response = await fetch("/ayekart/api/hisab/product/edit_product", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();

    toast.success("Product updated successfully", {
      position: "top-center",
    });

    return responseData;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });

    // throw error;
  }
}

export const fetchStaffData = async (userId, businessId) => {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/staff`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching staff data:", error);
    return null;
  }
};

export const fetchBusinessCategoryData = async () => {
  try {
    const response = await fetch(`/v1/user/business/category`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching staff data:", error);
    return null;
  }
};

export const fetchStaffDataWithDate = async (
  userId,
  businessId,
  selectedDate
) => {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/staff?date=${selectedDate}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching staff data:", error);
    return null;
  }
};

export const updateAttendance = async (
  userId,
  businessId,
  staffId,
  attendanceId,
  reqData
) => {
  try {
    const url = `/v1/user/${userId}/business/${businessId}/staff/${staffId}/attendance/${attendanceId}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating attendance:", error);
    return null;
  }
};

export const saveAttendance = async (userId, businessId, staffId, reqData) => {
  try {
    const url = `/v1/user/${userId}/business/${businessId}/staff/${staffId}/attendance`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating attendance:", error);
    return null;
  }
};

export const addStaff = async (userId, businessId, reqData) => {
  try {
    const url = `/v1/user/${userId}/business/${businessId}/staff`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Staff created successfully", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });
    return null;
  }
};

export const updateSalary = async (userId, businessId, staffId, reqData) => {
  try {
    const url = `/v1/user/${userId}/business/${businessId}/staff/${staffId}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating attendance:", error);
    return null;
  }
};

export const deleteStaffData = async (userId, businessId, staffId) => {
  try {
    const url = `/v1/user/${userId}/business/${businessId}/staff/${staffId}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    // throw error;
  }
};

export async function hisabEntryCustomer(
  userId,
  businessId,
  customerId,
  stockEntryData
) {
  const url = `/v1/user/${userId}/business/${businessId}/customer/${customerId}/hisab-entry`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(stockEntryData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Data saved successfully.", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });
    // throw error;
  }
}

export async function hisabEntryVendor(
  userId,
  businessId,
  vendorId,
  stockEntryData
) {
  const url = `/v1/user/${userId}/business/${businessId}/vendor/${vendorId}/hisab-entry`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(stockEntryData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Save entry successfully.", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });
  }
}

export const getCustomerDetails = async (userId, businessId) => {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/home`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getVendorData = async (userId, businessId) => {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/vendor/home`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getUserStats = async () => {
  try {
    const response = await fetch(`/order/merchant/9/user-stats`);

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getPartners = async () => {
  try {
    const response = await fetch(
      `/order/business/merchant/9/rating?page=1&limit=5&review_type=%27MERCHANT%27&deliverable_locations=%27Pune%20Division%27,%27ALLINDIA%27`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getMerchants = async () => {
  try {
    const response = await fetch(
      `/order/merchant/9/past-relation?limit=4&page=1`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getShopByCategory = async () => {
  try {
    const response = await fetch(
      `/order/merchant/9/allcat?except_master_list=true&deliverable_locations=%27null%27,%27ALLINDIA%27`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getPreviousProducts = async () => {
  try {
    const response = await fetch(
      `/order/merchant/9/ordered-products?limit=3&page=1`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export async function addScf(userId, businessId, reqData) {
  const url = `/v1/user/${userId}/business/${businessId}/scf`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // throw error;
    console.log(error);
  }
}

export async function ordersProcessingData(merchantId) {
  try {
    const response = await fetch(
      `/order/merchant/${merchantId}/orders?except_status=CANCELLED,Order%20Delivered&page=1&limit=10`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function ordersDeliveredData(merchantId) {
  try {
    const response = await fetch(
      `/order/merchant/${merchantId}/orders?order_status=Order%20Delivered&page=1&limit=10`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function ordersCancelledData(merchantId) {
  try {
    const response = await fetch(
      `/order/merchant/${merchantId}/orders?order_status=CANCELLED&page=1&limit=10`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function stockDetailsApi(userId, businessId, productId) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/products/${productId}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function updateStockDetails(reqData) {
  const url = `/ayekart/api/hisab/stock_entries/create_stock_entry`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Stock entry created successfully", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });

    // throw error;
  }
}

export async function customerDetailsApi(userId, businessId, customerId) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/customer/${customerId}/details`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function customerDetailsDateApi(
  userId,
  businessId,
  customerId,
  from,
  to
) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/customer/${customerId}/details?from=${from}&to=${to}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function vendorDetailsApi(userId, businessId, vendorId) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/vendor/${vendorId}/details`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function vendorDetailsDateApi(
  userId,
  businessId,
  vendorId,
  from,
  to
) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/vendor/${vendorId}/details?from=${from}&to=${to}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function addSaleData(userId, businessId, reqData) {
  const url = `/v1/user/${userId}/business/${businessId}/purchase`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Purchase created successfully.", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });
  }
}

export async function fetchInvoice(userId, businessId) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/invoices`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error("Unable to fetch invoice data. Please try again.", {
      position: "top-center",
    });
  }
}

export async function getAllBusinesses(userId) {
  try {
    const response = await fetch(`/v2/user/${userId}/me`);

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error("Unable to fetch businesses data. Please try again.", {
      position: "top-center",
    });
  }
}

export async function downloadInvoiceData(userId, businessId, reqBody) {
  const url = `/v1/user/${userId}/business/${businessId}/invoice`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Generated invoice successfully", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Please complete all mandatory fields.", {
      position: "top-center",
    });
  }
}

export async function createSaleData(userId, businessId, reqBody) {
  const url = `/v1/user/${userId}/business/${businessId}/sale`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("Sale created successfully.", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function merchantProductData(merchantId) {
  try {
    const response = await fetch(
      `/order/merchant/${merchantId}/product?is_approved=approved&isactive=true`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error("Unable to fetch businesses data. Please try again.", {
      position: "top-center",
    });
  }
}

export async function getDataWithId(businessId) {
  try {
    const response = await fetch(
      `/ayekart/api/hisab/product/get_product_byBusinessId?business_id=${businessId}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error("Unable to fetch data. Please try again.", {
      position: "top-center",
    });
  }
}
