import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ScfRequestCard from "./ScfRequestCard";
import scf from ".././../../images/scf.svg";
import {
  scfApprovedData,
  scfCancelledData,
  scfPendingData,
  scfRejectedData,
} from "../../../services/ScfFormApi";
import { useDataContext } from "../../../contexts/DataContext";
import Pagination from "../pagination/Pagination";

const ScfRequest = () => {
  const [requests, setRequests] = useState("Pending");
  const [scfData, setScfData] = useState([]);
  const [handleScfSearchData, setHandleScfSearchData] = useState([]);
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [search, setSearch] = useState("");

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    fetchingData();
  }, [requests]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    setRecords(scfData.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(scfData.length / recordsPerPage));
    maxmLimitPage();
  }, [scfData, firstIndex, lastIndex]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, scfData.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(scfData.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const fetchingData = async () => {
    if (requests === "Pending") {
      getScfPendingData();
    } else if (requests === "Approved") {
      getScfApprovedData();
    } else if (requests === "Rejected") {
      getScfRejectedData();
    } else if (requests === "Cancelled") {
      getScfCancelledData();
    }
  };

  const getScfPendingData = async () => {
    const data = await scfPendingData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setHandleScfSearchData(data.data);
    setScfData(data.data);
  };

  const getScfApprovedData = async () => {
    const data = await scfApprovedData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setHandleScfSearchData(data.data);
    setScfData(data.data);
  };

  const getScfRejectedData = async () => {
    const data = await scfRejectedData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setHandleScfSearchData(data.data);
    setScfData(data.data);
  };

  const getScfCancelledData = async () => {
    const data = await scfCancelledData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setHandleScfSearchData(data.data);
    setScfData(data.data);
  };

  const handleSearch = () => {
    // Filter the data based on the user's search.
    const filteredData =
      handleScfSearchData.length > 0
        ? handleScfSearchData.filter((item) => item.ID === parseInt(search))
        : [];

    if (filteredData.length > 0) setScfData(filteredData);
    else setScfData(handleScfSearchData);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 mb-3 xl:mb-5 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-[1.2rem] md:text-2xl xl:text-3xl">
              SCF Requests
            </h1>

            <p className="text-[#000000] text-[0.4rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline text-black">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/inventory" className="no-underline text-black">
                SCF Requests
              </NavLink>
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[6rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search by ID..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="w-[97%] md:w-[98%] ">
          <div className="flex justify-between items-center">
            <select
              className="appearance-auto bg-[#384B9D] text-[#FBFBFB] outline-0 text-center w-[5rem] md:w-[6rem] lg:w-[7rem] font-semibold py-1  px-1 text-[0.6rem] md:text-xs lg:text-[1rem] hover:border-transparent rounded"
              value={requests}
              onChange={(e) => setRequests(e.target.value)}
            >
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
              <option value="Cancelled">Cancelled</option>
            </select>

            <NavLink to="/scf-form">
              <img
                src={scf}
                alt=""
                className="w-[1rem] md:w-[1.3rem] xl:w-[1.7rem] h-[1rem] md:h-[1.3rem] xl:h-[1.7rem]"
              />
            </NavLink>
          </div>

          <div className="w-[99%] md:w-[98%] xl:w-[100%] grid gap-6 mb-6 md:grid-cols-2 xl:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-6">
            {records.map((item, index) => (
              <ScfRequestCard cardData={item} key={index} />
            ))}
          </div>
        </div>
      </div>

      {/* pagination */}
      <Pagination
        prevPage={prevPage}
        changePage={changePage}
        nextPage={nextPage}
        numbers={numbers}
        currentPage={currentPage}
      />
    </>
  );
};

export default ScfRequest;
