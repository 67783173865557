import React, { useEffect, useState } from "react";
import cardImg from "../../../../images/Card-img.png";
import { NavLink } from "react-router-dom";
import { useDataContext } from "../../../../contexts/DataContext";

const RemindPage = () => {
  const [isChecked, setIsChecked] = useState(true);
  const [amount, setAmount] = useState("");
  const [reqMoneyData, setReqMoneyData] = useState({});
  const { selectedPerson, userDetailsId, selectedBusiness } = useDataContext();

  useEffect(() => {
    // requestMoneyCallBack()
    if (reqMoneyData && reqMoneyData.notes) requestMoneyCallBack();
  }, [reqMoneyData]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const requestMoney = async () => {
    const username = "rzp_live_hswzMSHuwLCj6X";
    const password = "1KdCPrr1MA10RvnFutNHc2DG";
    const credentials = `${username}:${password}`;
    const authorizationHeader = `Basic ${btoa(credentials)}`;
    const timestamp = new Date().getTime();

    const requestData = {
      accept_partial: false,
      amount: parseInt(amount) * 100,
      callback_method: "get",
      callback_url: "/v1/user/razorpay/call-back",
      currency: "INR",
      customer: {
        contact: `+91${selectedPerson.mobile}`,
        name: `${selectedPerson.first_name} ${selectedPerson.last_name}`,
      },
      description: "",
      notes: {
        amount: parseInt(amount),
        business_id: selectedPerson.business_id,
        customer_id: selectedPerson.ID,
        customer_name: "KALPESH SUDAM MORYE ",
        payment_link: "",
        plink_id: "",
        referrence_id: `aye2${timestamp.toString()}`,
        user_id: parseInt(userDetailsId.data.ID.toString()),
      },
      notify: {
        email: false,
        sms: false,
      },
      reference_id: `aye2${timestamp.toString()}`,
      reminder_enable: true,
      upi_link: "true",
    };

    try {
      const response = await fetch("/razor/payment_links", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationHeader,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setReqMoneyData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const requestMoneyCallBack = async () => {
    const requestData = {
      business_id: parseInt(selectedPerson.business_id),
      customer_id: parseInt(selectedPerson.ID),
      user_id: parseInt(reqMoneyData.notes.user_id),
      customer_name: reqMoneyData.customer.name,
      amount: parseInt(reqMoneyData.notes.amount),
      referrence_id: reqMoneyData.notes.referrence_id,
      payment_link: reqMoneyData.short_url,
      plink_id: reqMoneyData.id,
    };

    try {
      const response = await fetch("/v1/user/razorpay/call-back", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      alert(`Dear Sir/Madam,
        Your payment of ₹ ${amount} is pending at ${
        selectedBusiness.business_name
          ? selectedBusiness.business_name
          : "PGARTS"
      }${userDetailsId.data.mobile}
        Click here: ${reqMoneyData.short_url} to make the payment.`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-sm md:text-2xl xl:text-3xl">
              Remind
            </h1>
          </div>
        </div>

        <div className="amount-container flex justify-between items-center mt-2 lg:mt-5 py-2 px-1 h-[4rem] md:h-[5rem] lg:h-[7rem] w-[97%] rounded lg:rounded-md">
          <div className="amount-box">
            <img
              src={cardImg}
              alt="icon"
              className="w-[1.7rem] h-[1.7rem] md:w-[2rem] md:h-[2rem] lg:w-[3rem] lg:h-[3rem] rounded-full"
            />
            <div>
              <p className="text-[#fbfbfb] font-semibold text-xs md:text-base lg:text-lg mx-2">
                {selectedPerson.first_name} {selectedPerson.last_name}
              </p>
              <p className="text-[#fbfbfb] font-semibold text-[0.6rem] md:text-xs lg:text-base mx-2">{selectedPerson.mobile}</p>
            </div>
          </div>

          <div className="mr-2 md:mr-4 lg:mr-8">
            <p className="text-[#fbfbfb] font-semibold text-xs md:text-base lg:text-lg text-center">
              Pending
            </p>
            <p className="text-[#fbfbfb] font-semibold text-xs md:text-base lg:text-lg text-center ">
              &#x20B9;
              {selectedPerson.to_collect - selectedPerson.collected > 0
                ? (
                    selectedPerson.to_collect - selectedPerson.collected
                  ).toFixed(2)
                : 0}
            </p>
          </div>
        </div>

        <div className="mt-4 w-[97%]">
          <label
            for="GST in %"
            className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
          >
            Amount
          </label>
          <input
            type="text"
            id="GST in %"
            className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>

        <div className="flex justify-end items-center mt-4 w-[97%]">
          <button
            type="button"
            className="text-white bg-[#384B9D] hover:bg-[#455ab7] focus:ring-4 focus:ring-blue-300 font-medium rounded-sm md:rounded-md text-[0.6rem] md:text-[0.7rem] lg:text-[0.9rem] px-2 lg:px-5 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={requestMoney}
          >
            <NavLink to="/requestAmount" className="no-underline">
              Request
            </NavLink>
          </button>
        </div>
      </div>
    </>
  );
};

export default RemindPage;
