export async function weeklyChartApi(businessId, month, year) {
  try {
    const response = await fetch(
      `/ayekart/api/hisab/charts/weekly?business_id=${businessId}&weekly=weekly&month_numeric=${month}&year=${year}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function monthlyChartApi(businessId, year) {
  try {
    const response = await fetch(
      `/ayekart/api/hisab/charts/monthly?business_id=${businessId}&monthly=monthly&year=${year}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function yearlyChartApi(businessId) {
  try {
    const response = await fetch(
      `/ayekart/api/hisab/charts/yearly?business_id=${businessId}&yearly=yearly`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function financeChartApi(userId, businessId, from, to) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/finance/graph?from=${from}&to=${to}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function stockChartApi(userId, businessId, from, to) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/stock/dashboard?from=${from}&to=${to}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function stockReportsApi(businessId, from, to) {
  try {
    const response = await fetch(
      `/ayekart/api/hisab/stock_entries/stock_entry_log/getall?business_id=${businessId}&from=${from}&to=${to}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function customerReportsApi(userId, businessId, from, to) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/customer-hisab-reports?from=${from}&to=${to}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function staffReportsApi(userId, businessId, from, to) {
  try {
    const response = await fetch(
      `/v1/user/${userId}/business/${businessId}/staff/attendance/all?from=${from}&to=${to}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}
