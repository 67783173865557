import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function dispatchOtp(userPhnNo, otp) {
  const url = `/json?key=4fym973z&secret=384QdxT6&from=AYEKRT&to=${userPhnNo}&text=${otp}%20is%20your%20OTP%20for%20Ayekart%20Consumer.%20Please%20do%20not%20share%20it%20with%20anyone.%20Thank%20you!%20Ayekart%20Fintech`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    // throw error;
  }
}

export async function getMerchantDetails(mobileNo) {
  const url = `/ayekart/api/merchant/me?mobile_number=${mobileNo}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
  }
}

export async function generateOtp(reqBody) {
  const url = "/ayekart/api/b2b/create_otp/";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    toast.success("OTP Sent to Your Mobile Number", {
      position: "top-center",
    });

    return data;
  } catch (error) {
    toast.error("Failed to Send OTP. Please Try Again.", {
      position: "top-center",
    });
  }
}
