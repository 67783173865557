
export async function scfPendingData(userId, businessId) {
    try {
      const response = await fetch(
        `/v1/user/${userId}/business/${businessId}/scf?status=pending`
      );
  
      // Check if the response is successful
      if (!response.ok) {   
        throw new Error("Network response was not ok");
      }
  
      // Parse the JSON response
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  export async function scfApprovedData(userId, businessId) {
    try {
      const response = await fetch(
        `/v1/user/${userId}/business/${businessId}/scf?status=approved`
      );
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      // Parse the JSON response
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  export async function scfRejectedData(userId, businessId) {
    try {
      const response = await fetch(
        `/v1/user/${userId}/business/${businessId}/scf?status=rejected`
      );
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      // Parse the JSON response
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  export async function scfCancelledData(userId, businessId) {
    try {
      const response = await fetch(
        `/v1/user/${userId}/business/${businessId}/scf?status=cancelled`
      );
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      // Parse the JSON response
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  }