import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { deleteStaffData, updateSalary } from "../../../services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getCurrentMonthFirstDay = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    .toISOString()
    .split("T")[0];
};

const getCurrentMonthLastDay = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  return lastDay.toISOString().split("T")[0];
};

const StaffProfile = () => {
  const [getSelectedUser, setSelectedUser] = useState({});
  const [getDate, setGetDate] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const { staffDetails, selectedBusiness, userDetailsId } = useDataContext();
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    const filterData = staffDetails.filter((item) => item.ID === parseInt(id));
    setSelectedUser(filterData[0]);
  }, []);

  const deleteData = async () => {
    const deletedData = await deleteStaffData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      id
    );

    toast.success("Delete successfully", {
      position: "top-center",
    });

    navigate(-1);
  };
  
  useEffect(() => {
    setMonthlySalary(getSelectedUser.staff_salary);
  }, [getSelectedUser]);

  const update = async () => {
    const reqData = {
      business_id: getSelectedUser.business_id,
      day_of_salary: parseInt(getDate.split("-")[2]),
      full_name: getSelectedUser.full_name,
      mobile_number: getSelectedUser.mobile_number,
      opening_balance_advance: 0,
      opening_balance_pending: 0,
      profile_image_URL: getSelectedUser.profile_image_URL,
      salary_cycle: getSelectedUser.salary_cycle,
      staff_salary: parseInt(monthlySalary),
      type_of_salary_payment: getSelectedUser.type_of_salary_payment,
    };

    const data = await updateSalary(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      id,
      reqData
    );

    toast.success("Update data successfully", {
      position: "top-center",
    });

    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 ">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-[1rem] md:text-2xl xl:text-3xl">
              Staff Profile
            </h1>

            <p className="text-[#000000] text-[0.5rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/staff" className="no-underline">
                Staff
              </NavLink>
            </p>
          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-3">
          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Staff Name
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={getSelectedUser.full_name}
              readOnly
            />
          </div>

          <div>
            <label
              for="Mobile Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Mobile Number
            </label>
            <input
              type="text"
              id="Mobile Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={getSelectedUser.mobile_number}
              readOnly
            />
          </div>

          <div>
            <label
              for="Monthly Salary of Staff"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Monthly Salary of Staff
            </label>
            <input
              type="text"
              id="Monthly Salary of Staff"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={monthlySalary}
              onChange={(e) => setMonthlySalary(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Salary Cycle"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Salary Cycle
            </label>
            <input
              type="date"
              id="Salary Cycle"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              min={getCurrentMonthFirstDay()}
              max={getCurrentMonthLastDay()}
              value={getDate}
              onChange={(e) => setGetDate(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800  focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={update}
          >
            Update
          </button>

          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={deleteData}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default StaffProfile;
