export function dateConvert(item) {
  // Create a new Date object from the input string
  let date = new Date(item);

  // Define months array for converting month number to month name
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract date components
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();

  // Format the date
  return `${day} ${months[monthIndex]} ${year}`;
}

export function dateTimeConvert(item) {
  const timestamp = item;
  const date = new Date(timestamp);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = String(date.getFullYear()).slice(2);
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12; // Handle midnight
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = `${day} ${month} ${year} - ${hours}:${minutes} ${ampm}`;

  return formattedDate;
}

export const formatDateAndTime = (dateString, timeString) => {
  // Split the date string into year, month, and day parts
  const [year, month, day] = dateString.split("-");

  // Split the time string into hour and minute parts
  const [hour, minute] = timeString.split(":");

  // Create a new Date object with the provided year, month (0-indexed), day, hour, and minute
  const formattedDate = new Date(year, month - 1, day, hour, minute);

  // Convert the Date object to an ISO string
  const isoString = formattedDate.toISOString();

  return isoString;
};

export const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getCurrentTimestamp = () => {
  // Get current date
  const currentDate = new Date();

  // Format date
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
  const day = String(currentDate.getDate()).padStart(2, "0"); // Add leading zero if needed

  // Format time
  const hours = String(currentDate.getHours()).padStart(2, "0"); // Add leading zero if needed
  const minutes = String(currentDate.getMinutes()).padStart(2, "0"); // Add leading zero if needed
  const seconds = String(currentDate.getSeconds()).padStart(2, "0"); // Add leading zero if needed
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0"); // Add leading zeros if needed

  // Combine date and time
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

  return formattedDate;
};

export const dateToMillisecond = (data) => {
  const timestamp = "2024-03-18T13:30:07.12Z";
  const dateObject = new Date(timestamp);
  const milliseconds = dateObject.getTime();
  return milliseconds;
};

export function getFirstAndLastDayOfMonth(dateString) {
  // Split the date string into year and month
  const [year, month] = dateString.split("-");

  // Create a new Date object for the given year and month (months are 0-based)
  const firstDayOfMonth = new Date(year, month - 1, 1); // Set to the 1st day of the month
  const lastDayOfMonth = new Date(year, month, 0); // Set to the last day of the month

  // Format the dates as yyyy-mm-dd
  const formattedFirstDay = formatDate(firstDayOfMonth);
  const formattedLastDay = formatDate(lastDayOfMonth);

  return {
    firstDay: formattedFirstDay,
    lastDay: formattedLastDay,
  };
}

function formatDate(date) {
  // Get the day, month, and year from the date object
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Return the formatted date as yyyy-mm-dd
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
}
