import React, { useEffect, useState } from "react";
import { getProduct, createStockEntry } from "../../../services/Api.js";
import { useDataContext } from "../../../contexts/DataContext.js";
import { NavLink, useNavigate } from "react-router-dom";

const AddStock = () => {
  const [productName, setProductName] = useState("");
  const [purchasedQty, setPurchasedQty] = useState("");
  const [purchasedAmount, setPurchasedAmount] = useState("");
  const [stockProduct, setStockProduct] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { selectedBusiness } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (purchasedQty)
      setPurchasedAmount(
        (
          parseInt(purchasedQty) * parseInt(selectedProductData.buying_price)
        ).toFixed(2)
      );
    else setPurchasedAmount("");
  }, [purchasedQty]);

  const getData = async () => {
    try {
      const productData = await getProduct(
        selectedBusiness.id ? selectedBusiness.id : 2
      );
      setStockProduct(productData.Data);
    } catch (error) {
      console.log(error);
    }
  };

  const selectedProduct = (id) => {
    const selectedData = filteredProducts.filter((item) => item.id === id);
    setSelectedProductData(selectedData[0]);
    setProductName(selectedData[0].product_name);
  };

  const handleSearch = (searchTerm) => {
    const filtered = stockProduct.filter(
      (product) =>
        product.product_name &&
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredProducts(filtered);
  };

  const handleChange = (e) => {
    setProductName(e.target.value);
    handleSearch(e.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 1000);
  };

  const addStock = async () => {
    const stockEntryData = {
      business_id: (selectedBusiness.id ? selectedBusiness.id : 2).toString(),
      damaged_value: "0.0",
      entry_type: "purchased",
      image_url: [],
      item_available_units: "0",
      item_damaged: "0",
      item_name: selectedProductData.product_name,
      item_purchased: purchasedQty,
      item_returned: "0",
      item_sold: "0",
      product_id: selectedProductData.id.toString(),
      purchased_value: purchasedAmount,
      returned_value: "0.0",
      sold_value: "0.0",
      stock_value: parseInt(purchasedAmount).toString(),
    };

    try {
      const createdEntry = await createStockEntry(stockEntryData);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
              Add Stock
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/inventory" className="no-underline">
                Inventory
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/" className="no-underline">
                Stock
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/addStock" className="no-underline">
                Add Stock
              </NavLink>
            </p>
          </div>

          <div className="relative mx-2 xl:mx-5 sm:mx-3 mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div>
            <p className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white">
              Select Product*
            </p>
            <div>
              <input
                type="text"
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Product"
                value={productName}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>

            {isFocused && (
              <div className="absolute z-10 bg-[#CBCCCB] h-[5rem] w-[72%] md:w-[35%] lg:w-[36%] rounded-md p-2 overflow-auto">
                {filteredProducts.map((product, index) => (
                  <div key={index}>
                    <p
                      key={index}
                      className="font-medium text-[0.7rem] leading-6 cursor-pointer hover:text-gray-600"
                      onClick={() => selectedProduct(product.id)}
                    >
                      {product.product_name}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <label
              for="Purchased Quantity"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Purchased Quantity*
            </label>
            <input
              type="text"
              id="Purchased Quantity"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Quantity"
              value={purchasedQty}
              onChange={(e) => setPurchasedQty(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Purchased Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Purchased Amount*
            </label>
            <input
              type="text"
              id="Purchased Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Amount"
              value={purchasedAmount}
              onChange={(e) => setPurchasedAmount(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={addStock}
          >
            Add Stock
          </button>
        </div>
      </div>
    </>
  );
};

export default AddStock;
