import React from "react";
import { NavLink } from "react-router-dom";

const Pagination = ({
  prevPage,
  changePage,
  nextPage,
  numbers,
  currentPage,
}) => {
  return (
    <div className="mx-auto px-4 mb-4 mt-[3rem]">
      <nav
        className="flex flex-row flex-nowrap justify-center items-center "
        aria-label="Pagination"
      >
        <NavLink
          className="flex w-10 h-10 mr-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
          title="Previous Page"
        >
          <span className="sr-only">Previous Page</span>
          <svg
            className="block w-4 h-4 fill-current"
            viewBox="0 0 256 512"
            aria-hidden="true"
            role="presentation"
            onClick={prevPage}
          >
            <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
          </svg>
        </NavLink>

        {numbers.map((n, i) => (
          <NavLink
            key={i}
            className={`${
              n === currentPage ? "bg-[#0E3293] text-white" : "bg-white"
            } flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 text-black hover:border-gray-300`}
            title={`Page ${n}`}
            onClick={() => changePage(n)}
          >
            {n}
          </NavLink>
        ))}

        <NavLink
          className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
          title="Next Page"
        >
          <span className="sr-only">Next Page</span>
          <svg
            className="block w-4 h-4 fill-current"
            viewBox="0 0 256 512"
            aria-hidden="true"
            role="presentation"
            onClick={nextPage}
          >
            <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
          </svg>
        </NavLink>
      </nav>
    </div>
  );
};

export default Pagination;
