import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveCustomer } from "../../../services/Api";

const AddNewCustomer = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const { selectedBusiness, userDetailsId } = useDataContext();
  const navigate = useNavigate();

  const addCustomer = async () => {
    const reqData = {
      business_id: selectedBusiness.id ? selectedBusiness.id : 2,
      first_name: firstName,
      last_name: lastName,
      mobile: mobileNo,
      profile_pic: "",
    };

    const data = await saveCustomer(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      reqData
    );

    navigate("/");
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-[0.9rem] md:text-2xl xl:text-3xl">
              Add New Customer
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp; Add Customer
            </p>
          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div>
            <label
              for="First Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              First Name*
            </label>
            <input
              type="text"
              id="First Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={firstName}
              placeholder="Enter First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Last Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Last Name*
            </label>
            <input
              type="text"
              id="Last Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={lastName}
              placeholder="Enter Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Mobile Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Mobile Number
            </label>
            <input
              type="number"
              id="Mobile Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={mobileNo}
              placeholder="Enter Mobile Number"
              onChange={(e) => setMobileNo(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={addCustomer}
          >
            Add Customer
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewCustomer;
