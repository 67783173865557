import React, { useEffect, useState } from "react";
import ayekart from "../../../images/ayekart-default.png";
import {
  fetchStaffData,
  updateAttendance,
  saveAttendance,
  fetchStaffDataWithDate,
} from "../../../services/Api";
import { useDataContext } from "../../../contexts/DataContext";
import { NavLink } from "react-router-dom";
import {
  dateToMillisecond,
  dateToMillisecondSpecificDate,
  getCurrentTimestamp,
} from "../../../utilities/convertDate";
import Pagination from "../pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Staff = () => {
  const [present, setPresent] = useState(false);
  const [halfDay, setHalfDay] = useState(false);
  const [absent, setAbsent] = useState(false);
  const [staffAttendance, setStaffAttendance] = useState(false);
  const [getId, setGetId] = useState("");
  const [getAttendanceId, setGetAttendanceId] = useState("");
  const { selectedBusiness, userDetailsId, staffDetails, setStaffDetails } =
    useDataContext();
  const [getDate, setGetDate] = useState(new Date().toISOString().slice(0, 10));
  const [attendance, setAttendance] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [search, setSearch] = useState("");
  const [handleStaffSearch, setHandleStaffSearch] = useState([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    getStaffData();
  }, [attendance, getDate]);

  useEffect(() => {
    postData();
  }, [isCheck]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    if (saveData.data) {
      getStaffData();
    }
  }, [saveData]);

  useEffect(() => {
    setRecords(staffDetails.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(staffDetails.length / recordsPerPage));
    maxmLimitPage();
  }, [staffDetails, firstIndex, lastIndex]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, staffDetails.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(staffDetails.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const postData = async () => {
    if (getId) {
      const postData = staffAttendance
        ? await saveAttendance(
            userDetailsId.data.ID,
            selectedBusiness.id ? selectedBusiness.id : 2,
            getId,
            {
              absent: absent,
              created_at: getDate
                ? `${getDate}T00:00:00.000Z`
                : getCurrentTimestamp(),
              halfday: halfDay,
              overtime: false,
              present: present,
              staff_id: parseInt(getId),
            }
          )
        : await updateAttendance(
            userDetailsId.data.ID,
            selectedBusiness.id ? selectedBusiness.id : 2,
            getId,
            getAttendanceId,
            {
              ID: parseInt(getId),
              absent: absent,
              halfday: halfDay,
              overtime: false,
              present: present,
              staff_id: parseInt(getAttendanceId), //attendance id
            }
          );
      setSaveData(postData);
    }
  };

  const getStaffData = async (id) => {
    let data;
    if (getDate) {
      data = await fetchStaffDataWithDate(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        getDate
      );
    } else {
      data = await fetchStaffData(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2
      );
    }

    if (attendance === "" || attendance === "All") {
      setHandleStaffSearch(data.data);
      setStaffDetails(data.data);
    } else {
      const updatedData = data.data.filter((item) => {
        if (item.staff_attendence.length > 0) {
          if (attendance === "Present")
            return item.staff_attendence[0].present === true;
          else if (attendance === "Absent")
            return item.staff_attendence[0].absent === true;
          else if (attendance === "Half Day")
            return item.staff_attendence[0].half_Day === true;
        }
        return false;
      });

      setHandleStaffSearch(updatedData);
      setStaffDetails(updatedData);
    }
  };

  const handlePresent = (id, checkAttendance) => {
    setStaffAttendance(checkAttendance.length > 0 ? false : true);
    if (checkAttendance.length > 0) setGetAttendanceId(checkAttendance[0].ID);
    setGetId(id);
    setIsCheck(!isCheck);
    setPresent(true);
    setAbsent(false);
    setHalfDay(false);
    getStaffData(id);

    toast.success(`Attendance recorded as Present.`, {
      position: "top-center",
    });
  };

  const handleAbsent = (id, checkAttendance) => {
    setStaffAttendance(checkAttendance.length > 0 ? false : true);
    if (checkAttendance.length > 0) setGetAttendanceId(checkAttendance[0].ID);
    setGetId(id);
    setAbsent(true);
    setPresent(false);
    setHalfDay(false);
    getStaffData(id);
    setIsCheck(!isCheck);

    toast.success(`Attendance recorded as Absent.`, {
      position: "top-center",
    });
  };

  const handleHalfDay = (id, checkAttendance) => {
    setStaffAttendance(checkAttendance.length > 0 ? false : true);
    if (checkAttendance.length > 0) setGetAttendanceId(checkAttendance[0].ID);
    setGetId(id);
    setHalfDay(true);
    setPresent(false);
    setAbsent(false);
    getStaffData(id);
    setIsCheck(!isCheck);

    toast.success(`Attendance recorded as Half-day.`, {
      position: "top-center",
    });
  };

  const handleSearch = () => {
    // Filter the data based on the user's search.
    const filteredData =
      handleStaffSearch.length > 0
        ? handleStaffSearch.filter((item) =>
            item.full_name.toLowerCase().includes(search.toLowerCase())
          )
        : [];
    setStaffDetails(filteredData);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-6 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-lg md:text-2xl xl:text-3xl">
              Staff
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/staff" className="no-underline">
                Staff
              </NavLink>
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between w-[97%] md:w-[98%] mt-5 items-center">
          <div className="flex items-center">
            <div>
              <select
                className="appearance-auto bg-[#384B9D] text-[#FBFBFB] outline-0 text-center w-[4rem] md:w-[6rem] xl:w-fit font-semibold py-1 xl:py-[0.27rem] px-1 text-[0.6rem] md:text-xs xl:text-[1rem] hover:border-transparent rounded"
                value={attendance}
                onChange={(e) => setAttendance(e.target.value)}
              >
                <option value="" className="font-semibold">
                  Attendance
                </option>
                <option value="All">All</option>
                <option value="Present">Present</option>
                <option value="Absent">Absent</option>
                <option value="Half Day">Half Day</option>
              </select>
            </div>

            <div>
              <input
                type="date"
                className="text-center ml-1 sm:ml-3 w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs xl:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] xl:py-[0.37rem]"
                value={getDate}
                onChange={(e) => setGetDate(e.target.value)}
              />
            </div>
          </div>

          <NavLink to="/addStaff" className="no-underline">
            <button className="bg-[#384B9D] text-[#FBFBFB] w-[4rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.6rem] md:text-xs xl:text-[1rem] hover:border-transparent rounded">
              Add Staff
            </button>
          </NavLink>
        </div>

        {/* cards */}
        <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-6">
          {records &&
            records.map((item, index) => (
              <div
                key={index}
                className="shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] w-full overflow-auto h-[8.5rem] xl:h-[9rem] p-2 py-4"
              >
                <NavLink
                  to={`/staffProfile/${item.ID}`}
                  className="no-underline"
                >
                  <div className="flex ">
                    <img
                      src={
                        item.profile_image_URL
                          ? item.profile_image_URL
                          : ayekart
                      }
                      className="w-7 h-7 xl:w-10 xl:h-10 mr-2 rounded-full md:w-9 md:h-9 "
                      alt="icon"
                    />
                    <div>
                      <p className="text-[#3850BD] text-[1rem] xl:text-xl mb-2 font-medium">
                        {item.full_name}
                      </p>
                      <p className="text-xs xl:text-sm text-[#000000]">
                        {item.mobile_number}
                      </p>
                    </div>
                  </div>
                </NavLink>

                <div className="mt-5 mb-1 flex justify-between items-center px-2 space-x-2">
                  <button
                    className={`${
                      item.staff_attendence.length > 0 &&
                      item.staff_attendence[0].present
                        ? "bg-[#384B9D] text-[#FBFBFB]"
                        : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                    }
                    w-full  font-semibold py-1 px-1  text-xs hover:border-transparent rounded`}
                    onClick={() =>
                      handlePresent(item.ID, item.staff_attendence)
                    }
                  >
                    Present
                  </button>

                  <button
                    className={`${
                      item.staff_attendence.length > 0 &&
                      item.staff_attendence[0].absent
                        ? "bg-[#384B9D] text-[#FBFBFB]"
                        : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                    }
                        w-full  font-semibold py-1 px-1  text-xs hover:border-transparent rounded`}
                    onClick={() => handleAbsent(item.ID, item.staff_attendence)}
                  >
                    Absent
                  </button>

                  <button
                    className={`${
                      item.staff_attendence.length > 0 &&
                      item.staff_attendence[0].half_Day
                        ? "bg-[#384B9D] text-[#FBFBFB]"
                        : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                    }
                        w-full font-semibold py-1 px-1 hover:border-transparent text-xs rounded`}
                    onClick={() =>
                      handleHalfDay(item.ID, item.staff_attendence)
                    }
                  >
                    Half Day
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* pagination */}
      <Pagination
        prevPage={prevPage}
        changePage={changePage}
        nextPage={nextPage}
        numbers={numbers}
        currentPage={currentPage}
      />
    </>
  );
};

export default Staff;
