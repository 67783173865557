import React from "react";

const ViewProductsCard = ({ cardData }) => {
  return (
    <div className="flex flex-col shadow-lg rounded-sm mb-2 border border-gray-300 h-[auto] p-2 lg:p-4">
      <div className="flex justify-between items-center">
        <p className="text-[#3850BD] font-semibold text-base lg:text-xl">
          {cardData.Product.product_name}
        </p>
        {/* <p className="order-txt2">{dateTimeConvert(cardData.CreatedAt)}</p> */}
      </div>

      <div className="mt-1">
        <p className="text-[#384B9D] font-semibold text-[0.6rem] lg:text-[0.8rem]">
          <span className="text-[#3A3A3A]">Quantity :</span> &nbsp;
          {cardData.quantity}
        </p>
        <p className="text-[#384B9D] font-semibold text-[0.6rem] lg:text-[0.8rem]">
          <span className="text-[#3A3A3A]">Unit Of Measurement :</span> &nbsp;
          {cardData.unit_of_measurement}
        </p>
        <p className="text-[#384B9D] font-semibold text-[0.6rem] lg:text-[0.8rem]">
          <span className="text-[#3A3A3A]">Amount :</span> &nbsp;
          {cardData.amount}
        </p>
        <p className="text-[#384B9D] font-semibold text-[0.6rem] lg:text-[0.8rem]">
          <span className="text-[#3A3A3A]">Offered Price :</span> &nbsp;
          {cardData.Product.selling_price} INR
        </p>
      </div>
    </div>
  );
};

export default ViewProductsCard;
