import React from "react";
import logo from "../../../images/logo-home.svg";
import homeIconWhite from "../../../images/home-icon-white.svg";
import financeWhite from "../../../images/Finance-white.svg";
import inventoryWhite from "../../../images/inventory-white.svg";
import shopWhite from "../../../images/shop-white.svg";
import staffWhite from "../../../images/staff-white.svg";
import logout from "../../../images/logout-white.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../../utilities/firebase";
import profileImg from "../../../images/profile-photo.png";
import { ToastContainer, toast } from "react-toastify";
import { useDataContext } from "../../../contexts/DataContext";

const HomeLeft = () => {
  const navigate = useNavigate();
  const { isShowCards, setIsShowCards } = useDataContext();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.clear();
        toast.success("Signed out successfully!", {
          position: "top-center",
        });

        setTimeout(() => {
          navigate("/login");
        }, 1000);
      })
      .catch((error) => {
        toast.error("Failed to sign out. Please try again later.", {
          position: "top-center",
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="flex items-center justify-center h-screen ">
        <div className="bg-[#384b9d] h-[95%] rounded-md w-[3rem] md:w-[4rem] lg:w-[5.5rem] fixed">
          <div className="flex items-center mx-[0.1rem] justify-center">
            <img src={logo} className="w-fit mt-5 lg:mt-8 h-fit" alt="Icon" />
          </div>
          <div className="flex justify-center items-center flex-col mt-[2.5rem] lg:mt-[4rem]">
            <div
              onClick={() => {
                setIsShowCards(true);
                navigate("/");
              }}
              className="mb-5 lg:mb-6 flex flex-col justify-center items-center cursor-pointer"
            >
              <img
                src={homeIconWhite}
                className="w-[1rem] md:w-[1.2rem] lg:w-[1.6rem] h-fit "
                alt="Icon"
              />

              <p className="text-[#ffff] text-[0.4rem] md:text-[0.6rem] lg:text-[0.8rem] my-[0.3rem] font-semibold">
                Home
              </p>
            </div>

            <div
              onClick={() => {
                setIsShowCards(false);
                navigate("/finance");
              }}
              className="mb-5 lg:mb-6 flex flex-col justify-center items-center cursor-pointer"
            >
              <img
                src={financeWhite}
                className="w-[1rem] md:w-[1.2rem] lg:w-[1.6rem] h-fit"
                alt="Icon"
              />
              <p className="text-[#ffff] text-[0.4rem] md:text-[0.6rem] lg:text-[0.8rem] my-[0.3rem] font-semibold">
                Finance
              </p>
            </div>

            <div
              onClick={() => {
                setIsShowCards(false);
                navigate("/inventory");
              }}
              className="mb-5 lg:mb-6 flex flex-col justify-center items-center cursor-pointer"
            >
              <img
                src={inventoryWhite}
                className="w-[1rem] md:w-[1.2rem] lg:w-[1.6rem] h-fit"
                alt="Icon"
              />
              <p className="text-[#ffff] text-[0.4rem] md:text-[0.6rem] lg:text-[0.8rem] my-[0.3rem] font-semibold">
                Inventory
              </p>
            </div>

            <div
              onClick={() => {
                setIsShowCards(true);
                navigate("/shop");
              }}
              className="mb-5 lg:mb-6 flex flex-col justify-center items-center cursor-pointer"
            >
              <img
                src={shopWhite}
                className="w-[1rem] md:w-[1.2rem] lg:w-[1.6rem] h-fit"
                alt="Icon"
              />
              <p className="text-[#ffff] text-[0.4rem] md:text-[0.6rem] lg:text-[0.8rem] my-[0.3rem] font-semibold">
                Shop
              </p>
            </div>

            <div
              onClick={() => {
                setIsShowCards(false);
                navigate("/staff");
              }}
              className="mb-5 lg:mb-6 flex flex-col justify-center items-center cursor-pointer"
            >
              <img
                src={staffWhite}
                className="w-[1rem] md:w-[1.2rem] lg:w-[1.6rem] h-fit"
                alt="Icon"
              />
              <p className="text-[#ffff] text-[0.4rem] md:text-[0.6rem] lg:text-[0.8rem] my-[0.3rem] font-semibold">
                Staff
              </p>
            </div>
            {/* <NavLink to="/staff"> */}
            <img
              src={logout}
              className="w-[1rem] md:w-[1.2rem] lg:w-[1.6rem] h-fit mb-5 lg:mb-6 cursor-pointer"
              alt="Icon"
              onClick={handleLogout}
              title="Logout"
            />
            {/* </NavLink> */}

            <NavLink
              to="/profile"
              className="absolute bottom-3 md:hidden cursor-pointer"
            >
              <img
                src={profileImg}
                className="w-[1.6rem] lg:w-[2rem] h-[1.6rem] lg:h-[2rem] mr-1 lg:mr-2"
                alt="icon"
                title="View Profile"
              />
            </NavLink>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default HomeLeft;
