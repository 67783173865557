import React, { useEffect, useState } from "react";
import "../../styles/login.css";

const LoginBoxPassword = (props) => {
  const [password, setPassword] = useState("");

  useEffect(() => {
    props.password(password);
  }, [password]);

  return (
    <>
      <input
        type="password"
        id="card-number-input"
        className="bg-[#3850BD] mt-3 border border-[#fbfbfb] font-semibold text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] rounded-md outline-0 block w-full p-[0.5rem] md:p-[0.8rem]"
        placeholder="Enter Password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        required
      />
      {/* <p
        className="text-[#ffffff] text-right mt-2 md:mt-3 text-xs md:text-[0.8rem] lg:text-[0.9rem] font-semibold cursor-pointer"
        onClick={props.changePassword}
      >
        Forgot Password?
      </p> */}
    </>
  );
};

export default LoginBoxPassword;
