import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStock, hisabEntryVendor } from "../../../../../services/Api";
import { useDataContext } from "../../../../../contexts/DataContext";
import {
  formatDateAndTime,
  getCurrentDate,
  getCurrentTime,
} from "../../../../../utilities/convertDate";

const ToPay = ({ customerId }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  // const [amount, , setAmount] = useState("");
  const [collect, setCollect] = useState("");
  const [itemDetails, setItemDetails] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [itemProducts, setItemProducts] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const { selectedBusiness, userDetailsId } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    setTime(getCurrentTime());
    setDate(getCurrentDate());
  }, []);

  const getData = async () => {
    try {
      const productData = await getStock(
        selectedBusiness.id ? selectedBusiness.id : 2
      );
      setItemProducts(productData.Data);
      setFilteredProducts(productData.Data)
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChangeRetailer = (event) => {
    const selectedFile = event.target.files[0];
    getImageUrlRetailer(selectedFile);
  };

  const getImageUrlRetailer = (e) => {
    const formValue = new FormData();
    formValue.append("file", e);
    const url = "/api/mitra-merchant/doc-upload";
    axios
      .post(url, formValue)
      .then((response) => {
        // setImageUrl(response.data.file_location);
        setImageUrl((prevUrls) => [...prevUrls, response.data.file_location]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectedProduct = (id) => {
    const selectedData = filteredProducts.filter((item) => item.id === id);
    setSelectedProductData(selectedData[0]);
    setItemDetails(selectedData[0].item_name);
  };

  const handleChange = (e) => {
    setItemDetails(e.target.value);
    handleSearch(e.target.value);
  };

  const handleSearch = (searchTerm) => {
    const filtered = itemProducts.filter(
      (product) =>
        product.item_name &&
        product.item_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredProducts(filtered);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 1000);
  };

  const saveData = async () => {
    const formattedDateTime = formatDateAndTime(date, time);

    const stockEntryData = {
      collected: 0.0,
      created_at: formattedDateTime,
      entry_type: "to_pay",
      image_url: imageUrl,
      item_name: itemDetails,
      paid: 0.0,
      quantity: parseFloat(quantity),
      to_collect: 0.0,
      to_pay: parseFloat(collect),
    };

    const createEntry = await hisabEntryVendor(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      customerId,
      stockEntryData
    );

    if(createEntry)
      navigate(-1);
  };

  const closePage = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4 w-[97%] md:w-[98%]">
          <div>
            <label
              for="Expiry Date (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Date*
            </label>
            <input
              type="date"
              id="Expiry Date (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="1 of every month"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Expiry Date (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Time*
            </label>
            <input
              type="time"
              id="Expiry Date (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>

          <div>
            <label
              for="UPC Code (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Amount*
            </label>
            <input
              type="text"
              id="UPC Code (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Amount"
              value={collect}
              onChange={(e) => setCollect(e.target.value)}
            />
          </div>

          <div>
            <p className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white">
              Product Name*
            </p>
            <div>
              <input
                type="text"
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Product Name"
                value={itemDetails}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>

            {isFocused && (
              <div className="absolute z-10 bg-[#CBCCCB] h-[5rem] w-[72%] md:w-[35%] lg:w-[36%] rounded-md p-2 overflow-auto">
                {filteredProducts.map((product, index) => (
                  <div key={index}>
                    <p
                      key={index}
                      className="font-medium text-[0.7rem] leading-6 cursor-pointer hover:text-gray-600"
                      onClick={() => selectedProduct(product.id)}
                    >
                      {product.item_name}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <label
              for="UPC Code (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Quantity*
            </label>
            <input
              type="text"
              id="UPC Code (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Product Image"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Upload Image
            </label>
            <input
              type="file"
              id="Product Image"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={handleFileChangeRetailer}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={closePage}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-4 lg:px-6 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={saveData}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ToPay;
