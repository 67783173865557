import React, { useState } from 'react';
import cardImg from "../../../images/Card-img.png";
import { useDataContext } from "../../../contexts/DataContext";
import { NavLink } from 'react-router-dom';

const Remind = () => {
  const [amount, setAmount] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const { selectedPerson, userDetailsId, selectedBusiness } = useDataContext();

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
    <div>
      <div className="customer-container">
        <div>
          <h1 className="home-middle-heading">Request Money</h1>
          <p className="home-middle-sub-heading">
            <NavLink to="/" style={{ textDecoration: "none", color: "black" }}>
              Home
            </NavLink>
            &nbsp; &gt; &nbsp;
            <NavLink
              to="/finance"
              style={{ textDecoration: "none", color: "black" }}
            >
              Finance
            </NavLink>
            &nbsp; &gt; &nbsp;
            <NavLink
              to="/requestMoney"
              style={{ textDecoration: "none", color: "black" }}
            >
              Request Money
            </NavLink>
          </p>
        </div>
      </div>

      <div className="amount-container">
        <div className="amount-box">
          <img src={cardImg} alt="icon" className="amount-img" />
          <p className="amount-txt1">
            {selectedPerson.first_name} {selectedPerson.last_name}
          </p>
        </div>
        <div className="amount-txt-container">
          <p className="amount-txt2">
            {(selectedPerson.to_collect - selectedPerson.collected).toFixed(
              2
            )}
          </p>
        </div>
      </div>

      <div style={{ marginTop: "1.4vw" }}>
        <p className="sale-label">Amount</p>
        <input
          type="text"
          className="amount-sale-text-box"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>

      <div className="amount-button-container">
        <div className="amount-button-box">
          <p className="amount-txt3">
            Share On {isChecked ? "Whatsapp" : "Sms"}
          </p>
          {/* <Switch defaultChecked onClick={handleToggle}/> */}
          <label className="amount-slider-container">
            <input
              type="checkbox"
              defaultChecked={true}
              onClick={handleToggle}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <button className="amount-button">
          REMIND
        </button>
      </div>
    </div>
  </>
  )
}

export default Remind