import React, { useEffect, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useDataContext } from "../../../contexts/DataContext";
import { dateConvert } from "../../../utilities/convertDate";
import { NavLink } from "react-router-dom";
import { getCustomerDetails } from "../../../services/Api";
import Pagination from "../pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RequestMoney = () => {
  const { setSelectedPerson, selectedBusiness, userDetailsId } =
    useDataContext();
  const [getCustomerData, setGetCustomerData] = useState([]);
  const [search, setSearch] = useState("");
  const [handleCustomerSearch, setHandleCustomerSearch] = useState([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 12;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    fetchCustomerDetails();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    setRecords(getCustomerData.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(getCustomerData.length / recordsPerPage));
    maxmLimitPage();
  }, [getCustomerData, firstIndex, lastIndex]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, getCustomerData.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(getCustomerData.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const fetchCustomerDetails = async () => {
    const data = await getCustomerDetails(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );
    setHandleCustomerSearch(
      data.data.customers
        .filter((item) => item.to_collect - item.collected > 0)
        .reverse()
    );
    setGetCustomerData(
      data.data.customers
        .filter((item) => item.to_collect - item.collected > 0)
        .reverse()
    );
  };

  const handleSearch = () => {
    const filteredData =
      handleCustomerSearch.length > 0
        ? handleCustomerSearch.filter((item) =>
            item.first_name.toLowerCase().includes(search.toLowerCase())
          )
        : [];
    setGetCustomerData(filteredData);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const select = (item) => {
    setSelectedPerson(item);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-medium text-lg md:text-2xl xl:text-3xl">
              Request Money
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/finance" className="no-underline">
                Finance
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/requestMoney" className="no-underline">
                Request Money
              </NavLink>
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 lg:grid-cols-3 sm:mr-3 mt-4 lg:mt-10">
            {records &&
              records.map((item, index) => {
                let formattedDate = dateConvert(item.CreatedAt);

                return (
                  <div
                    className="overflow-auto w-full h-[5.2rem] md:h-[5.5rem] lg:h-[6rem] shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] rounded-sm md:rounded-md p-2 lg:px-3"
                    key={index}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col">
                        <p className="text-[#3850bd] font-semibold text-sm md:text-base">
                          {item.first_name} {item.last_name}
                        </p>
                        <p className="text-[#000000] text-[0.5rem] md:text-[0.6rem] leading-4">
                          {formattedDate}
                        </p>
                      </div>

                      <LocalPhoneIcon
                        className="border me-3 border-[#384b9d] rounded-full text-[#384b9d] p-1 cursor-pointer mt-[-0.5rem]"
                        title={item.mobile}
                        onClick={() =>
                          toast.info(`Mobile No: ${item.mobile}`, {
                            position: "top-center",
                          })
                        }
                      />
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-[0.7rem] md:text-[0.8rem] lg:text-[0.9rem] leading-7 lg:leading-8 font-semibold">
                        &#x20B9; {item.to_collect - item.collected}
                      </p>

                      <NavLink to="/requestAmount" className="no-underline">
                        <button
                          type="button"
                          className="text-white bg-[#384B9D] hover:bg-[#455ab7] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[0.5rem] md:text-[0.6rem] lg:text-[0.7rem] px-2 lg:px-4 py-1 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          onClick={() => select(item)}
                        >
                          Request
                        </button>
                      </NavLink>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* pagination */}
        <Pagination
          prevPage={prevPage}
          changePage={changePage}
          nextPage={nextPage}
          numbers={numbers}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default RequestMoney;
