import React, { useEffect, useState } from "react";
import ayekart from "../../../../images/ayekart-default.png";
import { staffReportsApi } from "../../../../services/ChartApi";
import { useDataContext } from "../../../../contexts/DataContext";
import { getFirstAndLastDayOfMonth } from "../../../../utilities/convertDate";

const StaffReport = ({ defaultValueDate, getDate, search }) => {
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [staffData, setStaffData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    getReportData(defaultValueDate);
  }, [defaultValueDate]);

  useEffect(() => {
    getReportData(getDate);
  }, [getDate]);

  const getReportData = async (date) => {
    if (date) {
      const data = await staffReportsApi(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        getFirstAndLastDayOfMonth(date).firstDay,
        getFirstAndLastDayOfMonth(date).lastDay
      );

      setFilterData(data.data ? data.data : []);
      setStaffData(data.data ? data.data : []);
    }
  };

  const handleSearch = () => {
    //filter stock data
    const staffData =
      filterData.length > 0
        ? filterData.filter((item) =>
            item.full_name.toLowerCase().includes(search.toLowerCase())
          )
        : [];
    setStaffData(staffData);
  };

  return (
    <>
      <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 md:grid-cols-2 xl:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-6">
        {staffData &&
          staffData.map((item, index) => (
            <div
              key={index}
              className="shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] w-full overflow-auto h-[9rem] md:h-[9.5rem] lg:h-[10.5rem] p-2 py-4 rounded"
            >
              <div className="flex items-center">
                <img
                  src={
                    item.profile_image_URL ? item.profile_image_URL : ayekart
                  }
                  className="w-8 h-8 md:w-9 md:h-9 lg:w-[2.6rem] lg:h-[2.6rem] mr-2 rounded-full  "
                  alt="icon"
                />
                <div>
                  <p className="text-[#3850BD] text-[1.2rem] md:text-[1.3rem] lg:text-[1.4rem] font-semibold">
                    {item.full_name}
                  </p>
                </div>
              </div>

              <div className="mt-5 mb-1 flex justify-between items-center px-2 space-x-2">
                <button className="bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D] w-full  font-semibold py-2 px-2  text-xs min-[800px]:text-[0.8rem] lg:text-[0.9rem] hover:border-transparent rounded">
                  <p className="mb-[0.4rem] text-[#1E1E1E] ">Present</p>
                  <p>
                    {item.staff_attendence.reduce(
                      (accumulator, currentValue) =>
                        accumulator + (currentValue.present ? 1 : 0),
                      0
                    )}{" "}
                    Days
                  </p>
                </button>

                <button className="bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D] w-full  font-semibold py-2 px-2  text-xs min-[800px]:text-[0.8rem] lg:text-[0.9rem] hover:border-transparent rounded">
                  <p className="mb-[0.4rem] text-[#1E1E1E] ">Absent</p>
                  <p>
                    {item.staff_attendence.reduce(
                      (accumulator, currentValue) =>
                        accumulator + (currentValue.absent ? 1 : 0),
                      0
                    )}{" "}
                    Days
                  </p>
                </button>

                <button className="bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D] w-full  font-semibold py-2 px-2  text-xs min-[800px]:text-[0.8rem] lg:text-[0.9rem] hover:border-transparent rounded">
                  <p className="mb-[0.4rem] text-[#1E1E1E] ">Half Day</p>
                  <p>
                    {item.staff_attendence.reduce(
                      (accumulator, currentValue) =>
                        accumulator + (currentValue.half_Day ? 1 : 0),
                      0
                    )}{" "}
                    Days
                  </p>
                </button>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default StaffReport;
