import React, { useEffect, useState } from "react";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { useDataContext } from "../../../contexts/DataContext";
import { NavLink } from "react-router-dom";
import { ToWords } from "to-words";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchOrderDetails,
  fetchProduct,
  fetchSignAndLogo,
} from "../../../services/FinanceApi";
import { downloadInvoiceData } from "../../../services/Api";

const CreateInvoice = () => {
  const { selectedBusiness, userDetailsId, setEditProductId, merchantData } =
    useDataContext();
  //   const [orderDetailsById, setOrderDetailsById] = useState({});
  const [businessName, setBusinessName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [lorryNo, setLorryNo] = useState("");
  const [kataParchi, setKataParchi] = useState("");
  const [newProductData, setNewProductData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [amountWord, setAmountWord] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [isPaymentStatus, setIsPaymentStatus] = useState(true);
  const [imageUrlRetailer, setImageUrlRetailer] = useState("");
  const [imageUrlCompany, setImageUrlCompany] = useState("");
  const [signAndLogo, setSignAndLogo] = useState("");
  const toWords = new ToWords();
  const navigate = useNavigate();

  useEffect(() => {
    if (totalAmount) setAmountWord(toWords.convert(totalAmount));
  }, [totalAmount]);

  useEffect(() => {
    setTotalAmount(
      newProductData.reduce((initialData, data) => {
        initialData += data.amount;
        return initialData;
      }, 0)
    );
  }, [newProductData]);

  useEffect(() => {
    getProduct();
    getSignAndLogo();

    setBusinessName(
      selectedBusiness.business_name ? selectedBusiness.business_name : "PGARTS"
    );

    getTotalProduct();

    getCurrentDate();
  }, []);

  const getCurrentDate = () => {
    // Get the current date
    const today = new Date();

    // Format the date to YYYY-MM-DD (required by the date input field)
    const formattedDate = today.toISOString().substr(0, 10);

    // Set the current date as the default value
    setCurrentDate(formattedDate);
  };

  const handlePaymentStatusPaid = () => {
    setIsPaymentStatus(true);
  };

  const handlePaymentStatusUnPaid = () => {
    setIsPaymentStatus(false);
  };

  const getTotalProduct = () => {
    let storedData = sessionStorage.getItem("totalNewProduct");

    // Parse the stored data into a JavaScript object
    let parsedData = storedData ? JSON.parse(storedData) : null;

    if (parsedData) {
      setNewProductData(parsedData);
    }
  };

  const getProduct = async () => {
    const data = await fetchProduct(
      selectedBusiness.id ? selectedBusiness.id : 2
    );
  };

  const getSignAndLogo = async () => {
    const data = await fetchSignAndLogo(
      selectedBusiness.id ? selectedBusiness.id : 2
    );

    setImageUrlCompany(data.get_sign_logo_data.company_logo_image_url);
    setImageUrlRetailer(data.get_sign_logo_data.retailer_signature);
    setSignAndLogo(data.get_sign_logo_data);
  };

  const getOrderDetails = async () => {
    const data = await fetchOrderDetails(merchantData.ID, orderId);

    setCustomerName(
      `${data.data.merchat_orders[0].UserDetails.first_name} ${data.data.merchat_orders[0].UserDetails.last_name}`
    );
    setBillingAddress(data.data.merchat_orders[0].Order.billing_address);
    setShippingAddress(data.data.merchat_orders[0].Order.shipping_address);
    setMobileNo(data.data.merchat_orders[0].UserDetails.mobile_number);
    setPanNo(data.data.merchat_orders[0].UserDetails.UserKYC.pan_card);
    setGstNo(data.data.merchat_orders[0].UserDetails.UserKYC.gst_number);
  };

  const editButton = (index) => {
    setEditProductId(index);
  };

  const deleteButton = (index) => {
    // Retrieve the array from session storage
    let dataArray = JSON.parse(sessionStorage.getItem("totalNewProduct"));

    // Check if dataArray exists and if the index is valid
    if (dataArray && dataArray.length > 0) {
      dataArray.splice(index, 1); // Removes 1 item at index 1

      // Update session storage with the modified array
      sessionStorage.setItem("totalNewProduct", JSON.stringify(dataArray));
    }

    getTotalProduct();
  };

  const handleFileChangeRetailer = (event) => {
    const selectedFile = event.target.files[0];
    getImageUrlRetailer(selectedFile);
    // setImageUrlRetailer(getImageUrl(selectedFile));
  };

  const handleFileChangeCompany = (event) => {
    const selectedFile = event.target.files[0];
    getImageUrlCompany(selectedFile);
    // setImageUrlCompany(getImageUrl(selectedFile));
  };

  const getImageUrlCompany = (e) => {
    const formValue = new FormData();
    formValue.append("file", e);
    const url = "/api/mitra-merchant/doc-upload";
    axios
      .post(url, formValue)
      .then((response) => {
        setImageUrlCompany(response.data.file_location);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getImageUrlRetailer = (e) => {
    const formValue = new FormData();
    formValue.append("file", e);
    const url = "/api/mitra-merchant/doc-upload";
    axios
      .post(url, formValue)
      .then((response) => {
        setImageUrlRetailer(response.data.file_location);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadInvoice = async () => {
    let currentDate = new Date();
    let formattedDate = currentDate.toISOString();
    // const panPattern = /^[A-Z0-9\s]+$/;

    // if (!panPattern.test(panNo)) {
    //   toast.error("Pan no should be alphanumeric", {
    //     position: "top-center",
    //   });
    // } else
    if (newProductData.length === 0) {
      toast.error("Add Atleast One Item", {
        position: "top-center",
      });
    } else {
      // request data
      const requestData = {
        amount_in_words: amountWord,
        balance_due: isPaymentStatus ? 0 : totalAmount,
        business_name: businessName,
        company_logo_image_url: imageUrlCompany,
        customer_address: billingAddress,
        customer_mobile: mobileNo,
        customer_name: customerName,
        date_time: formattedDate,
        gst_reg_no: gstNo,
        image_url: signAndLogo.image_url,
        InvoiceItems: newProductData.map((item) => ({
          business_id: selectedBusiness.id ? selectedBusiness.id : 2,
          cess_amount: parseInt(item.cess_amount),
          cess_percentage: item.cess_percentage,
          description: item.description,
          new_unit_price: parseInt(item.selling_price),
          product_image_url: "test",
          product_service_name: item.productName,
          quantity: parseInt(item.quantity),
          unit: parseInt(item.quantity),
        })),
        kata_parchi: kataParchi,
        lorry_no: lorryNo,
        pan_number: panNo,
        payment_type: "online",
        received: 0,
        retailer_signature: imageUrlRetailer,
        state_code: stateCode,
        state_of_supply: stateCode,
        tax: 0,
        tax_type: "Gst",
        template_id: 1,
        total_amount: totalAmount,
      };

      const data = await downloadInvoiceData(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        requestData
      );

      if (data) navigate("/invoiceHistory");
    }
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-medium text-lg md:text-2xl xl:text-3xl">
              Create Invoice
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/finance" className="no-underline">
                Finance
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/createInvoice" className="no-underline">
                Create Invoice
              </NavLink>
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4 bg-[#ededed] p-[1rem] lg:p-[2rem] rounded-md">
          <div>
            <input
              type="date"
              className="bg-gray-50 outline-none border border-gray-300 text-[#384B9D] text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={currentDate}
              onChange={(e) => setCurrentDate(e.target.value)}
            />
          </div>

          <div>
            <input
              type="number"
              id="Monthly Salary"
              className="bg-gray-50 outline-none border border-gray-300 text-[#384B9D] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Order ID"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>

          <button
            type="button"
            className="text-white  bg-[#384B9D] w-full hover:bg-[#495fc2] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-5 py-3  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={getOrderDetails}
          >
            GET ORDER DETAILS
          </button>

          <div>
            <label
              for="Business Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Business Name*
            </label>
            <input
              type="text"
              id="Business Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Business Name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Customer Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Customer Name*
            </label>
            <input
              type="text"
              id="Customer Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Customer Name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Billing Address"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Billing Address*
            </label>
            <input
              type="text"
              id="Billing Address"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Billing Address"
              value={billingAddress}
              onChange={(e) => setBillingAddress(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Shipping Address"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Shipping Address
            </label>
            <input
              type="text"
              id="Shipping Address"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Shipping Address"
              value={shippingAddress}
              onChange={(e) => setShippingAddress(e.target.value)}
            />
          </div>

          <div>
            <label
              for="State/ UT Code"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              State / UT Code
            </label>
            <input
              type="text"
              id="State/ UT Code"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter State/ UT Code"
              value={stateCode}
              onChange={(e) => setStateCode(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Mobile Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Mobile Number
            </label>
            <input
              type="number"
              id="Mobile Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Mobile Number"
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
            />
          </div>

          <div>
            <label
              for="PAN Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              PAN Number
            </label>
            <input
              type="text"
              id="PAN Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter PAN Number"
              value={panNo}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 10)
                  setPanNo(e.target.value.toUpperCase());
              }}
            />
          </div>

          <div>
            <label
              for="GST Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              GST Number
            </label>
            <input
              type="number"
              id="GST Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter GST Number"
              value={gstNo}
              onChange={(e) => setGstNo(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Lorry Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Lorry Number
            </label>
            <input
              type="number"
              id="Lorry Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Lorry Number"
              value={lorryNo}
              onChange={(e) => setLorryNo(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Lorry Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Kata Parchi
            </label>
            <input
              type="text"
              id="Lorry Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Kata Parchi"
              value={kataParchi}
              onChange={(e) => setKataParchi(e.target.value)}
            />
          </div>
        </div>

        <div className="bg-[#ededed] mb-3 p-[1rem] lg:p-[2rem] rounded-md w-[97%] md:w-[98%] ">
          <div className="bg-[#fbfbfb] shadow-lg border border-gray-300 mb-4 rounded-md overflow-auto">
            <div className="flex items-center p-1 md:p-2 lg:p-3 border-b border-dotted border-[#999999]">
              <NavLink to="/editProduct" className="no-underline">
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[0.5rem] md:text-[0.7rem] lg:text-[0.8rem] px-1 md:px-2 lg:px-4 py-1 lg:py-2 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Add Items
                </button>
              </NavLink>
            </div>

            <div className="py-2 overflow-auto h-[5rem] md:h-[7rem] ">
              <table className="w-full text-center">
                <tr className="bg-[#fffafa] sticky top-0">
                  <th className="text-[0.4rem] md:text-[0.7rem]  text-[#666666]">
                    Product
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    UOM
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Quantity
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem]  text-[#666666]">
                    Total Amount
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Edit
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Delete
                  </th>
                </tr>

                {newProductData.map((item, index) => (
                  <tr key={index}>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem] ">
                      {item.productName}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.uom}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.quantity}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.amount}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.6rem] lg:text-[0.7rem]">
                      <NavLink to="/editProduct" className="no-underline">
                        <button
                          onClick={() => editButton(index)}
                          className="w-fit rounded cursor-pointer px-[0.4rem] py-[0.1rem] text-[#ffffff] bg-[#4f61b1]"
                        >
                          Edit
                        </button>
                      </NavLink>
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.6rem] lg:text-[0.7rem]">
                      <button
                        onClick={() => deleteButton(index)}
                        className="w-fit rounded cursor-pointer px-[0.4rem] py-[0.1rem] text-[#ffffff] bg-[#4f61b1]"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>

          <div className="grid gap-6 mb-6 md:grid-cols-2  ">
            <div>
              <label
                for="Total Amount"
                className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
              >
                Total Amount*
              </label>
              <input
                type="number"
                id="Total Amount"
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Total Amount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
              />
            </div>

            <div>
              <label
                for="Amount in Words"
                className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
              >
                Amount in Words*
              </label>
              <input
                type="text"
                id="Amount in Words"
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Amount"
                value={amountWord}
                onChange={(e) => setAmountWord(e.target.value)}
              />
            </div>
          </div>

          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                for="Signature"
                className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
              >
                Signature
              </label>
              <input
                type="file"
                id="Signature"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={handleFileChangeRetailer}
              />
            </div>

            <div>
              <label
                for="Optional"
                className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
              >
                Optional
              </label>
              <input
                type="file"
                id="Optional"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={handleFileChangeCompany}
              />
            </div>
          </div>

          <div>
            <p className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white">
              Add Hisab Entry
            </p>
            <div className="flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 py-3 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <p className="text-[0.5rem] md:text-xs font-medium text-[#384B9D] dark:text-white">
                Select Payment Status
              </p>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                  className="mr-1 w-[0.5rem] md:w-[0.7rem]"
                  onClick={handlePaymentStatusPaid}
                  defaultChecked
                />
                <label
                  for="html"
                  className="font-medium text-[0.6rem] md:text-xs mr-1 md:mr-2 "
                >
                  Paid
                </label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="CSS"
                  className="mr-1 w-[0.5rem] md:w-[0.7rem]"
                  onClick={handlePaymentStatusUnPaid}
                />
                <label
                  for="css"
                  className="font-medium text-[0.6rem] md:text-xs"
                >
                  Unpaid
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={downloadInvoice}
          >
            Download & Share
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
