import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveNewProduct } from "../../../services/Api";

const AddNewProduct = () => {
  const [productName, setProductName] = useState("");
  const [UOM, setUOM] = useState("");
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [GST, setGST] = useState("");
  const [stockQuantity, setStockQuantity] = useState("");
  const [cessInPercentage, setCessInPercentage] = useState(0);
  const [cessAmount, setCessAmount] = useState(0.0);
  const [quantity, setQuantity] = useState();
  const [totalAmount, setTotalAmount] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const { setProductData, setProductDetails, selectedBusiness } =
    useDataContext();

  useEffect(() => {
    if (quantity && sellingPrice)
      setTotalAmount((parseInt(quantity) * parseInt(sellingPrice)).toFixed(2));
  }, [quantity]);

  const addProduct = async () => {
    const reqData = {
      business_id: (selectedBusiness.id ? selectedBusiness.id : 2).toString(), //todo - change it based on businesses
      buying_price: buyingPrice,
      cess_amount: parseInt(cessAmount),
      cess_percentage: parseInt(cessInPercentage),
      gst: GST,
      margin_amount: "0",
      margin_percentage: "0",
      measurement_unit: UOM,
      originated_from: "",
      pack_size: "0",
      presence: "true",
      product_image_url: "",
      product_name: productName,
      selling_price: sellingPrice,
      sku: "",
    };

    if (
      productName &&
      UOM &&
      buyingPrice &&
      sellingPrice &&
      GST &&
      stockQuantity &&
      quantity
    ) {
      const data = await saveNewProduct(reqData);
      //store product data in context api
      setProductData(data && data);

      //here store others details which is not present in setProductData
      setProductDetails({
        quantity: quantity,
        description: description,
        stockQuantity: stockQuantity,
        totalAmount: totalAmount,
      });

      // Retrieve existing data from sessionStorage
      let existingData = sessionStorage.getItem("totalNewProduct");

      // Parse existing data (if any) into a JavaScript object, or initialize an empty array if no data exists
      let dataArray = existingData ? JSON.parse(existingData) : [];

      // Create a new data object
      let newData = {
        productName: productName,
        quantity: quantity,
        uom: UOM,
        amount: parseInt(totalAmount),
        business_id: (selectedBusiness.id ? selectedBusiness.id : 2).toString(),
        buying_price: buyingPrice,
        cess_amount: parseInt(cessAmount),
        cess_percentage: parseInt(cessInPercentage),
        gst: GST,
        selling_price: sellingPrice,
        description: description, 
        productId: data && data.Data.id,
      };

      // Add the new data to the array
      dataArray.push(newData);

      sessionStorage.setItem("totalNewProduct", JSON.stringify(dataArray));

      navigate(-1);
      
    } else {
      toast.error("Please complete all mandatory fields.", {
        position: "top-center",
      });
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <h1 className="text-[#0e3293] font-semibold text-[1rem] md:text-2xl xl:text-3xl">
            Add New Product
          </h1>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div>
            <label
              for="Product Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Product Name*
            </label>
            <input
              type="text"
              id="Product Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>

          <div>
            <p className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white">
              UOM*
            </p>
            <div>
              <select
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={UOM}
                onChange={(e) => setUOM(e.target.value)}
              >
                <option value="" className="font-semibold">
                  Select Unit
                </option>
                <option value="gram">Gram</option>
                <option value="kilogram">Kilogram</option>
                <option value="Milliliter">Milliliter</option>
                <option value="Liter">Liter</option>
                <option value="Dozen">Dozen</option>
                <option value="Pieces">Pieces</option>
              </select>
            </div>
          </div>

          <div>
            <label
              for="Buying Price"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Buying Price*
            </label>
            <input
              type="text"
              id="Buying Price"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Amount"
              value={buyingPrice}
              onChange={(e) => setBuyingPrice(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Buying Price"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Selling Price*
            </label>
            <input
              type="text"
              id="Buying Price"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Amount"
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Buying Price"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              GST*
            </label>
            <input
              type="text"
              id="Buying Price"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="GST"
              value={GST}
              onChange={(e) => setGST(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Stock Quantity"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Stock Quantity*
            </label>
            <input
              type="text"
              id="Stock Quantity"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Stock Quantity"
              value={stockQuantity}
              onChange={(e) => setStockQuantity(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Cess in %"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Cess in %
            </label>
            <input
              type="text"
              id="Cess in %"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Percentage"
              value={cessInPercentage}
              onChange={(e) => setCessInPercentage(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Cess Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Cess Amount
            </label>
            <input
              type="text"
              id="Cess Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Amount"
              value={cessAmount}
              onChange={(e) => setCessAmount(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Cess Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Quantity*
            </label>
            <input
              type="text"
              id="Cess Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Cess Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Total Amount
            </label>
            <input
              type="text"
              id="Cess Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Amount"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Description"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Description
            </label>
            <input
              type="text"
              id="Description"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Describe Product"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={addProduct}
          >
            Add Product
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewProduct;
