import React, { useEffect, useState } from "react";
import FinanceChart from "./charts/FinanceChart";
import { financeChartApi } from "../../../../services/ChartApi";
import { useDataContext } from "../../../../contexts/DataContext";

const FinanceGraph = ({ defaultValueDate, getDate }) => {
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [chartData, setChartData] = useState([]);
  const [collectedAmount, setCollectedAmount] = useState("");
  const [paidAmount, setPaidAmount] = useState("");

  useEffect(() => {
    getChartData(defaultValueDate);
  }, [defaultValueDate]);

  useEffect(() => {
    getChartData(getDate);
  }, [getDate]);

  useEffect(() => {
    // sum of collected amount
    const sumCollectedAmount = chartData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.collected_money,
      0
    );
    setCollectedAmount(sumCollectedAmount);

    // sum of paid amount
    const sumPaidAmount = chartData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.money_given,
      0
    );
    setPaidAmount(sumPaidAmount);
  }, [chartData]);

  const getChartData = async (date) => {
    if (date) {
      const data = await financeChartApi(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        date + "-01",
        date + "-28"
      );

      setChartData(data ? data.data : []);
    }
  };

  return (
    <>
      <div>
        <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 sm:mr-3 mt-4">
          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem] mb-2 md:mb-0">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              Collected Amount
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              {collectedAmount}
            </p>
          </div>

          <div className="finance-div w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem]">
            <p className="font-semibold text-[#ececec] text-[0.8rem] lg:text-[1.1rem] mb-1">
              Paid Amount
            </p>
            <p className="font-semibold text-[#ececec] text-[0.9rem] lg:text-[1.4rem]">
              {paidAmount}
            </p>
          </div>
        </div>

        <div className="w-[97%] md:w-[98%]">
          <FinanceChart chartValue={chartData} />
        </div>
      </div>
    </>
  );
};

export default FinanceGraph;
