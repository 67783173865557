import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import shopList from "../../../images/shopping-list 1.svg";
import booking from "../../../images/booking 1.svg";
import badge from "../../../images/badge 1.svg";
import ItemCard from "./ItemCard";
import CategoryCard from "./CategoryCard";
import {
  getMerchants,
  getPartners,
  getPreviousProducts,
  getShopByCategory,
  getUserStats,
} from "../../../services/Api";
import MerchantsCard from "./MerchantsCard";
import PreviousProduct from "./PreviousProduct";

const ShopPage = () => {
  const [userData, setUserData] = useState({});
  const [partnerData, setPartnerData] = useState([]);
  const [merchantsData, setMerchantsData] = useState([]);
  const [getCategoryData, setGetCategoryData] = useState([]);
  const [getPreviousData, setGetPreviousData] = useState([]);

  // useEffect(() => {
  //   console.log("merchantsData: ", merchantsData);
  // }, [merchantsData]);

  useEffect(() => {
    getUserStatsData();
    featuredPartner();
    getMerchantsData();
    getCategory();
    getPreviousProduct();
  }, []);

  const getUserStatsData = async () => {
    const data = await getUserStats();
    setUserData(data.data);
  };

  const featuredPartner = async () => {
    const data = await getPartners();
    setPartnerData(data.data);
  };

  const getMerchantsData = async () => {
    const data = await getMerchants();
    setMerchantsData(data.data);
  };

  const getCategory = async () => {
    const data = await getShopByCategory();
    setGetCategoryData(data.data);
  }; 

  const getPreviousProduct = async () => {
    const data = await getPreviousProducts();
    setGetPreviousData(data.data);
  }; 

  return (
    <>
      <div>
        <div className="customer-container">
          <div>
            <h1 className="home-middle-heading">Shop</h1>
            <p className="home-middle-sub-heading">
              <NavLink
                to="/"
                style={{ textDecoration: "none", color: "black" }}
              >
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink
                to="/finance"
                style={{ textDecoration: "none", color: "black" }}
              >
                Shop
              </NavLink>
            </p>
          </div>
        </div>

        <div className="finance-container">
          <div className="shop-div">
            <img src={shopList} alt="icon" />
            <p className="finance-text1">My Orders</p>
            <p className="finance-text2">{userData.my_orders}</p>
          </div>

          <div className="shop-div">
            <img src={booking} alt="icon" />
            <p className="finance-text1">Received Orders</p>
            <p className="finance-text2">{userData.received_orders}</p>
          </div>

          <div className="shop-div">
            <img src={badge} alt="icon" />
            <p className="finance-text1">My Rewards</p>
            <p className="finance-text2">
              {userData.my_wallet_balance ? userData.my_wallet_balance : 0}
            </p>
          </div>
        </div>

        <div className="shop-container">
          <div className="shop-header">
            <p className="shop-header-txt1">Featured Partners</p>
            <p className="shop-header-txt2">View All</p>
          </div>

          <div className="partner-card-container">
            {partnerData.length > 0 &&
              partnerData.slice(0, 4).map((item, index) => {
                return <ItemCard data={item} key={index} />;
              })}
          </div>
        </div>

        <div className="shop-container">
          <div className="shop-header">
            <p className="shop-header-txt1">
              Previously Ordered from Merchants
            </p>
            <p className="shop-header-txt2">View All</p>
          </div>

          <div className="partner-card-container">
            {merchantsData.length > 0 &&
              merchantsData.map((item, index) => {
                return <MerchantsCard data={item} key={index} />;
              })}
          </div>
        </div>

        <div className="shop-container">
          <div className="shop-header">
            <p className="shop-header-txt1">Shop by Category</p>
            <p className="shop-header-txt2">View All</p>
          </div>

          <div className="partner-card-container">
          {getCategoryData.length > 0 &&
              getCategoryData.slice(0, 4).map((item, index) => {
                return <CategoryCard data={item} key={index} />;
              })}
          </div>
        </div>

        <div className="shop-container">
          <div className="shop-header">
            <p className="shop-header-txt1">Previously Ordered Products</p>
            <p className="shop-header-txt2">View All</p>
          </div>

          <div className="partner-card-container">
          {getPreviousData.length > 0 &&
              getPreviousData.slice(0, 1).map((item, index) => {
                return <PreviousProduct data={item} key={index} />;
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopPage;
