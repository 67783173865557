import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div style={{width: '97%'}}>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "center",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "center",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              AYEKART FINTECH PRIVATE LIMITED
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "center",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "center",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              PRIVACY POLICY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
            Ayekart Fintech Private Limited{" "}
          </span>
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
              (“Ayekart”)
            </span>
          </strong>
          <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
            {" "}
            a Company incorporated under the laws of India, and its affiliates,
            is a demand aggregation and supply chain management platform for
            trading of food and agricultural related goods
          </span>
          <span style={{ fontFamily: "Bookman Old Style" }}>
            , by the trade names “Ayekart Hisab”,
          </span>
          <span
            style={{ fontFamily: "Bookman Old Style", letterSpacing: "0.05pt" }}
          >
            {" "}
          </span>
          <span style={{ fontFamily: "Bookman Old Style" }}>“Ayekart</span>
          <span
            style={{
              fontFamily: "Bookman Old Style",
              letterSpacing: "-0.65pt",
            }}
          >
            {" "}
          </span>
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Business”, “Ayekrishi”
          </span>
          <span
            style={{
              fontFamily: "Bookman Old Style",
              letterSpacing: "-0.25pt",
            }}
          >
            {" "}
          </span>
          <span style={{ fontFamily: "Bookman Old Style" }}>and</span>
          <span
            style={{ fontFamily: "Bookman Old Style", letterSpacing: "-0.6pt" }}
          >
            {" "}
          </span>
          <span style={{ fontFamily: "Bookman Old Style" }}>“Ayekart</span>
          <span
            style={{ fontFamily: "Bookman Old Style", letterSpacing: "-0.6pt" }}
          >
            {" "}
          </span>
          <span style={{ fontFamily: "Bookman Old Style" }}>Consumers”</span>
          <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
            {" "}
            along with the website located at https://ayekart.com/
          </span>
          <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
            &#xa0;{" "}
          </span>
          <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
            (Collectively referred to as the “
          </span>
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
              Ayekart Platform
            </span>
          </strong>
          <span style={{ fontFamily: "Bookman Old Style", fontSize: "11pt" }}>
            ”). The Platform is designed to assist Users in recording and
            managing their trades effortlessly. Additionally, the Ayekart
            Platform provides Users with the capability to execute payment
            transactions and collect funds from buyers and sellers nationwide,
            offering multiple payment options (Services).
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              Nature of the Policy
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            This Privacy Policy (the Privacy Policy) describes how and why the
            Ayekart Platform collects, uses, transfers and discloses the
            information provided by the Users. All Users shall be bound by this
            Privacy Policy and the Company shall not use any information
            supplied by Users, except in accordance with this Privacy Policy.
          </span>
        </p>

        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            By clicking on ‘I/We Accept’ during the sign-up process, users agree
            to and accept the terms and conditions provided under this Privacy
            Policy, read in addition to the Ayekart Platform terms (Terms &amp;
            Condition) (accessible at _________________). Users who do not agree
            with the terms set out in this Privacy Policy and the Terms are
            advised to refrain from accepting them and are also advised to
            refrain from using any Services. Users visit to and/or use of the
            Ayekart Platform and any dispute over privacy is subject to this
            Privacy Policy and the Terms &amp; Condition.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              Why does the Company have this Privacy
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            This Privacy Policy is published pursuant to:
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <ol type="1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Section 43A of the Information Technology Act, 2000;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Rule 4 of the Information Technology (Reasonable Security Practices
            and Procedures and Sensitive Personal Data or Information) Rules,
            2011 (Privacy Rules);
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Rule 3(1) of the Information Technology (Intermediaries Guidelines
            and Digital Media Ethics Code) Rules, 2021; and
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Guidelines and regulations issued by Reserve Bank of India from time
            to time, to the extent applicable to the Company
          </li>
        </ol>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            In this Privacy Policy, the Company will disclose what kind of
            information is collected from Users. It will also specify why the
            Company collects this information, the modes and means by which it
            will be used, and the entities or persons who will have access to
            this information.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "11pt" }}>
          <br style={{ pageBreakBefore: "always", clear: "both" }} />
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              What is Sensitive Personal Data or Information?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Under the Privacy Rules, sensitive personal data or information of a
            person means and includes such personal information about that
            person relating to the following (Personal Information):
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <ol type="1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Passwords;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Information such as bank accounts, credit and debit card details or
            other payment instrument details (Information);
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Physical, physiological and mental health condition;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Sexual orientation;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Medical records and history;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Biometric information; and
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Information received by body corporates under lawful contract or
            otherwise.
          </li>
        </ol>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            There is also certain information which will be collected from Users
            such as addresses, email addresses, telephone numbers, address proof
            and identity proof, User photographs and other contact information
            which may be used to personally identify Users directly or
            indirectly either from the collected information alone or from said
            information combined with other information which the Company may
            have access to about the User (Personally Identifiable Information).
            It is important to note that information that is freely available in
            the public domain, or accessible under the Right to Information Act,
            2005, or any other law will not be considered Sensitive Personal
            Information, or as Personally Identifiable Information. Sensitive
            Personal Information and Personally Identifiable Information is
            collectively referred to as (User Information).
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              What Sensitive Personal Information and Personally Identifiable
              Information does the Company collect from Users?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company will only collect User Information that is necessary for
            the purposes of rendering the Services and will not further process
            it in a manner that is incompatible with those purposes unless
            otherwise consented by the User.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: "Bookman Old Style" }}>
                Sensitive Personal Information:
              </span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              Payment Information Collection and Processing:
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            In the course of transactions conducted on the Ayekart Platform, the
            Company may, through its third-party payment gateway provider or any
            other designated platform, offer Users the option to make payments
            using credit cards, wire transfers, or debit cards. To facilitate
            these transactions, the third-party payment gateway provider may
            necessitate the collection of specific Financial Information from
            Users, encompassing but not limited to, their financial details.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Financial Information gathered is exclusively utilized for the
            purposes of billing and payment in relation to the Services provided
            through the Ayekart Platform. It is expressly clarified that the
            verification processes associated with the Financial Information
            provided by Users are entirely the responsibility of the Users, and
            the Ayekart Platform disclaims any involvement in or responsibility
            for such verification.
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "11pt" }}>
          <br style={{ pageBreakBefore: "always", clear: "both" }} />
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              Collection of Additional User Information:
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            In instances where a User avails Services from the Company via the
            Ayekart Platform, or voluntarily shares data, Ayekart may
            additionally collect credit bureau data (subject to the User's
            explicit consent), income data, bank statements, and other financial
            information. This collection is undertaken to facilitate support
            services, including but not limited to, sharing credit bureau data
            and Know Your Customer (KYC) information with third parties for
            verification and facilitation, all with the User's consent.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The transmission and processing of the aforementioned information
            are conducted through secure digital platforms of approved payment
            gateways, employing encryption protocols to comply with prevailing
            technology standards. While the Company diligently endeavours to
            implement commercially reasonable security measures mandated by
            applicable privacy laws, Users are strongly advised to exercise
            discretion when providing financial information, given that absolute
            security over the internet cannot be guaranteed.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            By using the Services, Users expressly acknowledge and agree to the
            terms outlined in this Payment Information Collection and Processing
            section of the Privacy Policy.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: "Bookman Old Style" }}>
                Personally, Identifiable Information (PII):
              </span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company may collect the following PII from its Users in the
            normal course of a User’s use of the Ayekart Platform:
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Account number, mobile number, business name, PAN number, date of
            birth, full name, address, masked Aadhar number
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              Automatically Generated Information:
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            When a User visits the Ayekart Platform, the Company automatically
            receives the following information:
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <ol style={{ margin: "0pt", paddingLeft: "0pt" }} type="1">
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Uniform resource locator of the site from which such user visits the
            Ayekart Platform;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Details of the website such User is visiting on leaving the Ayekart
            Platform;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            The internet protocol (IP) address of each user’s computer operating
            system;
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Type of web browser the user is using, email patterns; and
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            The user’s internet service provider.
          </li>
        </ol>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>Locations</span>
          </strong>
        </p>

        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company may with consent of the User, also access, collect and/
            or store information regarding the User’s location via the Ayekart
            Platform, which includes but is not limited to their global
            positioning system coordinates vis-à-vis the device used to access
            the Ayekart Platform. The location data collected does not include
            any Personally Identifiable Information about the User.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              Telephone Calls and Other Communications Received from Users:
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Subject to applicable laws, the Company may keep records of
            telephone calls, emails, transactional SMS and other channels of
            communication received from and made to Users for the purpose of
            administration of Services, research and development, training,
            business intelligence, business development, or for User
            administration. The Ayekart Platform may share the telephone
            records, emails, SMSs and other communications received from Users
            with third parties when required by law or when required to provide
            or facilitate the User with the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            In addition to the User Information collected by the Company (as
            outlined above), the Company may also collect other types of
            information which are not directly or indirectly linked to a User
            and which is aggregated, anonymized or de-identified including
            mobile phone resources, files and media, contact list, call logs,
            telephony functions, camera, microphone, location or any other
            facility necessary for the purpose of onboarding/KYC requirements,
            etc, with explicit consent of the user. Such information is
            collected in order to improve the services we provide to you.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Please note that all User Information is collected by the Company on
            an as-is basis and the Company shall not be responsible for the
            authenticity of the User Information provided by Users.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Further, without prejudice to the above and subject to applicable
            laws, you hereby expressly consent to providing the following
            permissions to us in relation to the respective data usage:
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            User Permission required Data Usage
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <table
          style={{
            width: "100%",
            border: "0.75pt solid #000000",
            borderCollapse: "collapse",
          }}
        >
          <tr style={{ height: "32pt" }}>
            <td
              style={{
                width: "22.92%",
                borderRightStyle: "solid",
                borderRightWidth: "0.75pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "0.75pt",
                paddingRight: "5.03pt",
                paddingLeft: "5.03pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "center",
                  fontSize: "11pt",
                }}
              >
                <strong>
                  <span
                    style={{
                      fontFamily: "Bookman Old Style",
                      color: "#333333",
                    }}
                  >
                    User Permission required
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: "77.08%",
                borderBottomStyle: "solid",
                borderBottomWidth: "0.75pt",
                paddingRight: "5.03pt",
                paddingLeft: "5.4pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "center",
                  fontSize: "11pt",
                }}
              >
                <strong>
                  <span
                    style={{
                      fontFamily: "Bookman Old Style",
                      color: "#333333",
                    }}
                  >
                    Data Usage
                  </span>
                </strong>
              </p>
            </td>
          </tr>
          <tr style={{ height: "61pt" }}>
            <td
              style={{
                width: "22.92%",
                borderRightStyle: "solid",
                borderRightWidth: "0.75pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "0.75pt",
                paddingRight: "5.03pt",
                paddingLeft: "5.03pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  fontSize: "11pt",
                }}
              >
                <strong>
                  <span
                    style={{
                      fontFamily: "Bookman Old Style",
                      color: "#333333",
                    }}
                  >
                    SMS data (Transactional SMS)
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: "77.08%",
                borderBottomStyle: "solid",
                borderBottomWidth: "0.75pt",
                paddingRight: "5.03pt",
                paddingLeft: "5.4pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  fontSize: "11pt",
                }}
              >
                <span
                  style={{ fontFamily: "Bookman Old Style", color: "#333333" }}
                >
                  To analyze the User's income and spending patterns, aiding us
                  or our affiliates in the credit evaluation process and
                  expediting higher limits and faster approval rates
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "61pt" }}>
            <td
              style={{
                width: "22.92%",
                borderRightStyle: "solid",
                borderRightWidth: "0.75pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "0.75pt",
                paddingRight: "5.03pt",
                paddingLeft: "5.03pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  fontSize: "11pt",
                }}
              >
                <strong>
                  <span
                    style={{
                      fontFamily: "Bookman Old Style",
                      color: "#333333",
                    }}
                  >
                    Phone Permission
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: "77.08%",
                borderBottomStyle: "solid",
                borderBottomWidth: "0.75pt",
                paddingRight: "5.03pt",
                paddingLeft: "5.4pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  fontSize: "11pt",
                }}
              >
                <span
                  style={{ fontFamily: "Bookman Old Style", color: "#333333" }}
                >
                  To gather and monitor specific information about a User's
                  device, including hardware model, operating system and
                  version, unique device identifiers such as IMEI and serial
                  number, and user profile information. This aims to uniquely
                  identify devices, ensuring that unauthorized devices cannot
                  act on behalf of the User, thereby mitigating the risk of
                  fraud.
                </span>
              </p>
            </td>
          </tr>

          <tr style={{ height: "61pt" }}>
            <td
              style={{
                width: "22.92%",
                borderRightStyle: "solid",
                borderRightWidth: "0.75pt",
                paddingRight: "5.03pt",
                paddingLeft: "5.03pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  fontSize: "11pt",
                }}
              >
                <strong>
                  <span
                    style={{
                      fontFamily: "Bookman Old Style",
                      color: "#333333",
                    }}
                  >
                    App Permission
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: "77.08%",
                paddingRight: "5.03pt",
                paddingLeft: "5.4pt",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  fontSize: "11pt",
                }}
              >
                <span
                  style={{ fontFamily: "Bookman Old Style", color: "#333333" }}
                >
                  This application gathers information about the installed
                  applications on your device to enhance our understanding of
                  application usage, including VPNs and others. This data is
                  utilized for fraud detection and to comprehend your risk
                  profile throughout the lending journey.
                </span>
              </p>
            </td>
          </tr>
        </table>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              When does the Company collect User Information?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company collects User information from Users when Users register
            on the Ayekart Platform, and place and order, subscribe to a
            newsletter, or enter information on the Ayekart Platform. The
            Company may use email, app notifications, short message service
            (SMS) transactional, multimedia message service (MMS) or other forms
            of communication (such as WhatsApp) to share information with Users
            about certain promotions or features regarding the Ayekart Platform
            which the Company or its affiliates, subsidiaries, business
            partners, advertisers and sponsors may choose to offer. Users may
            receive such communication when they opt to receive the same or have
            consented to the same during the registration process.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: "Bookman Old Style" }}>
                Who has access to your information?
              </span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style" }}>
              Employees, Authorised Personnel and Affiliates
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            All information that is collected, stored, processed or disclosed to
            the Company by Users is accessible only to authorised personnel and
            employees of the Company on a need-to-know basis. All employees and
            data processors, who have access to and are associated with the
            processing of User Information are obliged to respect its
            confidentiality. The Company may also disclose User Information to
            its affiliates for business purposes, such as for the promotion,
            sales or marketing of such affiliates’ services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Government Institutions or Authorities
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company may be required to disclose User Information to
            governmental institutions or authorities under any law or judicial
            decree. The Company may disclose User Information in its sole
            discretion, in case Ayekart deems it necessary in order to protect
            its rights or the rights of others, to prevent harm to persons or
            property, to fight fraud and credit risk, or to enforce or apply
            this Privacy Policy or the Terms. The Ayekart Platform shall not be
            responsible for the manner or method in which such institutions and
            authorities use or disclose such User Information.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Other Parties
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Ayekart Platform may share/use User Information with the other
            parties (payment gateway service providers, logistics service
            providers, affiliates entity etc.) for the purposes of:
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            providing services, technical support, etc. in relation to the
            Services and enhancing User experience; or
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            detecting and preventing identity theft, fraud or any other
            potentially illegal acts; or
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            monitoring enhancing User interest and engagement, including through
            promotional activity, personal messages to users using Personally
            Identifiable Information provided by Users, etc; or
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            processing the purchase of Services on the Ayekart Platform directly
            or through its affiliate’s entity.
          </li>
        </ol>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Merging/Acquiring Parties/Investing Parties
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            In the event that the Company is merged with or acquired by another
            business entity, it will be required to transfer all User
            Information to such merging or acquiring party. We may also share
            User Information with bona fide potential investors and investors.
            In such cases, the Company will take all reasonable efforts to make
            sure that the User Information is protected by the merging,
            acquiring, or investing entity, in conformity with applicable laws.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Third Party upon Sale
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company may also disclose or transfer the User Information
            provided by Users, to any third party as a part of reorganization or
            a sale of the assets, division or transfer of a part or whole of the
            Company. Any third party to which the Company transfers or sells its
            assets (such as the Ayekart Platform) will have the right to
            continue to use the User Information that Users provide to the
            Company.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Third Party Sites
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              &#xa0;
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company does not exercise control over the websites displayed as
            search results or links from within the Services. These other sites
            may place their own cookies or other files on the Users’ computer,
            collect data or solicit User Information from Users, for which the
            Company shall not be held responsible or liable. The Company does
            not make any representations concerning the privacy practices or
            policies of such third parties or terms of use of such websites, nor
            does the Company guarantee the accuracy, integrity, or quality of
            the information, data, text, software, sound, photographs, graphics,
            videos, messages or other materials available on such websites. The
            inclusion or exclusion of such websites does not imply any
            endorsement by the Company of such websites, the websites’ provider,
            or the information on the website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              How do we use your information?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company may use the User Information collected from the User
            when said Users register, make a purchase, sign up for the Company
            newsletter, respond to a survey or marketing communication, or when
            Users use certain other application features of the Ayekart Platform
            in the following ways:
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <ol type="1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            To identify the User, to understand their needs and resolve
            disputes, if any.
          </li>
          <li
            style={{
              marginLeft: "33.34pt",
              textAlign: "justify",
              paddingLeft: "2.66pt",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            To personalize User experience and to allow the Company to deliver
            the type of content and product offerings in which Users are most
            interested.
          </li>
          {/* Repeat the above list items for each point */}
          <li
            style={{
              marginLeft: "36pt",
              textAlign: "justify",
              fontFamily: "Bookman Old Style",
              fontSize: "11pt",
            }}
          >
            Other reasons which, prior to being put into effect, shall be
            communicated to Users through an update carried out to this Privacy
            Policy.
          </li>
        </ol>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              How do we protect your information?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company has diligently implemented security policies, regulatory
            rules, and technical measures as mandated by applicable laws,
            encompassing firewalls, transport layer security, and other physical
            and electronic security protocols. These measures are instituted to
            safeguard User Information under the Company's control from
            unauthorized access, improper use or disclosure, unauthorized
            modification, and unlawful destruction or accidental loss.
          </span>
        </p>
        {/* Repeat the above paragraphs for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            All information collected from Users via the Ayekart Platform is
            stored in electronic form on servers and/or cloud systems and is
            accessible only to specific employees of the Company. The User
            Information collected by the Company is stored by
            _________________________, located in ____________________, India.
          </span>
        </p>
        {/* Repeat the above paragraph for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Furthermore, User Information may, from time to time, be converted
            to or stored in physical form, such as hard drives or server racks.
            Irrespective of the manner of storage, the Company is committed to
            making commercially reasonable efforts to ensure the confidentiality
            of User Information. The Company will only disclose such User
            Information in strict accordance with the terms outlined in this
            Privacy Policy.
          </span>
        </p>
        {/* Repeat the above paragraph for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Retention of User Information
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            User Information will be held for as long as it is necessary to
            fulfil the purpose for which it was collected, or as required or
            permitted by applicable laws. The Company shall cease to retain User
            Information, or remove the means by which the User Information can
            be associated with particular Users, as soon as it is reasonable to
            assume that the purpose for which that User Information was
            collected is no longer being served by retention of the User
            Information.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Withdrawal of User Consent
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Users may withdraw their consent for the collection, use and/or
            disclosure of User Information in the Company’s possession or
            control by submitting a request to cloud support@Ayekart.com. The
            Company will process your request within a reasonable time from when
            the request was made, and thereafter not collect, use and/or
            disclose said User’s User Information as per their request.
          </span>
        </p>
        {/* Repeat the above paragraphs for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Depending on the extent of a User’s withdrawal of consent for us to
            process User Information, it may mean that said User will not be
            able to further use or access the Ayekart Platform and avail
            Services.
          </span>
        </p>
        {/* Repeat the above paragraphs for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Users Right to Review and Modify User Information
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Users can access their User Information at any time to review the
            same. Users can seek to rectify any inaccurate or deficient User
            Information, and the Company may rectify it if feasible.
          </span>
        </p>
        {/* Repeat the above paragraphs for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Do we use Cookies?
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            We will use cookies for tracking purposes and for the collection of
            relevant meta data. Users can choose to have their devices warn them
            each time a cookie is being sent, or Users can choose to turn off
            all cookies. Users may do this through their device browser
            settings. As Users may use different browsers, Users may look at
            their browser’s help menu to learn the correct way to modify their
            cookies
          </span>
        </p>
        {/* Repeat the above paragraphs for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            If Users turn cookies off, some of the Ayekart Platform’s features
            that make User experience more efficient may no longer function as
            intended.
          </span>
        </p>
        {/* Repeat the above paragraphs for each point */}
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Changes in Privacy Policy
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>

        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            We retain the right to modify this Privacy Policy at any time;
            therefore, we encourage Users to review it regularly. Changes and
            clarifications will become effective immediately upon posting on the
            Ayekart Platform. In the event of substantial alterations to this
            Privacy Policy, the Company commits to notifying Users of the
            updated policy. This ensures that Users remain informed about the
            information collected by the Company, its usage, and any
            circumstances under which the Company may use and/or disclose such
            information.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            The Company may, at regular intervals, push updates to Users to
            address production bugs and enhance the overall user experience on
            the Ayekart Platform. In certain instances, Users may be required to
            install these updates to continue using and optimizing their
            experience on the Ayekart Platform.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            By continuing to use our services, you affirm your acceptance of our
            Privacy Policy, including any amendments made to it. It is essential
            to stay apprised of the latest version of the Privacy Policy to
            remain informed about how your information is handled.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <strong>
            <span style={{ fontFamily: "Bookman Old Style", color: "#333333" }}>
              Questions and Contact Information
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            If there are any questions regarding this Privacy Policy, you may
            contact us using the information below:
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Grievance Officer:{" "}
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0.15pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>Name: </span>
          <span
            style={{ fontFamily: "Bookman Old Style", letterSpacing: "0.05pt" }}
          >
            Kunjal Thackar
          </span>
        </p>
        <p
          style={{
            marginTop: "0.05pt",
            marginRight: "6.15pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>Address: </span>
          <span
            style={{ fontFamily: "Bookman Old Style", letterSpacing: "1.75pt" }}
          >
            401, 402, Shah Trade Centre, Rani Sati Marg, Malad East, Mumbai
            400097, Maharashtra 400097
          </span>
        </p>
        <p
          style={{
            marginTop: "0.05pt",
            marginRight: "6.15pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            Email id: kunjal@ayekart.com
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>
            _______________________x_________________________________x_______________________
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            textAlign: "justify",
            fontSize: "11pt",
          }}
        >
          <span style={{ fontFamily: "Bookman Old Style" }}>&#xa0;</span>
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
