import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  dateConvert,
  dateTimeConvert,
} from "../../../utilities/convertDate";
import { stockDetailsApi } from "../../../services/Api";
import { useDataContext } from "../../../contexts/DataContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const StockDetails = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [stockData, setStockData] = useState({});
  const [stockEntries, setStockEntries] = useState([]);
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getStockData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const getStockData = async () => {
    const data = await stockDetailsApi(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      id
    );
    setStockEntries(data ? data.data.stock_entries : []);
    setStockData(data? data.data : {});
  };

  function mostRecentButton() {
    setIsToggle(false);
    setStockEntries(stockEntries.reverse());
  }

  function oldestButton() {
    setIsToggle(true);
    setStockEntries(stockEntries.reverse());
  }

  const handleSearch = () => {
    //for customers
    const customerDetails = stockData.stock_entries
      ? stockData.stock_entries.filter((item) =>
          item.entry_type.toLowerCase().includes(search.toLowerCase())
        )
      : [];
    setStockEntries(customerDetails);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 sticky top-0 z-10 bg-white shadow-sm w-[97%] md:w-[98%] rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
              Stock Details
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/inventory" className="no-underline">
                Inventory
              </NavLink>
              &nbsp; &gt; &nbsp; Stock Details
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search by ID..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-[1.5rem]">
          <p className="text-[#000000] text-[0.9rem] md:text-[1rem] lg:text-[1.3rem] font-semibold">
            Stock: {stockData.item_name}
          </p>
          <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 lg:grid-cols-4 sm:mr-3 mt-3">
            <div className="border-2 bg-[#3850BD] w-full flex justify-center items-center flex-col rounded-md lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem] mb-2 md:mb-0">
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem] mb-1">
                Sold
              </p>
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem]">
                {stockData.item_sold === 0 ? "NA" : stockData.item_sold}
              </p>
            </div>

            <div className="border-2 bg-[#BC8A0C] w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem]">
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem] mb-1">
                Returned
              </p>
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem]">
                {stockData.item_returned === 0 ? "NA" : stockData.item_returned}
              </p>
            </div>

            <div className="border-2 bg-[#529851] w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem]">
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem] mb-1">
                Purchased
              </p>
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem]">
                {stockData.item_purchased === 0
                  ? "NA"
                  : stockData.item_purchased}
              </p>
            </div>

            <div className="border-2 bg-[#CA5656] w-full flex justify-center items-center flex-col rounded-lg lg:rounded-xl h-[5rem] md:h-[6rem] lg:h-[9rem]">
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem] mb-1">
                Damaged
              </p>
              <p className="font-semibold text-[#fff] text-[1rem] lg:text-[1.2rem]">
                {stockData.item_damaged === 0 ? "NA" : stockData.item_damaged}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center w-[97%] md:w-[98%]">
          <div className="flex items-center">
            <button
              className={`${
                isToggle
                  ? "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
                  : "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
              }
              w-[4rem] mr-1 md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] md:text-xs xl:text-[1rem] rounded`}
              onClick={mostRecentButton}
            >
              Most Recent
            </button>

            <button
              className={`${
                isToggle
                  ? "bg-[#384B9D] text-[#FBFBFB] border border-[#384B9D]"
                  : "bg-[#FBFBFB] text-[#384B9D] border border-[#384B9D]"
              }
              w-[4rem] md:w-[6rem] xl:w-[7rem] font-semibold py-1 xl:py-2 px-1 text-[0.5rem] md:text-xs xl:text-[1rem] rounded`}
              onClick={oldestButton}
            >
              Oldest
            </button>
          </div>
          <div>
            <button
              onClick={() => navigate(`/addStockEntry/${id}`)}
              className="text-white border bg-[#384B9D] w-[1.8rem] lg:w-[2rem] text-[0.9rem] md:text-[1.2rem] rounded-md "
            >
              +
            </button>
          </div>
        </div>

        <div>
          <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 lg:grid-cols-3 sm:mr-3 mt-4 lg:mt-10">
            {stockEntries &&
              stockEntries.map((item, index) => {
                let formattedDate = dateTimeConvert(item.CreatedAt);
                return (
                  <div
                    key={index}
                    className="overflow-auto w-full h-[5rem] md:h-[5rem] lg:h-[6rem] shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] rounded-sm md:rounded-md p-2 lg:px-3"
                  >
                    <div className="flex justify-center  flex-col">
                      <div className="flex flex-col">
                        <p className="text-[#3850bd] font-semibold text-sm md:text-[1.2rem]">
                          {item.entry_type}
                        </p>
                        <p className="text-[#000000] text-[0.6rem] md:text-[0.8rem] leading-6">
                          {formattedDate}
                        </p>
                      </div>

                      <p className="text-[#3850bd] font-semibold text-[0.6rem] md:text-[0.7rem] lg:text-[0.9rem] lg:mt-[0.5rem]">
                        {item.entry_type === "purchased"
                          ? item.item_purchased
                          : item.item_sold} 
                        Units of &#x20B9; &nbsp;
                        {item.entry_type === "purchased"
                          ? item.purchased_value.toFixed(2)
                          : item.sold_value.toFixed(2)}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default StockDetails;
