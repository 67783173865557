import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { addScf } from "../../../services/Api";
import { useDataContext } from "../../../contexts/DataContext";

const Scf = () => {
  const [newProductData, setNewProductData] = useState([]);
  const [amount, setAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const { userDetailsId, selectedBusiness, setEditProductId } =
    useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    getTotalProduct();
  }, []);

  const saveScf = async () => {
    const reqData = {
      business_id: selectedBusiness.id ? selectedBusiness.id : 2,
      products: newProductData.map((item) => ({
        amount: item.amount,
        product_id: item.productId,
        product_name: item.productName,
        product_quantity: parseInt(item.quantity),
        unit_of_measurement: item.uom,
      })),
      scf_status: "pending",
      tenure: parseInt(tenure),
      amount: parseInt(amount),
      user_id: 2,
    };

    const data = await addScf(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      reqData
    );

    alert(
      "Thanks for your interest in supply chain finance. Our representative will get in touch with you soon."
    );

    navigate("/");
  };

  const editButton = (index) => {
    setEditProductId(index);
  };

  const deleteButton = (index) => {
    // Retrieve the array from session storage
    let dataArray = JSON.parse(sessionStorage.getItem("totalNewProduct"));

    // Check if dataArray exists and if the index is valid
    if (dataArray && dataArray.length > 0) {
      dataArray.splice(index, 1);

      // Update session storage with the modified array
      sessionStorage.setItem("totalNewProduct", JSON.stringify(dataArray));
    }

    getTotalProduct();
  };

  const getTotalProduct = () => {
    let storedData = sessionStorage.getItem("totalNewProduct");

    // Parse the stored data into a JavaScript object
    let parsedData = storedData ? JSON.parse(storedData) : null;

    if (parsedData) {
      setNewProductData(parsedData);
    }
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 mb-3">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-[1.2rem] md:text-2xl xl:text-3xl">
              SCF Form
            </h1>

            <p className="text-[#000000] text-[0.4rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline text-black">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/inventory" className="no-underline text-black">
                Apply for SCF
              </NavLink>
            </p>
          </div>

          <div className="relative mx-2 xl:mx-5 sm:mx-3 mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[6rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="bg-[#ededed] rounded-sm h-fit p-2 w-[97%] md:w-[98%]">
          <div className="grid gap-6 md:grid-cols-2 bg-[#ededed] rounded-md">
            <div>
              <label
                for="Amount"
                className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
              >
                Amount
              </label>
              <input
                type="text"
                id="Amount"
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            <div>
              <label
                for="Tenure"
                className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
              >
                Tenure
              </label>
              <input
                type="text"
                id="Tenure"
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Amount"
                value={tenure}
                onChange={(e) => setTenure(e.target.value)}
              />
            </div>
          </div>

          <div className="bg-[#fbfbfb] shadow-lg border border-gray-300 mb-4 rounded-md overflow-auto mt-7">
            <div className="flex items-center p-1 md:p-2 lg:p-3 border-b border-dotted border-[#999999]">
              <NavLink to="/editProduct" className="no-underline">
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[0.5rem] md:text-[0.7rem] lg:text-[0.8rem] px-1 md:px-2 lg:px-4 py-1 lg:py-2 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Add Items
                </button>
              </NavLink>
            </div>

            <div className="py-2 overflow-auto h-[5rem] md:h-[7rem]">
              <table className="w-full text-center">
                <tr className="bg-[#fffafa] sticky top-0">
                  <th className="text-[0.4rem] md:text-[0.7rem]  text-[#666666]">
                    Product
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    UOM
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Quantity
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem]  text-[#666666]">
                    Total Amount
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Edit
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Delete
                  </th>
                </tr>

                {newProductData.map((item, index) => (
                  <tr key={index}>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem] ">
                      {item.productName}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.uom}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.quantity}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.amount}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.6rem] lg:text-[0.7rem]">
                      <NavLink to="/editProduct" className="no-underline">
                        <button
                          onClick={() => editButton(index)}
                          className="w-fit rounded cursor-pointer px-[0.4rem] py-[0.1rem] text-[#ffffff] bg-[#4f61b1]"
                        >
                          Edit
                        </button>
                      </NavLink>
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.6rem] lg:text-[0.7rem]">
                      <button
                        onClick={() => deleteButton(index)}
                        className="w-fit rounded cursor-pointer px-[0.4rem] py-[0.1rem] text-[#ffffff] bg-[#4f61b1]"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={saveScf}
          >
            Apply for SCF
          </button>
        </div>
      </div>
    </>
  );
};

export default Scf;
