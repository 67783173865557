import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const HomeMiddleChart = ({ chartData, format }) => {
  const [series, setSeries] = useState([
    {
      name: "Product",
      data: [0, 0, 0, 0, 0],
    }
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      height: 200,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      //   colors: ['red', 'blue', 'orange', 'green', '#FF9800'],
      width: 2,
      // fill: {
      //     type: 'gradient'
      //   }
    },
    // title: {
    //   text: 'Fundamental Analysis of Stocks',
    //   align: 'left'
    // },
    // subtitle: {
    //   text: 'Price Movements',
    //   align: 'left'
    // },
    labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
    xaxis: {
      type: "category",
      //   categories: ["Jan", "Feb", "Mar", "Dec"],
      //   categories: [1991,1992,1993,1994,1995,1996,1997, 1998,1999]
    },
    // xaxis: {
    //   labels: {
    //     show: false,
    //   }
    // },
    // yaxis: {
    //   labels: {
    //     show: false,
    //   }
    // },
    // yaxis: {
    // //   opposite: true
    // },
    // legend: {
    // //   horizontalAlign: 'left'
    // }
  });

  useEffect(() => {
    console.log("chartData: ", chartData)
    if (format === "Weekly") {
      setSeries([
        {
          name: "Product Sold (₹)",
          data: chartData.map((item) => item.sum),
        },
        // {
        //   name: "Product",
        //   data: [10,20],
        // },
      ]);

      setOptions({
        chart: {
          type: "area",
          height: 200,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        labels: chartData.map((item) => `Week ${item.txn_week}`),
        xaxis: {
          type: "category",
        },
      });
    } else if (format === "Monthly") {
      setSeries([
        {
          name: "Product Sold (₹)",
          data: chartData.map((item) => item.sum),
        },
      ]);

      setOptions({
        chart: {
          type: "area",
          height: 200,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        labels: chartData.map((item) => {
          const monthIndex =
            parseInt(item.txn_month && item.txn_month.substring(5, 7)) - 1;
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return monthNames[monthIndex];
        }),
        xaxis: {
          type: "category",
        },
      });
    } else if (format === "Yearly") {
      setSeries([
        {
          name: "Product Sold (₹)",
          data: chartData.map((item) => item.sum),
        },
      ]);

      setOptions({
        chart: {
          type: "area",
          height: 200,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        labels: chartData.map((item) => {
          return item.txn_year && item.txn_year.substring(0, 4);
        }),
        xaxis: {
          type: "category",
        },
      });
    }
  }, [chartData, format]);

  return (
    <>
      <div>
        <div className="shadow-lg mt-2">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={200}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </>
  );
};

export default HomeMiddleChart;
