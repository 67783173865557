import React, { useEffect, useState } from "react";
// import { Rating } from "react-simple-star-rating";
import Rating from '@mui/material/Rating';
import ayekart from "../../../images/ayekart-default.png";
import star from '../../../images/Star 1.png'

const ItemCard = ({data}) => {
  const [rating, setRating] = useState(5);

  return (
    <>
      {data && <div className="partner-card">
        <img src={data.User.Shop.image_url ? data.User.Shop.image_url : ayekart} alt="icon" />
        <div style={{ padding: "0.1vw 0.5vw" }}>
          <p className="partner-card-txt1">{data.User.Shop.name}</p>
          <p className="partner-card-txt2">(Manufacturer)</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "0.2vw",
              marginBottom: "0.5vw",
            }}
          >
            {/* <Rating initialValue={rating} size={"1vw"} /> */}
            <Rating value={data.MerchantAvgRating.seller_rating} style={{fontSize: '1vw'}} name="read-only"/>
            {/* <img src={star} alt="" /> */}
            <p className="rating">({(data.MerchantAvgRating.seller_rating).toFixed(1)})</p>
          </div>
        </div>
      </div>}
    </>
  );
};

export default ItemCard;
