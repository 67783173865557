import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomeMiddleChart from "./HomeMiddleChart";
// import HomeMiddleCard from "./HomeMiddleCard";
import HomeMiddleCardHeading from "./HomeMiddleCardHeading";
import { useDataContext } from "../../../contexts/DataContext";
import Vector77 from "../../../images/Vector 77.png";
import Ellipse83 from "../../../images/Ellipse 83.png";
import Ellipse84 from "../../../images/Ellipse 84.png";
import Ellipse79 from "../../../images/Ellipse-79.png";
import Ellipse80 from "../../../images/Ellipse 80.png";
import { useNavigate } from "react-router-dom";
import {
  monthlyChartApi,
  weeklyChartApi,
  yearlyChartApi,
} from "../../../services/ChartApi";
import { getCustomerDetails, getVendorData } from "../../../services/Api";

const HomeMiddle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [collected, setCollected] = useState(0);
  const [to_collect, setTo_Collect] = useState(0);
  const [to_pay, setTo_Pay] = useState(0);
  const [paid, setPaid] = useState(0);
  const [search, setSearch] = useState("");
  const { setVendorData, setCustomerData, selectedBusiness, userDetailsId } =
    useDataContext();
  const [customerInfo, setCustomerInfo] = useState([]);
  const [vendorInfo, setVendorInfo] = useState([]);
  const [chartSelectedFormat, setChartSelectedFormat] = useState("Weekly");
  const [defaultValueDate, setDefaultValueDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(defaultValueDate);
  const [chartApiData, setChartApiData] = useState([]);

  useEffect(() => {
    getCustomerData();
    getVendorDetails();
    sessionStorage.removeItem("totalNewProduct");

    // getting current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Months are zero-based

    setDefaultValueDate(`${currentYear}-${currentMonth}`);
  }, []);

  useEffect(() => {
    setSelectedDate(defaultValueDate);
    //call chart API
    getWeeklyChartData(defaultValueDate);
  }, [defaultValueDate]);

  useEffect(() => {
    //call chart API
    if (chartSelectedFormat === "Weekly") getWeeklyChartData(selectedDate);
    else if (chartSelectedFormat === "Monthly")
      getMonthlyChartData(selectedDate);
    else if (chartSelectedFormat === "Yearly") getYearlyChartData();
  }, [chartSelectedFormat, selectedDate]);

  useEffect(() => {
    getCustomerData();
    getVendorDetails();
  }, [selectedBusiness.id]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const getCustomerData = async () => {
    const result = await getCustomerDetails(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );

    setCustomerInfo(result.data.customers);
    setCollected(result.data.collected);
    setTo_Collect(result.data.to_collect - result.data.collected);
    setCustomerData(result.data.customers);
  };

  const getVendorDetails = async () => {
    const result = await getVendorData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2
    );

    setVendorInfo(result.data.vendors);
    setPaid(result.data.paid);
    setTo_Pay(Math.abs(result.data.to_pay - result.data.paid));
    setVendorData(result.data.vendors);
  };

  const handleSearch = () => {
    //for customers
    const customerDetails =
      customerInfo.length > 0
        ? customerInfo.filter((item) =>
            item.first_name.toLowerCase().includes(search.toLowerCase())
          )
        : [];
    setCustomerData(customerDetails);

    // for vendors
    const vendorDetails =
      vendorInfo.length > 0
        ? vendorInfo.filter((item) =>
            item.first_name.toLowerCase().includes(search.toLowerCase())
          )
        : [];
    setVendorData(vendorDetails);
  };

  const getWeeklyChartData = async (date) => {
    if (date) {
      const dateString = date;
      const [year, month] = dateString.split("-");

      const chartData = await weeklyChartApi(
        selectedBusiness.id ? selectedBusiness.id : 2,
        month,
        year
      );

      setChartApiData(chartData ? chartData.Data : []);
    }
  };

  const getMonthlyChartData = async (date) => {
    if (date) {
      const dateString = date;
      const [year, month] = dateString.split("-");

      const chartData = await monthlyChartApi(
        selectedBusiness.id ? selectedBusiness.id : 2,
        year
      );

      setChartApiData(chartData ? chartData.Data : []);
    }
  };

  const getYearlyChartData = async () => {
    const chartData = await yearlyChartApi(
      selectedBusiness.id ? selectedBusiness.id : 2
    );

    setChartApiData(chartData ? chartData.Data : []);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-5 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] rounded-md px-1 ">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[0.9rem] md:text-2xl xl:text-3xl">
              Welcome{" "}
              {selectedBusiness.business_name
                ? selectedBusiness.business_name
                : "PGARTS"}
            </h1>

            <p className="text-[#000000] text-[0.35rem] min-[375px]:text-[0.4rem] min-[425px]:text-[0.5rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-semibold">
              Manage your finance, inventory and staff with hisab
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="w-[97%] md:w-[98%]">
          <div className="bg-gradient-to-r from-[#2c3d88] to-[#4355a4] flex  justify-between w-full h-[4rem] min-[400px]:h-[5rem] md:h-[6rem] lg:h-[8rem] xl:h-[9rem] mt-2 md:mt-4 rounded-md lg:rounded-lg">
            {/* <img src={Vector77} className="home-middle-details-img" alt="Icon" />
          <img src={Vector77} className="home-middle-details-img2" alt="Icon" />
          <img
            src={Ellipse83}
            className="home-middle-details-img3"
            alt="Icon"
          />
          <img
            src={Ellipse84}
            className="home-middle-details-img4"
            alt="Icon"
          />
          <img
            src={Ellipse84}
            className="home-middle-details-img5"
            alt="Icon"
          />
          <img
            src={Ellipse84}
            className="home-middle-details-img6"
            alt="Icon"
          />
          <img
            src={Ellipse79}
            className="home-middle-details-img7"
            alt="Icon"
          />
          <img
            src={Ellipse80}
            className="home-middle-details-img8"
            alt="Icon"
          /> */}

            <div className="text-center w-full flex flex-col justify-center items-center border-r-[0.01rem] border-[#989797]">
              <p className="text-[#ececec] text-[0.45rem] min-[375px]:text-[0.55rem] min-[425px]:text-[0.7rem] md:text-[0.8rem] lg:text-[1rem] xl:text-[1.2rem] font-bold mb-1">
                To Collect
              </p>
              <p className="text-[#ececec] text-[0.4rem] min-[375px]:text-[0.5rem] min-[425px]:text-[0.7rem] md:text-[0.9rem] lg:text-[1.2rem] xl:text-[1.3rem] font-bold ">
                &#x20B9; {Math.abs(to_collect).toFixed(2)}
                {to_collect < 0 && (
                  <sub className="text-[0.3rem] lg:text-[0.6rem]">(Adv)</sub>
                )}
              </p>
            </div>
            <div className="text-center w-full flex flex-col justify-center items-center border-r-[0.01rem] border-[#989797]">
              <p className="text-[#ececec] text-[0.45rem] min-[375px]:text-[0.55rem] min-[425px]:text-[0.7rem] md:text-[0.8rem] lg:text-[1rem] xl:text-[1.2rem] font-bold mb-1">
                Collected
              </p>
              <p className="text-[#ececec] text-[0.46rem] min-[375px]:text-[0.6rem] min-[425px]:text-[0.7rem] md:text-[0.9rem] lg:text-[1.2rem] xl:text-[1.3rem] font-bold">
                &#x20B9; {collected}
              </p>
            </div>
            <div className="text-center w-full flex flex-col justify-center items-center border-r-[0.01rem] border-[#989797]">
              <p className="text-[#ececec] text-[0.45rem] min-[375px]:text-[0.55rem] min-[425px]:text-[0.7rem] md:text-[0.8rem] lg:text-[1rem] xl:text-[1.2rem] min-[400px]:text-[0.55rem] font-bold mb-1">
                To Pay
              </p>
              <p className="text-[#ececec] text-[0.46rem] min-[375px]:text-[0.6rem] min-[425px]:text-[0.7rem] md:text-[0.9rem] min-[400px]:text-[0.6rem] lg:text-[1.2rem] xl:text-[1.3rem] font-bold">
                &#x20B9; {to_pay.toFixed(2)}
              </p>
            </div>
            <div className="text-center w-full flex flex-col justify-center items-center">
              <p className="text-[#ececec] text-[0.45rem] min-[375px]:text-[0.55rem] min-[425px]:text-[0.7rem] md:text-[0.8rem] lg:text-[1rem] xl:text-[1.2rem] min-[400px]:text-[0.55rem] font-bold mb-1">
                Paid
              </p>
              <p className="text-[#ececec] text-[0.46rem] min-[375px]:text-[0.6rem] min-[425px]:text-[0.7rem] md:text-[0.9rem] min-[400px]:text-[0.6rem] lg:text-[1.2rem] xl:text-[1.3rem] font-bold">
                &#x20B9; {paid.toFixed(2)}
              </p>
            </div>
          </div>
        </div>

        <div className="w-[97%] md:w-[98%]">
          <div className="flex justify-between items-center mt-3 lg:mt-4 mb-2 lg:mb-3">
            <p className="text-[0.8rem] lg:text-[1.2rem] font-medium text-[#000000]">
              Reports
            </p>

            <div className="flex">
              <div>
                <select
                  className="appearance-auto bg-[#384B9D] cursor-pointer text-[#FBFBFB] outline-0 text-center w-fit md:w-[5rem] xl:w-[6rem] font-semibold py-1 px-1 text-[0.5rem] lg:text-[0.8rem] md:text-xs xl:text-[1rem] hover:border-transparent rounded-lg"
                  value={chartSelectedFormat}
                  onChange={(e) => setChartSelectedFormat(e.target.value)}
                >
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>

              {chartSelectedFormat !== "Yearly" && (
                <div>
                  <input
                    type="month"
                    className="text-center cursor-pointer ml-1 sm:ml-3 w-[5rem] md:w-[8rem] xl:w-[10rem] text-[0.5rem] md:text-xs lg:text-[0.8rem] xl:text-[1rem] text-white bg-[#384B9D] rounded-lg px-1 py-[0.23rem] lg:py-[0.3rem] xl:py-[0.35rem]"
                    defaultValue={defaultValueDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Report chart */}
          <div>
            <HomeMiddleChart
              chartData={chartApiData}
              format={chartSelectedFormat}
            />
          </div>
        </div>

        {/* Customer Cards  */}
        <div>
          <HomeMiddleCardHeading />
        </div>
      </div>
    </>
  );
};

export default HomeMiddle;
