import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  customerDetailsApi,
  customerDetailsDateApi,
  vendorDetailsApi,
  vendorDetailsDateApi,
} from "../../../../services/Api";
import { useParams } from "react-router-dom";
import { useDataContext } from "../../../../contexts/DataContext";
import { dateTimeConvert } from "../../../../utilities/convertDate";

const ViewReportsVendor = () => {
  const { id } = useParams();
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [getDate, setGetDate] = useState("");
  const [getLastDate, setGetLastDate] = useState("");
  const [isEntries, setIsEntries] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const [customerDataWithDate, setCustomerDataWithDate] = useState([]);
  const [customerDataFilter, setCustomerDataFilter] = useState([]);
  const [customerDataWithDateFilter, setCustomerDataWithDateFilter] = useState(
    []
  );
  const [defaultFirstDate, setDefaultFirstDate] = useState("");
  const [defaultLastDate, setDefaultLastDate] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    getCustomerData();
    firstDay();
    lastDay();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    if (getDate && getLastDate) {
      getCustomerDataWithDate(getDate, getLastDate);
      setIsEntries(false);
    } else getCustomerDataWithDate(defaultFirstDate, defaultLastDate);
  }, [getDate, getLastDate, defaultFirstDate, defaultLastDate]);

  const getCustomerDataWithDate = async (from, to) => {
    if (from && to) {
      const data = await vendorDetailsDateApi(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        id,
        from,
        to
      );
      setCustomerDataWithDate(data.data.vendor_hisab);
      setCustomerDataWithDateFilter(data.data.vendor_hisab);
    }
  };

  const getCustomerData = async () => {
    const data = await vendorDetailsApi(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      id
    );
    setCustomerData(data.data.vendor_hisab ? data.data.vendor_hisab : []);
    setCustomerDataFilter(data.data.vendor_hisab);
  };

  const firstDay = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const year = firstDay.getFullYear();
    const month = String(firstDay.getMonth() + 1).padStart(2, "0");
    const day = String(firstDay.getDate()).padStart(2, "0");
    setDefaultFirstDate(`${year}-${month}-${day}`);
  };

  const lastDay = () => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const year = lastDay.getFullYear();
    const month = String(lastDay.getMonth() + 1).padStart(2, "0");
    const day = String(lastDay.getDate()).padStart(2, "0");
    setDefaultLastDate(`${year}-${month}-${day}`);
  };

  const handleSearch = () => {
    //filter customers data without date
    const customerDetails = customerDataFilter
      ? customerDataFilter.filter((item) =>
          item.item_name.toLowerCase().includes(search.toLowerCase())
        )
      : [];

    setCustomerData(customerDetails);

    //filter customers data with date
    const customerDetailsWithDate = customerDataWithDateFilter
      ? customerDataWithDateFilter.filter((item) =>
          item.item_name.toLowerCase().includes(search.toLowerCase())
        )
      : [];

    setCustomerDataWithDate(customerDetailsWithDate);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-6 sticky top-0 z-10 bg-white shadow-sm w-[97%] md:w-[98%] rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-lg md:text-2xl xl:text-3xl">
              View Report
            </h1>

            <p className="text-[#000000] text-[0.4rem] min-[350px]:text-[0.45rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/" className="no-underline">
                Customer Profile
              </NavLink>
              &nbsp; &gt; &nbsp; View Report
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-[1rem]">
          <input
            type="date"
            className="text-center w-[4rem] min-[375px]:w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.37rem]"
            onChange={(e) => setGetDate(e.target.value)}
          />

          <input
            type="date"
            className="text-center ml-1 sm:ml-3 w-[4rem] min-[375px]:w-[6rem] md:w-[8rem] xl:w-[10rem] text-[0.6rem] md:text-xs lg:text-[1rem] text-white bg-[#384B9D] rounded px-1 py-[0.2rem] lg:py-[0.37rem]"
            onChange={(e) => setGetLastDate(e.target.value)}
          />
        </div>

        <div className="flex items-center my-4">
          <input
            id="checked-checkbox"
            type="checkbox"
            value=""
            className="w-3 md:w-4 h-3 md:h-4 lg:w-5 lg:h-5 text-blue-600 bg-gray-100 border-gray-300 rounded "
            onClick={() => setIsEntries(!isEntries)}
            checked={isEntries}
          />
          <label
            for="checked-checkbox"
            className="ms-2 text-xs md:text-sm lg:text-[1rem] text-[#384B9D] dark:text-gray-300 font-semibold"
          >
            Show all entries
          </label>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-4 mb-6 md:grid-cols-2 lg:grid-cols-3 sm:mr-3 mt-4 lg:mt-10">
          {isEntries
            ? customerData &&
              customerData.map((item, index) => {
                let formattedDate = dateTimeConvert(item.CreatedAt);

                return (
                  <div
                    key={index}
                    className="overflow-auto w-full h-[5rem] md:h-[5.5rem] lg:h-[6rem] shadow-xl border-[#d9d8d8] border-[1px] rounded-sm md:rounded-md p-2 lg:px-3"
                  >
                    <div className="flex justify-center  flex-col">
                      <div className="flex flex-col">
                        <p className="text-[#3850bd] font-semibold text-sm md:text-[1rem]">
                          {item.item_name} ({item.quantity})
                        </p>
                        <p className="text-[#000000] text-[0.6rem] md:text-[0.8rem] leading-6 ">
                          {formattedDate}
                        </p>
                      </div>

                      <p className="text-[#3850bd] font-semibold text-[0.6rem] md:text-[0.7rem] lg:text-[0.9rem] md:mt-[0.3rem] lg:mt-[0.5rem]">
                        Collected: &#x20B9; {item.collected.toFixed(1)}
                      </p>
                    </div>
                  </div>
                );
              })
            : customerDataWithDate &&
              customerDataWithDate.map((item, index) => {
                let formattedDate = dateTimeConvert(item.CreatedAt);

                return (
                  <div
                    key={index}
                    className="overflow-auto w-full h-[5rem] md:h-[5.5rem] lg:h-[6rem] shadow-xl border-[#d9d8d8] border-[1px] rounded-sm md:rounded-md p-2 lg:px-3"
                  >
                    <div className="flex justify-center  flex-col">
                      <div className="flex flex-col">
                        <p className="text-[#3850bd] font-semibold text-sm md:text-[1rem]">
                          {item.item_name} ({item.quantity})
                        </p>
                        <p className="text-[#000000] text-[0.6rem] md:text-[0.8rem] leading-6 ">
                          {formattedDate}
                        </p>
                      </div>

                      <p className="text-[#3850bd] font-semibold text-[0.6rem] md:text-[0.7rem] lg:text-[0.9rem] md:mt-[0.3rem] lg:mt-[0.5rem]">
                        Collected: &#x20B9; {item.collected.toFixed(1)}
                      </p>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default ViewReportsVendor;
