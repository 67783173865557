import React, { useEffect, useState } from "react";
import OrderCards from "./OrderCards";
import {
  ordersCancelledData,
  ordersDeliveredData,
  ordersProcessingData,
} from "../../../services/Api";
import { NavLink } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import Pagination from "../pagination/Pagination";

const MyOrder = () => {
  const [attendance, setAttendance] = useState("Processing");
  const [ordersData, setOrdersData] = useState([]);
  const [search, setSearch] = useState("");
  const [handleSearchOrdersData, setHandleSearchOrdersData] = useState([]);
  const { setMyOrdersData, merchantData } = useDataContext();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    fetchingData();
  }, [attendance]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    setRecords(ordersData.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(ordersData.length / recordsPerPage));
    maxmLimitPage();
  }, [ordersData, firstIndex, lastIndex]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, ordersData.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(ordersData.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const fetchingData = async () => {
    if (attendance === "Processing") {
      getProcessingData();
    } else if (attendance === "Delivered") {
      getDeliveredData();
    } else if (attendance === "Cancelled") {
      getCancelledData();
    }
  };

  const getProcessingData = async () => {
    const data = await ordersProcessingData(merchantData.ID);
    setHandleSearchOrdersData(data["data"] ? data.data : []);
    setMyOrdersData(data["data"] ? data.data : []); //use for transfer data to order details page
    setOrdersData(data["data"] ? data.data : []);
  };

  const getDeliveredData = async () => {
    const data = await ordersDeliveredData(merchantData.ID);
    setHandleSearchOrdersData(data["data"] ? data.data : []);
    setMyOrdersData(data["data"] ? data.data : []); //use for transfer data to order details page
    setOrdersData(data["data"] ? data.data : []);
  };

  const getCancelledData = async () => {
    const data = await ordersCancelledData(merchantData.ID);
    setHandleSearchOrdersData(data["data"] ? data.data : []);
    setMyOrdersData(data["data"] ? data.data : []); //use for transfer data to order details page
    setOrdersData(data["data"] ? data.data : []);
  };

  const handleSearch = () => {
    // Filter the data based on the user's search.
    const filteredData =
      handleSearchOrdersData.length > 0
        ? handleSearchOrdersData.filter((item) => item.ID === parseInt(search))
        : [];

    if (filteredData.length > 0) setOrdersData(filteredData);
    else setOrdersData(handleSearchOrdersData);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 sticky top-0 z-10 bg-white shadow-sm w-[97.5%] md:w-[98.5%] px-1 rounded-md">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
              My Orders
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp; My Orders
            </p>
          </div>

          <div className="relative mb-1">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[5rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search by ID..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-4">
          <select
            className="appearance-auto bg-[#384B9D] cursor-pointer text-[#FBFBFB] outline-0 text-center w-fit font-semibold py-1 px-1 text-[0.6rem] lg:text-[0.8rem] md:text-xs xl:text-[1rem] hover:border-transparent rounded-md"
            value={attendance}
            onChange={(e) => setAttendance(e.target.value)}
          >
            <option value="Processing">Processing</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 md:grid-cols-2 xl:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-4">
          {records.map((item, index) => {
            return (
              <OrderCards orderData={item} key={index} status={attendance} />
            );
          })}
        </div>
      </div>

      {/* pagination */}
      <Pagination
        prevPage={prevPage}
        changePage={changePage}
        nextPage={nextPage}
        numbers={numbers}
        currentPage={currentPage}
      />
    </>
  );
};

export default MyOrder;
