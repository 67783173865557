import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [customerData, setCustomerData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [productData, setProductData] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [userDetailsId, setUserDetailsId] = useState({});
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [selectedPerson, setSelectedPerson] = useState({});
  const [editProductId, setEditProductId] = useState(-1);
  const [inventoryProductDetails, setInventoryProductDetails] = useState([]);
  const [staffDetails, setStaffDetails] = useState([]);
  const [scfProductsData, setScfProductsData] = useState([]);
  const [myOrdersData, setMyOrdersData] = useState([]);
  const [merchantData, setMerchantData] = useState({});
  const [isShowCards, setIsShowCards] = useState(true);

  return (
    <DataContext.Provider
      value={{
        customerData,
        setCustomerData,
        vendorData,
        setVendorData,
        productData,
        setProductData,
        productDetails,
        setProductDetails,
        userDetailsId,
        setUserDetailsId,
        selectedBusiness,
        setSelectedBusiness,
        selectedPerson,
        setSelectedPerson,
        editProductId,
        setEditProductId,
        inventoryProductDetails,
        setInventoryProductDetails,
        setStaffDetails,
        staffDetails,
        scfProductsData,
        setScfProductsData,
        myOrdersData,
        setMyOrdersData,
        merchantData,
        setMerchantData,
        isShowCards,
        setIsShowCards,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
