import React from "react";
import offers from "../../../images/offers.svg";
import { NavLink } from "react-router-dom";

const Offers = () => {
  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7 mb-3 xl:mb-5">
          <div>
            <h1 className="text-[#0e3293] font-semibold text-[1.2rem] md:text-2xl xl:text-3xl">
              Offers
            </h1>

            <p className="text-[#000000] text-[0.5rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline text-black">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/" className="no-underline text-black">
                Shop
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/" className="no-underline text-black">
                Offers
              </NavLink>
            </p>
          </div>
        </div>

        <div className="flex flex-col h-[70vh] bg-slate-200 w-[97%] mt-3 rounded">
          <div className=" flex items-center justify-center h-screen">
            <img
              src={offers}
              alt="icon"
              className="w-20 h-20 md:h-28 md:w-28"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Offers;
