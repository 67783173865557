import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const FinanceChart = ({ chartValue }) => {
  const [series, setSeries] = useState([
    {
      name: "Product",
      data: [0, 0, 0, 0, 0],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      height: 200,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    labels: [
      "2024-03-01",
      "2024-03-08",
      "2024-03-15",
      "2024-03-22",
      "2024-03-29",
    ],
    xaxis: {
      type: "datetime",
    },
  });

  useEffect(() => {
    if (chartValue.length > 0) {
      setSeries([
        {
          name: `Collected Amount (₹)`,
          data: chartValue.map((item) => item.collected_money),
        },
        {
          name: "Paid Amount (₹)",
          data: chartValue.map((item) => item.money_given),
        },
      ]);

      setOptions({
        chart: {
          type: "area",
          height: 200,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        colors: ["#008FFB", "#233951"],
        labels: chartValue.map((item) => {
          return item.date_time.substring(0, 10);
        }),
        xaxis: {
          type: "date",
        },
      });
    }
  }, [chartValue]);

  return (
    <>
      <div>
        <div className="shadow-lg mt-2">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={300}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </>
  );
};

export default FinanceChart;
