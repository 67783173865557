import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function fetchProduct(businessId) {
  const url = `/ayekart/api/hisab/product/get_product_byBusinessId?business_id=${businessId}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
  }
}

export async function fetchSignAndLogo(businessId) {
  const url = `/ayekart/api/hisab/invoices/get_sign&logo?business_id=${businessId}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
  }
}

export async function fetchOrderDetails(merchantId, orderId) {
    const url = `/order/merchant/${merchantId}/orders/received?except_status=CANCELLED&text=${orderId}`;
  
    try {
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  }
