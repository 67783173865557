import React, { useEffect, useState } from "react";
import profileImg from "../../../images/profile-photo.png";
// import dropDown from "../../images/dropdown.png";
import myOrder from "../../../images/my-order.png";
import offers from "../../../images/offers.png";
import sendMsg from "../../../images/send-msg.png";
import scf from "../../../images/scf.png";
import businessCard from "../../../images/business-card.png";
import offerPercent from "../../../images/offer%.png";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Draggable from "react-draggable";
import { useDataContext } from "../../../contexts/DataContext";
import { NavLink, useNavigate } from "react-router-dom";
import ayekart from "../../../images/ayekart-default.png";
import { getAllBusinesses } from "../../../services/Api";

const HomeRight = () => {
  const [isBusinesses, setIsBusinesses] = useState(false);
  const [businesses, setBusinesses] = useState();
  const {
    setSelectedBusiness,
    selectedBusiness,
    userDetailsId,
    isShowCards,
    setIsShowCards,
  } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    getBusinesses();
  }, []);

  const getBusinesses = async () => {
    const data = await getAllBusinesses(userDetailsId.data.ID);
    setBusinesses(data.data.businesses);
  };

  const selectBusiness = (data) => {
    setSelectedBusiness(data);
    setIsBusinesses(!isBusinesses);
  };

  return (
    <>
      <div className="fixed md:w-[9.5rem] lg:w-[12rem] xl:w-[14rem]">
        <div className="flex justify-between items-center p-[0.5rem] mt-[1rem]">
          <div className="flex items-center">
            <img
              src={
                selectedBusiness.business_image_url
                  ? selectedBusiness.business_image_url
                  : ayekart
              }
              className="w-[1.7rem] lg:w-[2.3rem] h-[1.7rem] lg:h-[2.3rem] mr-1 lg:mr-2 rounded-full"
              alt="icon"
            />
            <div>
              <p className="text-[#0e3293] font-bold text-sm lg:text-[1.1rem]">
                {selectedBusiness.business_name
                  ? selectedBusiness.business_name
                  : "PGARTS"}
              </p>
              <p
                className="text-[0.5rem] lg:text-[0.7rem] text-[#000000] cursor-pointer"
                onClick={() => navigate("/profile")}
              >
                View Profile
              </p>
            </div>

            {isBusinesses && (
              // <Draggable>
              <div className="absolute bg-[#e6e5e5] z-10 w-[7.5rem] lg:w-[9.5rem] xl:w-[12.5rem] h-[15rem] xl:h-[17rem] overflow-auto shadow-xl mx-1 p-2 mt-[18rem] xl:mt-[21rem] rounded-md">
                <div className="text-center sticky top-0">
                  <NavLink to="/createBusinessAccount" className="no-underline">
                    <button className="text-white bg-blue-700 cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md w-full md:text-[0.6rem] lg:text-[0.7rem] xl:text-[0.8rem] px-[0.2rem] py-1 lg:py-3 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                      Create Business Account
                    </button>
                  </NavLink>
                </div>

                <div className="mt-1">
                  {businesses.map((item, index) => (
                    <div className="flex items-center mb-3">
                      <img
                        src={
                          item.business_image_url
                            ? item.business_image_url
                            : ayekart
                        }
                        className="w-[0.9rem] lg:w-[1.4rem] h-[0.9rem] lg:h-[1.4rem] mr-1 lg:mr-2 rounded-full"
                        alt="icon"
                      />
                      <p
                        key={index}
                        onClick={() => selectBusiness(item)}
                        className="text-[#3850BD] font-semibold hover:text-[#303b77] cursor-pointer text-sm xl:text-[1.1rem] "
                      >
                        {item.business_name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              // </Draggable>
            )}
          </div>
          {/* <img src={dropDown} className="drop-down-image" alt="icon" onClick={()=>setIsBusinesses(!isBusinesses)} /> */}
          {isBusinesses ? (
            <ExpandLessIcon
              className="text-[#706e6e] cursor-pointer"
              onClick={() => setIsBusinesses(!isBusinesses)}
            />
          ) : (
            <ExpandMoreIcon
              className="text-[#706e6e] cursor-pointer"
              onClick={() => setIsBusinesses(!isBusinesses)}
            />
          )}
        </div>

        <div className="flex flex-col pl-[1rem] mt-8 lg:mt-10">
          <div
            className="flex items-center mb-3 lg:mb-5 cursor-pointer"
            onClick={() => {
              setIsShowCards(false);
              navigate("/myOrder");
            }}
          >
            <img
              src={myOrder}
              className="w-[0.8rem] lg:w-[1.2rem] h-fit"
              alt="icon"
            />

            <p className="text-[#3850BD] font-bold ml-2 lg:mx-3 text-[0.75rem] lg:text-[1rem]">
              My Orders
            </p>
          </div>
          <div
            className="flex items-center mb-3 lg:mb-5 cursor-pointer"
            onClick={() => {
              setIsShowCards(false);
              navigate("/offers");
            }}
          >
            <img
              src={offers}
              className="w-[0.8rem] lg:w-[1.2rem] h-fit"
              alt="icon"
            />

            <p className="text-[#3850BD] font-bold ml-2 lg:mx-3 text-[0.75rem] lg:text-[1rem]">
              Offers
            </p>
          </div>
          <div className="flex items-center mb-3 lg:mb-5">
            <img
              src={sendMsg}
              className="w-[0.8rem] lg:w-[1.2rem] h-fit"
              alt="icon"
            />
            <p className="text-[#3850BD] font-bold ml-2 lg:mx-3 text-[0.75rem] lg:text-[1rem]">
              Send Message
            </p>
          </div>
          <div
            className="flex items-center mb-3 lg:mb-5 cursor-pointer"
            onClick={() => {
              setIsShowCards(false);
              navigate("/scfRequest");
            }}
          >
            <img
              src={scf}
              className="w-[0.8rem] lg:w-[1.2rem] h-fit"
              alt="icon"
            />

            <p className="text-[#3850BD] font-bold ml-2 lg:mx-3 text-[0.75rem] lg:text-[1rem]">
              Apply for SCF
            </p>
          </div>
        </div>

        {isShowCards && (
          <div className="fixed bottom-0 mx-3 lg:mx-4">
            <div className="flex justify-center items-center flex-col">
              <div className="home-right-card1 mb-4 w-[7.5rem] lg:w-[10rem] xl:w-[12rem] h-[7rem] lg:h-[10rem] xl:h-[10rem] py-2 flex justify-center items-center flex-col rounded-md">
                <img
                  src={businessCard}
                  className="w-[3rem] lg:w-[5rem] "
                  alt="icon"
                />
                <p className="text-[#fbfbfb] font-semibold text-[0.9rem] lg:text-[1.1rem] xl:text-[1.2rem] mt-2">
                  Business Card
                </p>
                <p className="text-[#fbfbfb]  text-[0.4rem] lg:text-[0.5rem] xl:text-[0.5rem]">
                  Create your business card
                </p>
              </div>
              <div className="home-right-card1 mb-4 w-[7.5rem] lg:w-[10rem] xl:w-[12rem] h-[7rem] lg:h-[10rem] xl:h-[10rem] py-2 flex justify-center items-center flex-col rounded-md">
                <img
                  src={offerPercent}
                  className="w-[3rem] lg:w-[5rem]"
                  alt="icon"
                />
                <p className="text-[#fbfbfb] font-semibold text-[0.9rem] lg:text-[1.1rem] xl:text-[1.2rem] mt-2">
                  Offers
                </p>
                <p className="text-[#fbfbfb]  text-[0.4rem] lg:text-[0.5rem] xl:text-[0.5rem]">
                  Send offers to your customers
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeRight;
