import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSaleData, downloadInvoiceData } from "../../../services/Api";

const CreateSale = () => {
  const [isChecked, setIsChecked] = useState(true);
  const [saleAmount, setSaleAmount] = useState("");
  const {
    productData,
    productDetails,
    userDetailsId,
    selectedBusiness,
    setEditProductId,
  } = useDataContext();
  const [isPaymentStatus, setIsPaymentStatus] = useState(true);
  const [newProductData, setNewProductData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let storedData = sessionStorage.getItem("totalNewProduct");

    // Parse the stored data into a JavaScript object
    let parsedData = storedData ? JSON.parse(storedData) : null;

    if (parsedData) {
      setNewProductData(parsedData);
    }

    getTotalProduct();
  }, []);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handlePaymentStatusPaid = () => {
    setIsPaymentStatus(true);
  };

  const handlePaymentStatusUnPaid = () => {
    setIsPaymentStatus(false);
  };

  const saveSale = async () => {
    if (saleAmount) {
      const reqBody = {
        business_id: selectedBusiness.id ? selectedBusiness.id : 2,
        payment_status: isPaymentStatus ? "Paid" : "Unpaid",
        products: newProductData.map((item) => ({
          amount: parseInt(saleAmount),
          product_id: item.productId,
          product_name: item.productName,
          product_quantity: parseInt(item.quantity),
          unit_of_measurement: item.uom,
        })),
        total_amount: newProductData.reduce((sum, item) => {
          sum = sum + item.amount;
          return sum;
        }, 0),
        customer_id: parseInt(id),
      };

      const data = await createSaleData(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        reqBody
      );
      
      if (isChecked) {
        createInvoice();
        navigate("/invoiceHistory");
      }
    } else {
      toast.error("Please complete all mandatory fields.", {
        position: "top-center",
      });
    }
  };

  //creating invoice
  const createInvoice = async () => {
    let currentDate = new Date();
    let formattedDate = currentDate.toISOString();

    const reqData = {
      amount_in_words: "",
      balance_due: 0,
      business_name:
        userDetailsId.data && userDetailsId.data.business.business_name,
      company_logo_image_url:
        "https://ayekart-mobile.sfo2.digitaloceanspaces.com/hisabinvoices/2sign1640319754266",
      customer_address: "NA",
      customer_mobile: userDetailsId.data && userDetailsId.data.mobile,
      customer_name:
        userDetailsId.data &&
        `${userDetailsId.data.first_name} ${userDetailsId.data.last_name}`,
      date_time: formattedDate,
      gst_reg_no: "",
      image_url: "test",
      InvoiceItems: [
        {
          business_id: productData.Data && productData.Data.business_id,
          cess_amount:
            productData.Data && parseInt(productData.Data.cess_amount),
          cess_percentage:
            productData.Data && parseInt(productData.Data.cess_percentage),
          description: productDetails.description,
          gst: productData.Data && parseInt(productData.Data.gst),
          new_unit_price:
            productData.Data && parseInt(productData.Data.buying_price),
          product_image_url: "test",
          product_service_name:
            productData.Data && productData.Data.product_name,
          quantity: parseInt(productDetails.quantity),
          unit: productData.Data && parseInt(productData.Data.buying_price),
        },
      ],
      pan_number: "",
      payment_type: "online",
      received: 0,
      retailer_signature:
        "https://ayekart-mobile.sfo2.digitaloceanspaces.com/hisabinvoices/2sign1658251422994",
      state_code: "",
      state_of_supply: "MH",
      tax: 0,
      tax_type: "Gst",
      template_id: 1,
      total_amount: newProductData.reduce((sum, item) => {
        sum = sum + item.amount;
        return sum;
      }, 0),
    };

    const data = await downloadInvoiceData(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      reqData
    );
  };

  const editButton = (index) => {
    setEditProductId(index);
  };

  const getTotalProduct = () => {
    let storedData = sessionStorage.getItem("totalNewProduct");

    // Parse the stored data into a JavaScript object
    let parsedData = storedData ? JSON.parse(storedData) : null;

    if (parsedData) {
      setNewProductData(parsedData);
    }
  };

  const deleteButton = (index) => {
    // Retrieve the array from session storage
    let dataArray = JSON.parse(sessionStorage.getItem("totalNewProduct"));

    // Check if dataArray exists and if the index is valid
    if (dataArray && dataArray.length > 0) {
      // Remove the item at index 1 (for example)
      dataArray.splice(index, 1);

      // Update session storage with the modified array
      sessionStorage.setItem("totalNewProduct", JSON.stringify(dataArray));
    }

    getTotalProduct();
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
              Create Sale
            </h1>

            <p className="text-[#000000] text-[0.38rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp; Create Sale
            </p>
          </div>
        </div>

        <div className="mt-3 lg:mt-5 bg-[#EDEDED] border border-gray-100 shadow-md w-[97%] md:w-[98%] rounded-md p-2 lg:p-4">
          <div>
            <label
              for="Purchased Quantity"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Total sale Amount in Rs*
            </label>
            <input
              type="number"
              id="Purchased Quantity"
              className="bg-gray-50 mb-4 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Amount"
              value={saleAmount}
              onChange={(e) => setSaleAmount(e.target.value)}
            />
          </div>

          <div className="bg-[#fbfbfb] shadow-lg border border-gray-300 mb-4 rounded-md overflow-auto">
            <div className="flex items-center p-1 md:p-2 lg:p-3 border-b border-dotted border-[#999999]">
              <NavLink to="/editProduct" className="no-underline">
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[0.5rem] md:text-[0.7rem] lg:text-[0.8rem] px-1 md:px-2 lg:px-4 py-1 lg:py-2 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Add Items
                </button>
              </NavLink>
            </div>

            <div className="py-2 overflow-auto h-[5rem] md:h-[7rem] ">
              <table className="w-full text-center">
                <tr className="bg-[#fffafa] sticky top-0">
                  <th className="text-[0.4rem] md:text-[0.7rem]  text-[#666666]">
                    Product
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    UOM
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Quantity
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem]  text-[#666666]">
                    Total Amount
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Edit
                  </th>
                  <th className="text-[0.4rem] md:text-[0.7rem] text-[#666666]">
                    Delete
                  </th>
                </tr>

                {newProductData.map((item, index) => (
                  <tr key={index}>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem] ">
                      {item.productName}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.uom}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.quantity}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.7rem]">
                      {item.amount}
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.6rem] lg:text-[0.7rem]">
                      <NavLink to="/editProduct" className="no-underline">
                        <button
                          onClick={() => editButton(index)}
                          className="w-fit rounded cursor-pointer px-[0.4rem] py-[0.1rem] text-[#ffffff] bg-[#4f61b1]"
                        >
                          Edit
                        </button>
                      </NavLink>
                    </td>
                    <td className="p-[0.5rem] z-10 text-[0.4rem] md:text-[0.6rem] lg:text-[0.7rem]">
                      <button
                        onClick={() => deleteButton(index)}
                        className="w-fit rounded cursor-pointer px-[0.4rem] py-[0.1rem] text-[#ffffff] bg-[#4f61b1]"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>

          <div className="grid gap-6 mb-6 md:grid-cols-2 w-full">
            <div>
              <div className=" bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-3 lg:p-[1.1rem] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <div className="flex justify-between items-center">
                  <p className="text-xs font-medium text-[#384B9D] dark:text-white">
                    Create Invoice Entry
                  </p>
                  <label className="amount-slider-container ">
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      onClick={handleToggle}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-3 lg:p-[1.1rem] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <p className="text-[0.7rem] md:text-xs font-medium text-[#384B9D] dark:text-white">
                  Payment Status*
                </p>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="HTML"
                    className="mr-1 w-[0.5rem] md:w-[0.7rem]"
                    onClick={handlePaymentStatusPaid}
                  />
                  <label className="font-medium text-[0.6rem] md:text-xs mr-1 md:mr-2 ">
                    Paid
                  </label>
                  <input
                    type="radio"
                    id="css"
                    name="fav_language"
                    value="CSS"
                    className="mr-1 w-[0.5rem] md:w-[0.7rem]"
                    onClick={handlePaymentStatusUnPaid}
                  />
                  <label className="font-medium text-[0.6rem] md:text-xs">
                    Unpaid
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={saveSale}
          >
            Save Sale
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateSale;
