import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { updateSalary } from "../../../services/Api";

const getCurrentMonthFirstDay = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    .toISOString()
    .split("T")[0];
};

const getCurrentMonthLastDay = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  return lastDay.toISOString().split("T")[0];
};

const SalarySettings = () => {
  const [getSelectedUser, setGetSelectedUser] = useState({});
  const [getDate, setGetDate] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const { staffDetails, selectedBusiness, userDetailsId } = useDataContext();
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(()=>{
    setMonthlySalary(getSelectedUser.staff_salary)
  }, [getSelectedUser])

  useEffect(() => {
    const filterData = staffDetails.filter((item) => item.ID === parseInt(id));
    setGetSelectedUser(filterData[0]);
  }, []);

  const update = async () => {
    const reqData = {
      business_id: getSelectedUser.business_id,
      day_of_salary: parseInt(getDate.split("-")[2]),
      full_name: getSelectedUser.full_name,
      mobile_number: getSelectedUser.mobile_number,
      opening_balance_advance: 0,
      opening_balance_pending: 0,
      profile_image_URL: getSelectedUser.profile_image_URL,
      salary_cycle: getSelectedUser.salary_cycle,
      staff_salary: parseInt(monthlySalary),
      type_of_salary_payment: getSelectedUser.type_of_salary_payment
    };

    const data = await updateSalary(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      id,
      reqData
    );
    // setStaffDetails(data.data);
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="customer-container">
          <div>
            <h1 className="home-middle-heading">Salary Settings</h1>
          </div>
        </div>

        <div className="staff-profile-data">
          <div>
            <p className="product-label">Monthly Salary of Staff</p>
            <input
              type="text"
              className="staff-text-box"
              placeholder="Enter Product Name"
              value={monthlySalary}
              onChange={(e)=>setMonthlySalary(e.target.value)}
            />
          </div>

          <div style={{ marginBottom: "2vw" }}>
            <p className="customer-label">Salary Cycle</p>
            <input
              type="date"
              className="staff-salary-cycle"
              placeholder="1 of every month"
              min={getCurrentMonthFirstDay()}
              max={getCurrentMonthLastDay()}
              value={getDate}
              onChange={(e) => setGetDate(e.target.value)}
            />
          </div>
        </div>

        <div className="product-button">
          <button className="customer-left-button" onClick={cancel}>
            Cancel
          </button>
          <button className="customer-right-button" onClick={update}>
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default SalarySettings;
