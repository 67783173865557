import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  formatDateAndTime,
  getCurrentDate,
  getCurrentTime,
} from "../../../../../utilities/convertDate";
import { hisabEntryVendor } from "../../../../../services/Api";
import { useDataContext } from "../../../../../contexts/DataContext";

const Paid = ({ customerId }) => {
  const [date, setDate] = useState("");
  const [collect, setCollect] = useState("");
  const [time, setTime] = useState("");
  const { selectedBusiness, userDetailsId } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    setTime(getCurrentTime());
    setDate(getCurrentDate());
  }, []);

  const saveData = async () => {
    const formattedDateTime = formatDateAndTime(date, time);

    const stockEntryData = {
      collected: 0.0,
      created_at: formattedDateTime,
      entry_type: "paid",
      image_url: [],
      item_name: "Amount Paid",
      paid: parseFloat(collect),
      to_collect: 0.0,
      to_pay: 0.0,
    };

    const createEntry = await hisabEntryVendor(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      customerId,
      stockEntryData
    );

    if (createEntry) navigate(-1);
  };

  const closePage = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4 w-[97%] md:w-[98%]">
          <div>
            <label
              for="Expiry Date (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Date*
            </label>
            <input
              type="date"
              id="Expiry Date (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="1 of every month"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Expiry Date (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Time*
            </label>
            <input
              type="time"
              id="Expiry Date (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>

          <div>
            <label
              for="UPC Code (Optional)"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Amount*
            </label>
            <input
              type="text"
              id="UPC Code (Optional)"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Amount"
              value={collect}
              onChange={(e) => setCollect(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={closePage}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-4 lg:px-6 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={saveData}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default Paid;
