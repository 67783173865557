import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../utilities/firebase";
import { Outlet, useNavigate } from "react-router-dom";
import "../styles/home.css";
import HomeRight from "../components/home-components/Home/HomeRight";
import HomeLeft from "../components/home-components/Home/HomeLeft";
import { useDataContext } from "../contexts/DataContext";
import { ThreeDots } from "react-loader-spinner";
import { getUserDetails, getUserDetailsWithId } from "../services/Api";
import { getMerchantDetails } from "../services/LoginApi";

const Home = () => {
  const navigate = useNavigate();
  const [userPhnNo, setUserPhnNo] = useState("");
  const [userId, setUserId] = useState("");
  const { setUserDetailsId, userDetailsId, merchantData, setMerchantData } = useDataContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    validUser();
  }, []);

  useEffect(() => {
    if (userPhnNo)
    {
      fetchUserDetails();
      fetchMerchantDetails();
    }
  }, [userPhnNo]);

  useEffect(() => {
    if (userId) fetchUserDetailsWithId();
  }, [userId]);

  const validUser = () => {
    const valueFirebase = JSON.parse(localStorage.getItem("userFirebase"));
    const valueOtp = JSON.parse(localStorage.getItem("userOtp"));

    if (valueFirebase || valueOtp) {
      if (valueOtp) setUserPhnNo(parseInt(valueOtp.data.mobile_number));
      if (valueFirebase)
        setUserPhnNo(parseInt(valueFirebase.email.substring(0, 10)));
    } else {
      navigate("/login");
    }
  };

  const fetchUserDetails = async () => {
    const data = await getUserDetails(userPhnNo);
    setUserId(data.data.ID);
  };

  const fetchMerchantDetails = async () => {
    const data = await getMerchantDetails(userPhnNo);
    setMerchantData(data.data);
  };

  const fetchUserDetailsWithId = async () => {
    const data = await getUserDetailsWithId(userId);

    if (data) {
      setUserDetailsId(data);
      setLoading(false);
    }
  };

  if (loading) {
    // return <div className="flex justify-center items-center h-[100vh]">Loading...</div>; // Render loading indicator while user details are loading
    return (
      <ThreeDots
        visible={true}
        height="80"
        width="50"
        color="#00000"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        wrapperClass=""
      />
    );
  }

  return (
    <>
      <div className="flex h-full">
        <div className="w-[5rem] md:w-[8rem] lg:w-[9rem]">
          <HomeLeft />
        </div>
        <div className="w-full">
          <Outlet />
        </div>
        <div className="w-[13rem] lg:w-[17rem] xl:w-[18rem] shadow-md hidden md:block">
          <HomeRight />
        </div>
      </div>
    </>
  );
};

export default Home;
