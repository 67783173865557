import React, { useEffect, useState } from "react";
import { addStaff } from "../../../services/Api";
import axios from "axios";
import { useDataContext } from "../../../contexts/DataContext";
import { NavLink, useNavigate } from "react-router-dom";

const getCurrentMonthFirstDay = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    .toISOString()
    .split("T")[0];
};

const getCurrentMonthLastDay = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  return lastDay.toISOString().split("T")[0];
};

const AddStaff = () => {
  const [staffName, setStaffName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const [imageUrlRetailer, setImageUrlRetailer] = useState("");
  const [getDate, setGetDate] = useState("");
  const { selectedBusiness, userDetailsId } = useDataContext();
  const navigate = useNavigate();

  const handleFileChangeRetailer = (event) => {
    const selectedFile = event.target.files[0];
    getImageUrlRetailer(selectedFile);
  };

  const getImageUrlRetailer = (e) => {
    const formValue = new FormData();
    formValue.append("file", e);
    const url = "/api/mitra-merchant/doc-upload";
    axios
      .post(url, formValue)
      .then((response) => {
        setImageUrlRetailer(response.data.file_location);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createStaff = async () => {
    const reqData = {
      business_id: selectedBusiness.id ? selectedBusiness.id : 2,
      day_of_salary: parseInt(getDate.split("-").pop()),
      full_name: staffName,
      mobile_number: mobileNo,
      opening_balance_advance: 0,
      opening_balance_pending: 0,
      profile_image_URL: imageUrlRetailer,
      salary_cycle: 30,
      staff_salary: parseInt(monthlySalary),
      type_of_salary_payment: "Monthly",
    };

    const data = await addStaff(
      userDetailsId.data.ID,
      selectedBusiness.id ? selectedBusiness.id : 2,
      reqData
    );

    if (data) navigate(-1);
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <div>
            <h1 className="text-[#0e3293] font-medium text-xl md:text-2xl xl:text-3xl">
              Add Staff
            </h1>

            <p className="text-[#000000] text-[0.4rem] md:text-[0.7rem] xl:text-[0.8rem] mx-[0.1rem] font-medium">
              <NavLink to="/" className="no-underline">
                Home
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/staff" className="no-underline">
                Staff
              </NavLink>
              &nbsp; &gt; &nbsp;
              <NavLink to="/addStaff" className="no-underline">
                Add Staff
              </NavLink>
            </p>
          </div>

          <div className="relative mx-2 xl:mx-5 sm:mx-3 mb-2">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 md:ps-4 pointer-events-none">
              <svg
                className="w-2 h-2 md:w-3 md:h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none focus:ring-blue-500 focus:border-blue-500 w-[6rem] md:w-[10rem] xl:w-[12rem] text-[0.5rem] md:text-[0.7rem] xl:text-[0.9rem] py-1 sm:py-2 ps-5 md:ps-9 text-gray-900 border border-gray-300 rounded-md shadow-2xl"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div>
            <label
              for="Staff Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Staff Name*
            </label>
            <input
              type="text"
              id="Staff Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Staff Name"
              value={staffName}
              onChange={(e) => setStaffName(e.target.value)}
            />
          </div>
          <div>
            <label
              for="Mobile Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Mobile Number*
            </label>
            <input
              type="number"
              id="Mobile Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="123-45-678"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
            />
          </div>
          <div>
            <label
              for="Type of Salary Payment"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Type of Salary Payment
            </label>
            <input
              type="text"
              id="Type of Salary Payment"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Salary Payment"
              value="Monthly"
              readOnly
            />
          </div>
          <div>
            <label
              for="Monthly Salary"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Monthly Salary*
            </label>
            <input
              type="text"
              id="Monthly Salary"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Amount"
              value={monthlySalary}
              onChange={(e) => setMonthlySalary(e.target.value)}
            />
          </div>
          <div>
            <label
              for="Salary Cycle"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Salary Cycle
            </label>
            <input
              type="date"
              id="Salary Cycle"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-[0.8rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              min={getCurrentMonthFirstDay()}
              max={getCurrentMonthLastDay()}
              value={getDate}
              onChange={(e) => setGetDate(e.target.value)}
            />
          </div>
          <div>
            <label
              for="Profile Picture"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Upload Profile Picture
            </label>
            <input
              type="file"
              id="Profile Picture"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.6rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={handleFileChangeRetailer}
            />
          </div>
        </div>

        <div className="flex justify-end items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <button
            type="button"
            className="text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={cancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={createStaff}
          >
            Add Staff
          </button>
        </div>
      </div>
    </>
  );
};

export default AddStaff;
