import React, { useEffect } from "react";
import { dateConvert } from "../../../utilities/convertDate";
import { useNavigate } from "react-router-dom";

const OrderCards = ({ orderData, status }) => {
  const navigate = useNavigate();

  useEffect(()=>{
    console.log("orderData: ", orderData)
  }, [])

  return (
    <div className="shadow-xl border-2 border-gray-200 border-l-4 border-l-[#3850BD] w-full overflow-auto h-fit p-2 xl:p-3">
      <div className="flex justify-between items-center">
        <p className="text-[#000000] text-sm lg:text-lg font-semibold">
          Order No: {orderData.ID}
        </p>
        <p className="text-[#384B9D] text-[0.6rem] lg:text-[0.8rem] font-semibold">
          {dateConvert(orderData.CreatedAt)}
        </p>
      </div>

      <div className="mt-2">
        <p className="text-[#384B9D] text-[0.6rem] lg:text-[0.8rem] font-semibold">
          <span className="text-[#3A3A3A]">Total Amount:</span> &nbsp; Rs.
          {orderData.total_amount}
        </p>
        <p className="text-[#384B9D] text-[0.6rem] lg:text-[0.8rem] font-semibold">
          <span className="text-[#3A3A3A]">Quantity:</span> &nbsp;{" "}
          {orderData.OrderItems.reduce((sum, item) => {
            sum = sum + item.new_quantity;
            return sum;
          }, 0)}
        </p>
        <p className="text-[#384B9D] text-[0.6rem] lg:text-[0.8rem] font-semibold">
          <span className="text-[#3A3A3A]">Order Status:</span> &nbsp; 
          {orderData.order_status}
        </p>
      </div>

      <div className="flex items-center justify-end">
        <button
          type="button"
          className="text-white bg-[#384b9d] hover:bg-blue-800 focus:ring-4 font-medium rounded-md text-[0.6rem] md:text-[0.7rem] xl:text-[0.8rem] px-2 md:px-2 lg:px-4 py-1 "
          onClick={() => navigate(`/orderDetails/${orderData.ID}/${status}`)}
        >
          Details
        </button>
      </div>
    </div>
  );
};

export default OrderCards;
