import React, { useEffect, useState } from "react";
import {
  customerReportsApi,
  stockReportsApi,
} from "../../../../services/ChartApi";
import { useDataContext } from "../../../../contexts/DataContext";
import { dateTimeConvert } from "../../../../utilities/convertDate";

const CustomerReport = ({
  defaultFirstDate,
  defaultLastDate,
  from,
  to,
  search,
}) => {
  const { selectedBusiness, userDetailsId } = useDataContext();
  const [customerData, setCustomerData] = useState([]);
  const [isEntries, setIsEntries] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [stockData, setStockData] = useState([]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    getCustomerReportsData(defaultFirstDate, defaultLastDate);
  }, [defaultFirstDate, defaultLastDate]);

  useEffect(() => {
    getCustomerReportsData(from, to);
  }, [from, to]);

  const getCustomerReportsData = async (firstDate, lastDate) => {
    if (firstDate && lastDate) {
      const data = await customerReportsApi(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2,
        firstDate,
        lastDate
      );
      setFilterData(data["data"] ? data.data : []);
      setCustomerData(data["data"] ? data.data : []);
    }
  };

  const handleSearch = () => {
    //filter stock data
    const stockData =
      filterData.length > 0
        ? filterData.filter((item) =>
            item.first_name.toLowerCase().includes(search.toLowerCase())
          )
        : [];
    setCustomerData(stockData);
  };

  return (
    <div>
      <div>
        <div className="flex items-center mb-4">
          <input
            id="checked-checkbox"
            type="checkbox"
            value=""
            className="w-3 md:w-4 h-3 md:h-4 lg:w-5 lg:h-5 text-blue-600 bg-gray-100 border-gray-300 rounded "
            onClick={() => setIsEntries(!isEntries)}
          />
          <label
            for="checked-checkbox"
            className="ms-2 text-xs md:text-sm lg:text-[1rem] text-[#384B9D] dark:text-gray-300 font-semibold"
          >
            Show all entries
          </label>
        </div>
      </div>
      <div className="relative overflow-auto">
        <table className="w-full text-[0.6rem] md:text-[0.8rem] lg:text-[1rem]">
          <thead className="bg-[#384B9D] text-[#FFFFFF] font-semibold">
            <tr>
              <th scope="col" className="p-1 md:p-2">
                CUSTOMER
              </th>
              <th scope="col" className="p-1 md:p-2">
                ENTRY TYPE
              </th>
              <th scope="col" className="p-1 md:p-2">
                AMOUNT
              </th>
            </tr>
          </thead>
          {isEntries ? (
            <tbody>
              <tr className="bg-[#F3F3F3] border-b text-center">
                <th
                  scope="row"
                  className="p-1 md:p-2 text-gray-900 whitespace-nowrap font-bold"
                >
                  {customerData[0].first_name} {customerData[0].last_name}
                  <p className="text-[0.5rem] md:text-[0.6rem] font-normal">
                    {dateTimeConvert(customerData[0].CreatedAt)}
                  </p>
                  <p className="text-[0.5rem] md:text-[0.6rem] font-normal">
                    {customerData[0].item_name}
                  </p>
                </th>
                <td className="p-1 md:p-2">
                  {customerData[0].entry_type === "collected"
                    ? "Collected"
                    : "To Collect"}
                </td>
                <td className="p-1 md:p-2 font-semibold">
                  {" "}
                  &#x20B9; {customerData[0].collected.toFixed(1)}
                </td>
              </tr>
            </tbody>
          ) : (
            customerData.map((item, index) => (
              <tbody key={index}>
                <tr className="bg-[#F3F3F3] border-b text-center">
                  <th
                    scope="row"
                    className="p-1 md:p-2 text-gray-900 whitespace-nowrap font-bold"
                  >
                    {item.first_name} {item.last_name}
                    <p className="text-[0.5rem] md:text-[0.6rem] font-normal">
                      {dateTimeConvert(item.CreatedAt)}
                    </p>
                    <p className="text-[0.5rem] md:text-[0.6rem] font-normal">
                      {item.item_name}
                    </p>
                  </th>
                  <td className="p-1 md:p-2">
                    {item.entry_type === "collected"
                      ? "Collected"
                      : "To Collect"}
                  </td>
                  <td className="p-1 md:p-2 font-semibold">
                    {" "}
                    &#x20B9; {item.collected.toFixed(1)}
                  </td>
                </tr>
              </tbody>
            ))
          )}
        </table>
      </div>
    </div>
  );
};

export default CustomerReport;
