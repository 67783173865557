import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDataContext } from "../../../contexts/DataContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getDataWithId,
  getInventoryStockData,
  merchantProductData,
} from "../../../services/Api";

const EditProduct = () => {
  const [productName, setProductName] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [GST, setGST] = useState("");
  const [cessAmount, setCessAmount] = useState(0.0);
  const [cessPercentage, setCessPercentage] = useState(0.0);
  const [uom, setUom] = useState("");
  const [quantity, setQuantity] = useState();
  const [totalAmount, setTotalAmount] = useState("");
  const [description, setDescription] = useState("");
  const [fetchProducts, setFetchProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [slectedProductId, setSelectedProductId] = useState("");
  const {
    editProductId,
    setEditProductId,
    selectedBusiness,
    userDetailsId,
    merchantData,
  } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductData();
    autofillData();
  }, []);

  useEffect(() => {
    if (quantity && unitPrice)
      setTotalAmount((parseInt(quantity) * parseInt(unitPrice)).toFixed(2));
  }, [quantity]);

  const autofillData = () => {
    let existingData = sessionStorage.getItem("totalNewProduct");

    // Parse existing data (if any) into a JavaScript object, or initialize an empty array if no data exists
    let dataArray = existingData ? JSON.parse(existingData) : [];

    if (dataArray.length > 0 && editProductId >= 0) {
      setProductName(dataArray[editProductId].productName);
      setUnitPrice(dataArray[editProductId].selling_price);
      setCessAmount(dataArray[editProductId].cess_amount);
      setGST(dataArray[editProductId].gst);
      setDescription(dataArray[editProductId].description);
      setTotalAmount(dataArray[editProductId].amount);
      setQuantity(dataArray[editProductId].quantity);
    }
  };

  const fetchProductData = async () => {
    try {
      const data1 = await merchantProductData(merchantData.ID);

      const data2 = await getInventoryStockData(
        userDetailsId.data.ID,
        selectedBusiness.id ? selectedBusiness.id : 2
      );

      // Merge the data from both responses
      const mergedData = [...data1.data, ...data2.data];

      setFetchProducts(mergedData);
      setFilteredProducts(mergedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = fetchProducts.filter(
      (product) =>
        product.item_name &&
        product.item_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleChange = (e) => {
    setProductName(e.target.value);
    handleSearch(e.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 1000);
  };

  const fetchDataWithId = async (id) => {
    const data = await getDataWithId(
      selectedBusiness.id ? selectedBusiness.id : 2
    );

    const selectedData = data.Data.filter((item) => item.id === id);

    if (selectedData[0]) {
      setUnitPrice(selectedData[0].selling_price);
      setGST(selectedData[0].gst);
      setCessPercentage(selectedData[0].cess_percentage);
      setUom(selectedData[0].measurement_unit);
    } else {
      setUnitPrice("");
      setGST("");
      setCessPercentage("");
      setUom("");
    }
  };

  const selectedProduct = (id) => {
    const selectedData = filteredProducts.filter(
      (item) => item.product_id === id
    );
    setProductName(selectedData[0].item_name);
    setUom(selectedData[0].measurement_unit);

    fetchDataWithId(id);

    setSelectedProductId(id);
  };

  const updateProduct = () => {
    const checkProduct = filteredProducts.filter(
      (item) => item.item_name === productName
    );

    // if product is available in the list then only update the data
    if (checkProduct.length > 0) {
      // Retrieve existing data from sessionStorage
      let existingData = sessionStorage.getItem("totalNewProduct");

      // Parse existing data (if any) into a JavaScript object, or initialize an empty array if no data exists
      let dataArray = existingData ? JSON.parse(existingData) : [];

      // Check if dataArray has an item at index 0
      if (dataArray.length > 0 && editProductId >= 0) {
        // Update existing item at index 0
        dataArray[editProductId] = {
          productName: productName,
          quantity: quantity,
          uom: uom,
          amount: parseInt(totalAmount),
          cess_amount: parseInt(cessAmount),
          cess_percentage: parseInt(cessPercentage),
          gst: GST,
          selling_price: unitPrice,
          description: description,
          productId: slectedProductId,
        };
      } else {
        // Create a new data object
        let newData = {
          productName: productName,
          quantity: quantity,
          uom: uom,
          amount: parseInt(totalAmount),
          cess_amount: parseInt(cessAmount),
          cess_percentage: parseInt(cessPercentage),
          gst: GST,
          selling_price: unitPrice,
          description: description,
          productId: slectedProductId,
        };

        // Add the new data to the array
        dataArray.push(newData);
      }

      // Store updated data back to sessionStorage
      sessionStorage.setItem("totalNewProduct", JSON.stringify(dataArray));

      if (productName) {
        toast.success("Add product successfully", {
          position: "top-center",
        });
        navigate(-1);
      }

      setEditProductId(-1);
    } else {
      toast.error("Product not found in the list. Please add a new product.", {
        position: "top-center",
      });
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mt-7">
          <h1 className="text-[#0e3293] font-medium text-[1rem] md:text-2xl xl:text-3xl">
            Edit Product
          </h1>
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-4">
          <div>
            <p className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white">
              Product Name*
            </p>
            <div>
              <input
                type="text"
                className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Product Name"
                value={productName}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>

            {isFocused && (
              <div className="absolute z-10 bg-[#CBCCCB] h-[5rem] w-[72%] md:w-[35%] lg:w-[36%] rounded-md p-2 overflow-auto">
                {filteredProducts.map((product, index) => (
                  <div key={index}>
                    <p
                      key={index}
                      className="font-medium text-[0.7rem] leading-6 cursor-pointer hover:text-gray-600"
                      onClick={() => selectedProduct(product.product_id)}
                    >
                      {product.item_name}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <label
              for="Quantity"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Quantity*
            </label>
            <input
              type="text"
              id="Quantity"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Unit Price"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Unit Price (Incl. GST)*
            </label>
            <input
              type="text"
              id="Unit Price"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Unit Price"
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
            />
          </div>

          <div>
            <label
              for="GST"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              GST*
            </label>
            <input
              type="text"
              id="GST"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="GST"
              value={GST}
              onChange={(e) => setGST(e.target.value)}
            />
          </div>

          <div>
            <label
              for="GST"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Total Cess Amount*
            </label>
            <input
              type="text"
              id="GST"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Cess Amount"
              value={cessAmount}
              onChange={(e) => setCessAmount(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Total Amount"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Total Amount*
            </label>
            <input
              type="text"
              id="Total Amount"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Amount"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
            />
          </div>

          <div>
            <label
              for="Description"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Description (Optional)
            </label>
            <input
              type="text"
              id="Description"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Describe Product"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between items-center xl:mr-3 sm:mr-1 mb-4 mt-7">
          <div>
            <NavLink to="/addNewProduct" className="no-underline">
              <button
                type="button"
                className="text-white bg-[#3850BD] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-2 md:px-3  lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Add New Product
              </button>
            </NavLink>
          </div>

          <div className="flex">
            <button
              type="button"
              className="hidden md:block text-white bg-[#CA5656] hover:bg-[#b34545] focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={cancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="text-white bg-[#3850BD] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs md:text-sm px-2 md:px-3 lg:px-4 py-2 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={updateProduct}
            >
              Add Product
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
