import React, { useEffect, useState } from "react";
import cardImg from "../../../images/Card-img.png";
import invoice from "../../../images/invoice.png";
import entry from "../../../images/entry.png";
import sale from "../../../images/sale.png";
import collect from "../../../images/collect.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useDataContext } from "../../../contexts/DataContext";
import { NavLink } from "react-router-dom";
import Pagination from "../pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ayekart from "../../../images/ayekart-default.png";


const HomeMiddleCard = () => {
  const { customerData, setSelectedPerson } = useDataContext();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const [records, setRecords] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    setRecords(customerData.slice(firstIndex, lastIndex));
    setNoOfPages(Math.ceil(customerData.length / recordsPerPage));
    maxmLimitPage();
  }, [customerData, firstIndex, lastIndex]);

  useEffect(() => {
    setNumbers([...Array(noOfPages + 1).keys()].slice(1));
    maxmLimitPage();
  }, [noOfPages]);

  useEffect(() => {
    maxmLimitPage();
  }, [currentPage, customerData.length, recordsPerPage]);

  const maxmLimitPage = () => {
    const totalPages = Math.ceil(customerData.length / recordsPerPage);
    const maxPagesToShow = 3; // Adjust this value to show 2 buttons at a time
    let minPage;
    let maxPage;

    if (totalPages <= maxPagesToShow) {
      minPage = 1;
      maxPage = totalPages;
    } else if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      minPage = 1;
      maxPage = maxPagesToShow;
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
      minPage = totalPages - maxPagesToShow + 1;
      maxPage = totalPages;
    } else {
      minPage = currentPage - Math.floor(maxPagesToShow / 2);
      maxPage = currentPage + Math.floor(maxPagesToShow / 2);
    }

    const pagesToShow = [];
    for (let i = minPage; i <= maxPage; i++) {
      pagesToShow.push(i);
    }

    setNumbers(pagesToShow);
  };

  const select = (item) => {
    setSelectedPerson(item);
  };

  const nextPage = () => {
    if (currentPage !== lastIndex)
      setCurrentPage(currentPage < noOfPages ? currentPage + 1 : noOfPages);
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const prevPage = () => {
    if (currentPage !== firstIndex)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  return (
    <>
      <div className="grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 mr-2 xl:mr-5 sm:mr-3 mt-4">
        {records.length > 0 &&
          records.map((item, index) => (
            <div
              className="shadow-lg border-2 border-gray-200 border-l-4 border-l-[#3850BD] p-2 w-full"
              key={index}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={item.profile_image_url ? item.profile_image_url : ayekart}
                    className="w-[1.5rem] h-[1.5rem] lg:w-[1.8rem] lg:h-[1.8rem] rounded-md "
                    alt="icon"
                  />
                  <NavLink
                    to={`/customerProfile/${item.ID}`}
                    className="no-underline"
                  >
                    <p className="mx-2 text-[#384B9D] text-[0.9rem] lg:text-[1rem] font-semibold">
                      {item.first_name} {item.last_name}
                    </p>
                  </NavLink>
                </div>

                <LocalPhoneIcon
                  className="border border-[#384b9d] rounded-full text-[#384b9d] p-1 cursor-pointer"
                  title={item.mobile}
                  onClick={() => {
                    toast.info(`Mobile No: ${item.mobile}`, {
                      position: "top-center",
                    });
                  }}
                />
              </div>

              <div className="flex justify-around items-center mt-2">
                <div>
                  <p className="text-[0.6rem] lg:text-[0.9rem] text-[#666666] font-semibold  text-center">
                    To Collect
                  </p>
                  <p className="text-[0.6rem] lg:text-[0.9rem] text-[#000000] font-semibold text-center">
                    {Math.abs(item.to_collect - item.collected).toFixed(2)}
                    {item.to_collect - item.collected < 0 && (
                      <sub className="text-[0.3rem] lg:text-[0.6rem]">(Adv)</sub>
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-[0.6rem] lg:text-[0.9rem] text-[#666666] font-semibold  text-center">
                    Collected
                  </p>
                  <p className="text-[0.6rem] lg:text-[0.9rem] text-[#000000] font-semibold text-center">
                    {item.collected.toFixed(2)}
                  </p>
                </div>
              </div>

              <div className="flex justify-around items-center mt-2">
                <div>
                  <NavLink to="/createInvoice" className="no-underline">
                    <button className="bg-[#e0e0e0] hover:bg-[#bfbebe] flex justify-center items-center p-1 xl:p-2 md:py-2 rounded-md w-[6rem] xl:w-[8rem] mb-2">
                      <img
                        src={invoice}
                        className="w-[0.5rem] h-[0.5rem] md:w-[0.7rem] md:h-[0.7rem] xl:w-[0.8rem] xl:h-[0.8rem] ml-1"
                        alt="icon"
                      />
                      <p className="mx-1 text-[0.6rem]  xl:text-[0.7rem]">
                        Create Invoice
                      </p>
                    </button>
                  </NavLink>

                  <NavLink
                    to={`/createSale/${item.ID}`}
                    className="no-underline"
                  >
                    <button className="bg-[#e0e0e0] hover:bg-[#bfbebe] flex justify-center items-center p-1 xl:p-2  md:py-2 rounded-md w-[6rem] xl:w-[8rem] mb-2">
                      <img
                        src={sale}
                        className="w-[0.5rem] h-[0.5rem] md:w-[0.7rem] md:h-[0.7rem] xl:w-[0.8rem] xl:h-[0.8rem] ml-1"
                        alt="icon"
                      />
                      <p className="mx-1 text-[0.6rem] xl:text-[0.7rem]">
                        Create Sale
                      </p>
                    </button>
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to={`/addEntry-customer/${item.ID}`}
                    className="no-underline"
                  >
                    <button className="bg-[#e0e0e0] hover:bg-[#bfbebe] flex justify-center items-center p-1 xl:p-2 md:py-2 rounded-md w-[6rem] xl:w-[8rem] mb-2">
                      <img
                        src={entry}
                        className="w-[0.5rem] h-[0.5rem] md:w-[0.7rem] md:h-[0.7rem] xl:w-[0.8rem] xl:h-[0.8rem] ml-1"
                        alt="icon"
                      />
                      <p className="mx-1 text-[0.6rem] xl:text-[0.7rem]">
                        Add Entry
                      </p>
                    </button>
                  </NavLink>

                  <NavLink to="/requestAmount" className="no-underline">
                    <button
                      className="bg-[#e0e0e0] hover:bg-[#bfbebe] flex justify-center items-center p-1 xl:p-2 md:py-2 rounded-md w-[6rem] xl:w-[8rem] mb-2"
                      onClick={() => select(item)}
                    >
                      <img
                        src={collect}
                        className="w-[0.5rem] h-[0.5rem] md:w-[0.7rem] md:h-[0.7rem] xl:w-[0.8rem] xl:h-[0.8rem] ml-1"
                        alt="icon"
                      />
                      <p className="mx-1 text-[0.6rem] xl:text-[0.7rem]">
                        Collect Now
                      </p>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* pagination */}
      <Pagination
        prevPage={prevPage}
        changePage={changePage}
        nextPage={nextPage}
        numbers={numbers}
        currentPage={currentPage}
      />
    </>
  );
};

export default HomeMiddleCard;
