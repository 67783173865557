import React, { useEffect } from "react";
import ayekart from "../../../images/ayekart-default.png";

const OrderDetailsCard = ({ filteredData }) => {
  return (
    <div>
      <div className="w-[97%] md:w-[98%] grid gap-6 mb-6 mr-2 xl:mr-5 sm:mr-3 mt-6">
        {filteredData.length > 0 &&
          filteredData[0].OrderItems.map((item, index) => (
            <div
              key={index}
              className="flex justify-center flex-col shadow-xl border border-[#d9d8d8] w-full overflow-auto h-[6rem] md:h-[7rem] lg:h-[9.5rem] p-2 py-4 md:px-5 lg:p-6"
            >
              <div className="flex justify-between">
                <div className="flex items-center">
                  <img
                    src={
                      item.product.product_images[0]
                        ? item.product.product_images[0]
                        : ayekart
                    }
                    className="w-[3.5rem] h-[3.5rem] md:w-[4.5rem] md:h-[4.5rem] lg:w-[5.3rem] lg:h-[5.3rem] mr-2 md:mr-3 rounded-md"
                    alt="icon"
                  />
                  <div>
                    <p className="text-[#3850BD] text-[0.8rem] md:text-[1.2rem] lg:text-[1.5rem] font-bold">
                      {item.product.product_name}
                    </p>
                    <p className="text-[0.6rem] md:text-[0.8rem] lg:text-[0.9rem] text-[#000000] font-semibold mb-1">
                      Rs. {item.product.buying_price}/{item.product.pack_size}{" "}
                      {item.product.measurement_unit}
                    </p>
                    <p className="text-[0.7rem] md:text-[0.9rem] lg:text-[1.1rem] text-[#000000] font-semibold">
                      Quantity: {item.product.new_min_order_qty}{" "}
                      {item.product.measurement_unit}
                    </p>
                  </div>
                </div>
                <p className="text-[#384B9D] font-bold text-[0.8rem] md:text-[1rem] lg:text-[1.5rem]">
                  Rs.{" "}
                  {item.product.buying_price * item.product.new_min_order_qty}
                </p>
              </div>
            </div>
          ))}
      </div>

      <div>
        <p className="text-[#384B9D] font-bold text-[1rem] md:text-[1.3rem] lg:text-[1.5rem]">
          Order Details
        </p>
        <div className="grid gap-6 mb-6 md:grid-cols-2 mr-2 xl:mr-5 sm:mr-3 mt-3 bg-[#ededed] p-[1rem] lg:p-[2rem] rounded-md">
          <div>
            <label
              for="Business Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Shipping Address
            </label>
            <input
              type="text"
              id="Business Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={
                filteredData.length > 0 ? filteredData[0].shipping_address : ""
              }
              disabled
            />
          </div>

          <div>
            <label
              for="Customer Name"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Order Status
            </label>
            <input
              type="text"
              id="Customer Name"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={
                filteredData.length > 0 ? filteredData[0].order_status : ""
              }
              disabled
            />
          </div>

          <div>
            <label
              for="Billing Address"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Payment Method:
            </label>
            <input
              type="text"
              id="Billing Address"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={
                filteredData.length > 0 ? filteredData[0].payment_method : ""
              }
              disabled
            />
          </div>

          <div>
            <label
              for="Shipping Address"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Payment Status
            </label>
            <input
              type="text"
              id="Shipping Address"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={
                filteredData.length > 0 ? filteredData[0].payment_status : ""
              }
              disabled
            />
          </div>

          <div>
            <label
              for="State/ UT Code"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Reward Amount
            </label>
            <input
              type="text"
              id="State/ UT Code"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={
                filteredData.length > 0 ? filteredData[0].reward_amount : ""
              }
              disabled
            />
          </div>

          <div>
            <label
              for="Mobile Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Total Amount
            </label>
            <input
              type="text"
              id="Mobile Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={
                filteredData.length > 0 ? filteredData[0].total_amount : ""
              }
              disabled
            />
          </div>

          <div>
            <label
              for="PAN Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Cess Amount
            </label>
            <input
              type="text"
              id="PAN Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={filteredData.length > 0 ? filteredData[0].cess_amount : ""}
              disabled
            />
          </div>

          <div>
            <label
              for="GST Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Discount
            </label>
            <input
              type="text"
              id="GST Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={filteredData.length > 0 ? filteredData[0].discount : ""}
              disabled
            />
          </div>

          <div>
            <label
              for="Lorry Number"
              className="block mb-2 text-sm font-medium text-[#384B9D] dark:text-white"
            >
              Order Total
            </label>
            <input
              type="text"
              id="Lorry Number"
              className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 lg:py-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={
                filteredData.length > 0 ? filteredData[0].total_amount : ""
              }
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsCard;
