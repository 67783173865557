import React from "react";
import logo from "../../images/logo.png";
import "../../styles/login.css";
import Subtract from "../../images/Subtract.png";
import Group from "../../images/Group.png";
import Group123 from "../../images/Group 123.png";
import Subtract2 from "../../images/Subtract-2.png";

const AyekartLogo = () => {
  return (
    <>
      <div className="w-full relative">
        <img
          src={Subtract}
          className="w-[4rem] md:w-[5rem] xl:w-[7rem] h-fit absolute"
          alt="Icon"
        />
        <img
          src={Group123}
          className="absolute top-[15%] xl:top-[8rem] right-0 w-[3.5rem] md:w-[4rem] xl:w-[5rem] h-fit"
          alt="Icon"
        />
        <img
          src={Group123}
          className="absolute top-[70%] w-[3.5rem] md:w-[4rem] xl:w-[5rem] h-fit"
          alt="Icon"
        />
        <img
          src={Subtract2}
          className="absolute top-[80%] w-[5rem] md:w-[6rem] xl:w-[8rem] h-fit right-[-2rem]"
          alt="Icon"
        />

        <div className="flex items-center h-screen mx-1 justify-center">
          <img src={logo} className="w-fit h-fit" alt="Icon" />
        </div>
      </div>
    </>
  );
};

export default AyekartLogo;
