import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const StockChart = ({ chartValue }) => {
  const [series, setSeries] = useState([
    {
      data: [0, 0, 0, 0],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 200,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Sold", "Returned", "Purchased", "Damaged"],
    },
  });

  useEffect(() => {
    const soldSum = chartValue.reduce(
      (accumulator, currentValue) => accumulator + currentValue.item_sold,
      0
    );

    const returnedSum = chartValue.reduce(
      (accumulator, currentValue) => accumulator + currentValue.item_returned,
      0
    );

    const purchasedSum = chartValue.reduce(
      (accumulator, currentValue) => accumulator + currentValue.item_purchased,
      0
    );

    const damagedSum = chartValue.reduce(
      (accumulator, currentValue) => accumulator + currentValue.item_damaged,
      0
    );

    setSeries([
      {
        data: [soldSum, returnedSum, purchasedSum, damagedSum],
      },
    ]);

    setOptions({
      chart: {
        type: "bar",
        height: 200,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#384B9D"],
      xaxis: {
        categories: ["Sold", "Returned", "Purchased", "Damaged"],
      },
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              height: 200,
            },
            plotOptions: {
              bar: {
                borderRadius: 3,
              },
            },
          },
        },
      ],
    });
    //     setSeries([
    //       {
    //         name: "Collected Amount",
    //         data: chartValue.map((item) => item.collected_money),
    //       }
    //     ]);
    //     setOptions({
    //       chart: {
    //         type: "area",
    //         height: 200,
    //         zoom: {
    //           enabled: false,
    //         },
    //       },
    //       dataLabels: {
    //         enabled: false,
    //       },
    //       stroke: {
    //         curve: "straight",
    //         width: 2,
    //       },
    //       labels: chartValue.map((item) => {
    //         return item.date_time.substring(0, 10);
    //       }),
    //       xaxis: {
    //         type: "date",
    //       },
    //     });
  }, [chartValue]);

  return (
    <>
      <div>
        <div className="shadow-lg mt-2">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={300}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </>
  );
};

export default StockChart;
