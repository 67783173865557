import React, { useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../utilities/firebase";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import "../../styles/login.css";
import Vector78 from "../../images/Vector 78.png";
import Ellipse79 from "../../images/Ellipse 79.png";
import Ellipse86 from "../../images/Ellipse 86.png";
import Ellipse84 from "../../images/Ellipse 84.png";
import Ellipse83 from "../../images/Ellipse 83.png";
import LoginBoxOtp from "./LoginBoxOtp";
import LoginBoxPassword from "./LoginBoxPassword";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dispatchOtp, generateOtp } from "../../services/LoginApi";

const LoginBox = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [isToggleButton, setIsToggleButton] = useState(false);
  const [storePassword, setStorePassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [userPhnNo, setUserPhnNo] = useState("");
  const [createOtpData, setCreateOtpData] = useState({});

  useEffect(() => {
    sentOtp();
  }, [otp]);

  function toggleLoader(data) {
    setIsLoader(data);
  }

  const sentOtp = async () => {
    const data = await dispatchOtp(userPhnNo, otp);
  };

  const createOTP = async () => {
    const reqBody = {
      mobile_number: number,
    };

    const data = await generateOtp(reqBody);
    let mobileNo = "91" + number;
    setOtp(data.data.otp);
    setUserPhnNo(mobileNo);
    setCreateOtpData(data);
  };

  const forgotPassword = async () => {
    //take the number as a email id
    // let email = `${number.substring(4)}@example.com`;
    // await sendPasswordResetEmail(auth, email);
    // console.log("Password reset email sent")
  };

  const onLogin = (e) => {
    e.preventDefault();

    //take the number as a email id
    let email = `${number}@example.com`;

    if (!storePassword) {
      toast.error("Please complete all fields.", {
        position: "top-center",
      });
    } else {
      signInWithEmailAndPassword(auth, email, storePassword)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // store user details in local storage
          localStorage.setItem("userFirebase", JSON.stringify(user));

          // make null login with otp
          localStorage.setItem("userOtp", JSON.stringify(""));

          setIsLoader(true);

          toast.success("Login Successful!", {
            position: "top-center",
          });

          // Delay 1 sec because of showing loader - for better User Experience
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((error) => {
          setIsLoader(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error("Invalid credentials!", {
            position: "top-center",
          });
          console.log(errorCode, errorMessage);
        });
    }
  };

  function getPassword(data) {
    setStorePassword(data);
  }

  const userOTP = () => {
    setIsToggleButton(!isToggleButton);
  };

  const userPassword = () => {
    setIsToggleButton(!isToggleButton);
  };

  return (
    <>
      <div className="relative h-screen flex justify-center items-center">
        <img
          src={Vector78}
          className="absolute inset-0 w-full h-auto top-[4%] "
          alt="icon"
        />
        <img
          src={Vector78}
          className="absolute inset-0 w-full h-auto top-[8%] "
          alt="icon"
        />
        <img
          src={Ellipse79}
          className="absolute inset-0 w-[1rem] xl:w-[1.2rem] h-auto top-[2%] md:top-[3%] lg:top-[4%] xl:top-[5%]"
          alt="icon"
        />
        <img
          src={Ellipse86}
          className="absolute w-[1.8rem] xl:w-[2.5rem] h-auto top-[5%] xl:top-[6%] right-0"
          alt="icon"
        />
        <img
          src={Ellipse84}
          className="absolute w-[0.8rem] xl:w-[1rem] h-auto top-[6%] md:top-[9%] lg:top-[11%] xl:top-[13%]"
          alt="icon"
        />
        <img
          src={Ellipse84}
          className="absolute inset-0 left-8 xl:left-[17%] w-[0.5rem] h-auto top-[7%] md:top-[8%] lg:top-[9%] xl:top-[7%]"
          alt="icon"
        />

        <div className="w-full mx-[1rem] min-[400px]:mx-[1.5rem] md:mx-[4rem] lg:mx-[6rem] xl:mx-[14rem]">
          <p className="text-[#ffffff] font-bold text-xs min-[400px]:text-sm md:text-2xl lg:text-[1.7rem] xl:text-[1.9rem] text-center leading-6 min-[400px]:leading-8 lg:leading-10 xl:leading-12">
            Welcome to Ayekart Hisab
          </p>
          <p className="text-center text-[#ffffff] font-semibold text-[0.5rem] min-[400px]:text-[0.6rem] md:text-xs xl:text-sm mb-[2rem] md:mb-[3rem] lg:mb-[5rem]">
            Log In to your Account
          </p>

          <label
            for="card-number-input"
            className="text-left text-[#ffffff] font-normal text-[0.7rem] md:text-[0.8rem] mt-[2rem]"
          >
            Mobile Number
          </label>
          <div className="relative">
            <div className="absolute inset-y-0  top-0 flex items-center mx-3">
              {/* {!isToggleButton && ( */}
              <p className="text-[#FFFFFF] font-semibold text-xs md:text-sm lg:text-[0.9rem]">
                +91
              </p>
              {/* // )} */}
            </div>
            <input
              type="number"
              id="card-number-input"
              className="remove-arrow bg-[#3850BD] mt-3 border border-[#fbfbfb] font-semibold text-[#FFFFFF] text-xs md:text-sm lg:text-[0.9rem] rounded-md outline-0 block w-full p-[0.5rem] pl-[2.4rem] pr-[2.8rem] md:p-[0.8rem] md:pl-[3rem] md:pr-[4rem]"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="123-456-7890"
              value={number}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 10) {
                  setNumber(inputValue);
                }
              }}
              required
            />
            {/* onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 14) {
                  setNumber(inputValue);
                }
              }} */}
            {/* value={number ? `${number}` : "+91 "} */}
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-2 md:pe-3 cursor-pointer">
              {!isToggleButton && (
                <p
                  onClick={createOTP}
                  className="text-[#FFFFFF] hover:text-[#e3e2e2] font-semibold text-xs md:text-sm lg:text-[0.9rem]"
                >
                  Verify
                </p>
              )}
            </div>
          </div>

          {isToggleButton ? (
            <p className="text-left text-[#ffffff] font-normal text-[0.7rem] md:text-[0.8rem] mt-[1.5rem] ">
              Enter Password
            </p>
          ) : (
            <p className="text-left text-[#ffffff] font-normal text-[0.7rem] md:text-[0.8rem] mt-[1.5rem] ">
              Enter OTP
            </p>
          )}

          {isToggleButton ? (
            <LoginBoxPassword
              password={getPassword}
              changePassword={forgotPassword}
            />
          ) : (
            <LoginBoxOtp
              oneTimePassword={otp}
              loader={toggleLoader}
              otpData={createOtpData}
            />
          )}

          {/* Toggling Login button between password and OTP*/}
          {isToggleButton ? (
            isLoader ? (
              <ThreeDots
                visible={true}
                height="80"
                width="50"
                color="#ffff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1.6vw",
                }}
                wrapperClass=""
              />
            ) : (
              <div>
                <button
                  type="button"
                  className="text-[#0E3293] bg-[#F3F5FF] hover:bg-[#d4daf4] w-full focus:ring-4 font-semibold rounded-md md:rounded-lg text-[0.7rem] min-[400px]:text-[0.7rem] md:text-sm lg:text-[1rem] px-3 lg:px-4 py-2 md:py-3 lg:py-4 mt-7 md:mt-9 lg:mt-16"
                  onClick={onLogin}
                >
                  Sign In
                </button>

                <p
                  className="text-[#ffffff] text-center mt-2 md:mt-3 text-[0.6rem] md:text-[0.8rem] lg:text-[0.9rem] font-semibold cursor-pointer underline"
                  onClick={userPassword}
                >
                  Instead Login with OTP
                </p>
              </div>
            )
          ) : isLoader ? (
            <ThreeDots
              visible={true}
              height="80"
              width="50"
              color="#ffff"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1.6vw",
              }}
              wrapperClass=""
            />
          ) : (
            <div>
              <button
                type="button"
                className="text-[#0E3293] bg-[#F3F5FF] hover:bg-[#d4daf4] w-full focus:ring-4 font-semibold rounded-md md:rounded-lg text-[0.6rem] min-[400px]:text-[0.7rem] md:text-sm lg:text-[1rem] px-3 lg:px-4 py-2 md:py-3 lg:py-4 mt-7 md:mt-9 lg:mt-16"
                onClick={userOTP}
              >
                Use Password Instead
              </button>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LoginBox;
